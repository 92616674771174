/* ibm-plex-sans-regular - latin */
$path-name: '/assets/fonts/';

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
       url('#{$path-name}ibm-plex-sans-v7-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-500 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
       url('#{$path-name}ibm-plex-sans-v7-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-600 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
       url('#{$path-name}ibm-plex-sans-v7-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-700 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
       url('#{$path-name}ibm-plex-sans-v7-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');