/* You can add global styles to this file, and also import other style files */
@import "./assets/styles/fabric.css";
/* ibm-plex-sans-regular - latin */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("/assets/fonts/ibm-plex-sans-v7-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-500 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("/assets/fonts/ibm-plex-sans-v7-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-600 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("/assets/fonts/ibm-plex-sans-v7-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ibm-plex-sans-700 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("/assets/fonts/ibm-plex-sans-v7-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/*
  This stylesheet uses scss valiables for most of the colors / background-colors of the table
  to enable the customization of the displayed table without cloning the stylesheet into the
  own application.

  To modify table colors, add the following lines to the scss file of your application
  (this example modifies the color of the selected row - selectionType = single, multi or multiClick):

  $ngx-datatable-selected-active-background: yellow;
  $ngx-datatable-selected-active-background-hover: rgba(yellow, 0.2);

  @import '~@swimlane/ngx-datatable/index.css';
  @import '~@swimlane/ngx-datatable/themes/material.scss';
  @import '~@swimlane/ngx-datatable/assets/icons.css';

That's all.
*/
.ngx-datatable.material {
  background: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  /**
  * Shared Styles
  */
  /**
  * Global Row Styles
  */
  /**
  * Header Styles
  */
  /**
  * Body Styles
  */
  /**
  * Footer Styles
  */
}
.ngx-datatable.material.striped .datatable-row-odd {
  background: #eee;
}
.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:focus, .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:hover, .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:focus, .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active, .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #fff;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-color: #fff;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  font-weight: 400;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  vertical-align: bottom;
  font-size: 12px;
  font-weight: 500;
}
.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}
.ngx-datatable.material .datatable-body {
  position: relative;
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.material .datatable-body .datatable-group-header {
  background: #f5f5f5;
  border-bottom: solid 1px #d9d8d9;
  border-top: solid 1px #d9d8d9;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .progress-linear {
  display: block;
  position: sticky;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  top: 0;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: rgb(170, 209, 249);
}
.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: rgb(16, 108, 200);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}
.ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}
.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: #fff;
  cursor: pointer;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
/*** Global ***/
.white-skin .gradient {
  background: #ababab;
  background: linear-gradient(135deg, #ababab 0%, #fff 100%);
}
.white-skin .primary-color {
  background-color: #4285f4 !important;
}
.white-skin .navbar {
  background-color: #fff;
  color: #424242;
}
.white-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.white-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .white-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .white-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #2a75f3;
}
.white-skin .navbar .navbar-nav .nav-item a {
  color: #424242;
}
.white-skin .navbar.double-nav a {
  color: #424242;
}
.white-skin .navbar form .md-form .form-control {
  color: #424242;
  font-weight: 300;
}
.white-skin .navbar form .md-form .form-control::placeholder {
  color: #424242;
}
.white-skin .navbar.navbar-dark form .md-form .form-control {
  color: #fff;
}
.white-skin .navbar.navbar-dark form .md-form .form-control::placeholder {
  color: #fff;
}
.white-skin .page-footer {
  background-color: #6f7178;
}
.white-skin .side-nav {
  background-color: #f9f9f9;
}
.white-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.white-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #0d5bdd;
}
.white-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.white-skin .side-nav .social a .fas,
.white-skin .side-nav .social a .fab,
.white-skin .side-nav .social a .far {
  color: #424242;
}
.white-skin .side-nav .social a:hover .fas,
.white-skin .side-nav .social a:hover .fab,
.white-skin .side-nav .social a:hover .far {
  color: #4285f4 !important;
  transition: all 0.3s linear;
}
.white-skin .side-nav .search-form .md-form input {
  color: #424242 !important;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.white-skin .side-nav .search-form .md-form input::placeholder {
  color: rgba(66, 66, 66, 0.5) !important;
}
.white-skin .side-nav .collapsible li {
  background-color: transparent;
}
.white-skin .side-nav .collapsible li a {
  font-weight: 400;
}
.white-skin .side-nav .collapsible li .collapsible-header {
  color: #424242;
  transition: all 0.3s linear;
}
.white-skin .side-nav .collapsible li .collapsible-header.active {
  color: #4285f4;
  background-color: transparent;
}
.white-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.white-skin .side-nav .collapsible li .collapsible-body a {
  color: #424242;
}
.white-skin .side-nav .collapsible li .collapsible-body a:hover, .white-skin .side-nav .collapsible li .collapsible-body a.active, .white-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #4285f4;
}
.white-skin .side-nav .collapsible li .collapsible-body a .fas,
.white-skin .side-nav .collapsible li .collapsible-body a .fab,
.white-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #424242;
}
.white-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #424242;
  transition: all 0.3s linear;
}
.white-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .white-skin .side-nav .collapsible li a:not(.collapsible-header).active, .white-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #4285f4 !important;
}
.white-skin .side-nav .sidenav-bg:after, .white-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(255, 255, 255, 0.8);
}
.white-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(255, 255, 255, 0.65);
}
.white-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(255, 255, 255, 0.5);
}
.white-skin .btn-primary {
  background-color: #4285f4 !important;
  color: #fff;
}
.white-skin .btn-primary:hover {
  background-color: #5a95f5;
  color: #fff;
}
.white-skin .btn-primary:focus, .white-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .btn-primary:focus, .white-skin .btn-primary:active, .white-skin .btn-primary.active {
  background-color: #0b51c5;
}
.white-skin .btn-primary.dropdown-toggle {
  background-color: #4285f4 !important;
}
.white-skin .btn-primary.dropdown-toggle:hover, .white-skin .btn-primary.dropdown-toggle:focus {
  background-color: #5a95f5 !important;
}
.white-skin .btn-primary:not([disabled]):not(.disabled):active, .white-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .white-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #0b51c5 !important;
}
.white-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .white-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .primary-ic {
  color: #4285f4 !important;
}
.white-skin .primary-ic:hover, .white-skin .primary-ic:focus {
  color: #4285f4;
}
.white-skin a.btn:not([href]):not([tabindex]),
.white-skin a.btn:not([href]):not([tabindex]):focus,
.white-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.white-skin table.table a.btn.btn-primary {
  color: #fff;
}
.white-skin .btn-secondary {
  background-color: #fc685f !important;
  color: #fff;
}
.white-skin .btn-secondary:hover {
  background-color: #fc8078;
  color: #fff;
}
.white-skin .btn-secondary:focus, .white-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .btn-secondary:focus, .white-skin .btn-secondary:active, .white-skin .btn-secondary.active {
  background-color: #f01205;
}
.white-skin .btn-secondary.dropdown-toggle {
  background-color: #fc685f !important;
}
.white-skin .btn-secondary.dropdown-toggle:hover, .white-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #fc8078 !important;
}
.white-skin .btn-secondary:not([disabled]):not(.disabled):active, .white-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .white-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #f01205 !important;
}
.white-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .white-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .secondary-ic {
  color: #fc685f !important;
}
.white-skin .secondary-ic:hover, .white-skin .secondary-ic:focus {
  color: #fc685f;
}
.white-skin a.btn:not([href]):not([tabindex]),
.white-skin a.btn:not([href]):not([tabindex]):focus,
.white-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.white-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.white-skin .btn-default {
  background-color: #454545 !important;
  color: #fff;
}
.white-skin .btn-default:hover {
  background-color: #525252;
  color: #fff;
}
.white-skin .btn-default:focus, .white-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .btn-default:focus, .white-skin .btn-default:active, .white-skin .btn-default.active {
  background-color: #121212;
}
.white-skin .btn-default.dropdown-toggle {
  background-color: #454545 !important;
}
.white-skin .btn-default.dropdown-toggle:hover, .white-skin .btn-default.dropdown-toggle:focus {
  background-color: #525252 !important;
}
.white-skin .btn-default:not([disabled]):not(.disabled):active, .white-skin .btn-default:not([disabled]):not(.disabled).active, .show > .white-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #121212 !important;
}
.white-skin .btn-default:not([disabled]):not(.disabled):active:focus, .white-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .default-ic {
  color: #454545 !important;
}
.white-skin .default-ic:hover, .white-skin .default-ic:focus {
  color: #454545;
}
.white-skin a.btn:not([href]):not([tabindex]),
.white-skin a.btn:not([href]):not([tabindex]):focus,
.white-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.white-skin table.table a.btn.btn-default {
  color: #fff;
}
.white-skin .btn-outline-primary {
  border: 2px solid #4285f4 !important;
  background-color: transparent !important;
  color: #4285f4 !important;
}
.white-skin .btn-outline-primary:hover, .white-skin .btn-outline-primary:focus, .white-skin .btn-outline-primary:active, .white-skin .btn-outline-primary:active:focus, .white-skin .btn-outline-primary.active {
  border-color: #4285f4 !important;
  background-color: transparent !important;
  color: #4285f4 !important;
}
.white-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .white-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .white-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #4285f4 !important;
}
.white-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .white-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .btn-outline-secondary {
  border: 2px solid #fc685f !important;
  background-color: transparent !important;
  color: #fc685f !important;
}
.white-skin .btn-outline-secondary:hover, .white-skin .btn-outline-secondary:focus, .white-skin .btn-outline-secondary:active, .white-skin .btn-outline-secondary:active:focus, .white-skin .btn-outline-secondary.active {
  border-color: #fc685f !important;
  background-color: transparent !important;
  color: #fc685f !important;
}
.white-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .white-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .white-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #fc685f !important;
}
.white-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .white-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .btn-outline-default {
  border: 2px solid #454545 !important;
  background-color: transparent !important;
  color: #454545 !important;
}
.white-skin .btn-outline-default:hover, .white-skin .btn-outline-default:focus, .white-skin .btn-outline-default:active, .white-skin .btn-outline-default:active:focus, .white-skin .btn-outline-default.active {
  border-color: #454545 !important;
  background-color: transparent !important;
  color: #454545 !important;
}
.white-skin .btn-outline-default:not([disabled]):not(.disabled):active, .white-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .white-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #454545 !important;
}
.white-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .white-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .white-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.white-skin .card .btn-action {
  background: #454545;
}
.white-skin .card .btn-action:hover, .white-skin .card .btn-action:focus {
  background-color: #525252 !important;
}
.white-skin .card .btn-action.active {
  background-color: #121212 !important;
}
.white-skin .md-outline input[type=text]:focus:not([readonly]),
.white-skin .md-outline input[type=password]:focus:not([readonly]),
.white-skin .md-outline input[type=email]:focus:not([readonly]),
.white-skin .md-outline input[type=url]:focus:not([readonly]),
.white-skin .md-outline input[type=time]:focus:not([readonly]),
.white-skin .md-outline input[type=date]:focus:not([readonly]),
.white-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.white-skin .md-outline input[type=tel]:focus:not([readonly]),
.white-skin .md-outline input[type=number]:focus:not([readonly]),
.white-skin .md-outline input[type=search-md]:focus:not([readonly]),
.white-skin .md-outline input[type=search]:focus:not([readonly]),
.white-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #4285f4;
  box-shadow: inset 0px 0px 0px 1px #4285f4;
}
.white-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.white-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.white-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #4285f4;
}
.white-skin .md-bg input[type=text],
.white-skin .md-bg input[type=password],
.white-skin .md-bg input[type=email],
.white-skin .md-bg input[type=url],
.white-skin .md-bg input[type=time],
.white-skin .md-bg input[type=date],
.white-skin .md-bg input[type=datetime-local],
.white-skin .md-bg input[type=tel],
.white-skin .md-bg input[type=number],
.white-skin .md-bg input[type=search-md],
.white-skin .md-bg input[type=search],
.white-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #4285f4, #4285f4), linear-gradient(to bottom, #ced4da, #ced4da);
}
.white-skin input[type=email]:focus:not([readonly]),
.white-skin input[type=text]:focus:not([readonly]),
.white-skin input[type=password]:focus:not([readonly]),
.white-skin input[type=number]:focus:not([readonly]),
.white-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #4285f4;
  box-shadow: 0 1px 0 0 #4285f4;
}
.white-skin input[type=email]:focus:not([readonly]) + label,
.white-skin input[type=text]:focus:not([readonly]) + label,
.white-skin input[type=password]:focus:not([readonly]) + label,
.white-skin input[type=number]:focus:not([readonly]) + label,
.white-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #4285f4;
}
.white-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #4285f4;
  border-bottom: 2px solid #4285f4;
}
.white-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.white-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #4285f4;
  border-color: #4285f4;
}
.white-skin .md-form .prefix.active {
  color: #4285f4;
}
.white-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #4285f4 !important;
}
.white-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #4285f4;
  box-shadow: inset 0px 0px 0px 1px #4285f4;
}
.white-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #4285f4;
}
.white-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.white-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.white-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #4285f4 !important;
}
.white-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.white-skin .top-nav-collapse {
  background-color: #fff;
}
.white-skin .carousel-multi-item .controls-top > a,
.white-skin .carousel-multi-item .carousel-indicators li,
.white-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #4285f4;
}
.white-skin .form-header,
.white-skin .card-header {
  background-color: #4c8bf5;
}
.white-skin .spinner-primary-color,
.white-skin .spinner-primary-color-only {
  border-color: #4285f4;
}
.white-skin .pagination-primary-color .page-item.active .page-link,
.white-skin .pagination-primary-color .page-item.active .page-link:focus,
.white-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #4285f4;
}
.white-skin .pagination-primary-color .page-link {
  color: #4285f4;
}
.white-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.black-skin .gradient {
  background: #000;
  background: linear-gradient(135deg, #000 0%, #616161 100%);
}
.black-skin .primary-color {
  background-color: #2ad1a3 !important;
}
.black-skin .navbar {
  background-color: #222;
  color: #fff;
}
.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .black-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #26bc92;
}
.black-skin .navbar.double-nav a {
  color: #fff;
}
.black-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.black-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.black-skin .page-footer {
  background-color: #222;
}
.black-skin .side-nav {
  background-color: #222;
}
.black-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.black-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #1d9171;
}
.black-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.black-skin .side-nav .social a:hover .fas,
.black-skin .side-nav .social a:hover .fab,
.black-skin .side-nav .social a:hover .far {
  color: #2ad1a3 !important;
  transition: all 0.3s linear;
}
.black-skin .side-nav .collapsible li {
  background-color: transparent;
}
.black-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.black-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: #2ad1a3;
}
.black-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: #2ad1a3;
}
.black-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.black-skin .side-nav .collapsible li .collapsible-body a:hover, .black-skin .side-nav .collapsible li .collapsible-body a.active, .black-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #2ad1a3;
}
.black-skin .side-nav .collapsible li .collapsible-body a .fas,
.black-skin .side-nav .collapsible li .collapsible-body a .fab,
.black-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.black-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.black-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .black-skin .side-nav .collapsible li a:not(.collapsible-header).active, .black-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #2ad1a3 !important;
}
.black-skin .side-nav .sidenav-bg:after, .black-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(0, 0, 0, 0.8);
}
.black-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(0, 0, 0, 0.65);
}
.black-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(0, 0, 0, 0.5);
}
.black-skin .btn-primary {
  background-color: #2ad1a3 !important;
  color: #fff;
}
.black-skin .btn-primary:hover {
  background-color: #3dd8ad;
  color: #fff;
}
.black-skin .btn-primary:focus, .black-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .btn-primary:focus, .black-skin .btn-primary:active, .black-skin .btn-primary.active {
  background-color: #197c61;
}
.black-skin .btn-primary.dropdown-toggle {
  background-color: #2ad1a3 !important;
}
.black-skin .btn-primary.dropdown-toggle:hover, .black-skin .btn-primary.dropdown-toggle:focus {
  background-color: #3dd8ad !important;
}
.black-skin .btn-primary:not([disabled]):not(.disabled):active, .black-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .black-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #197c61 !important;
}
.black-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .black-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .primary-ic {
  color: #2ad1a3 !important;
}
.black-skin .primary-ic:hover, .black-skin .primary-ic:focus {
  color: #2ad1a3;
}
.black-skin a.btn:not([href]):not([tabindex]),
.black-skin a.btn:not([href]):not([tabindex]):focus,
.black-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.black-skin table.table a.btn.btn-primary {
  color: #fff;
}
.black-skin .btn-secondary {
  background-color: #0f478a !important;
  color: #fff;
}
.black-skin .btn-secondary:hover {
  background-color: #1253a1;
  color: #fff;
}
.black-skin .btn-secondary:focus, .black-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .btn-secondary:focus, .black-skin .btn-secondary:active, .black-skin .btn-secondary.active {
  background-color: #05182e;
}
.black-skin .btn-secondary.dropdown-toggle {
  background-color: #0f478a !important;
}
.black-skin .btn-secondary.dropdown-toggle:hover, .black-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #1253a1 !important;
}
.black-skin .btn-secondary:not([disabled]):not(.disabled):active, .black-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .black-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #05182e !important;
}
.black-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .black-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .secondary-ic {
  color: #0f478a !important;
}
.black-skin .secondary-ic:hover, .black-skin .secondary-ic:focus {
  color: #0f478a;
}
.black-skin a.btn:not([href]):not([tabindex]),
.black-skin a.btn:not([href]):not([tabindex]):focus,
.black-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.black-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.black-skin .btn-default {
  background-color: #222222 !important;
  color: #fff;
}
.black-skin .btn-default:hover {
  background-color: #2f2f2f;
  color: #fff;
}
.black-skin .btn-default:focus, .black-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .btn-default:focus, .black-skin .btn-default:active, .black-skin .btn-default.active {
  background-color: black;
}
.black-skin .btn-default.dropdown-toggle {
  background-color: #222222 !important;
}
.black-skin .btn-default.dropdown-toggle:hover, .black-skin .btn-default.dropdown-toggle:focus {
  background-color: #2f2f2f !important;
}
.black-skin .btn-default:not([disabled]):not(.disabled):active, .black-skin .btn-default:not([disabled]):not(.disabled).active, .show > .black-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: black !important;
}
.black-skin .btn-default:not([disabled]):not(.disabled):active:focus, .black-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .default-ic {
  color: #222222 !important;
}
.black-skin .default-ic:hover, .black-skin .default-ic:focus {
  color: #222222;
}
.black-skin a.btn:not([href]):not([tabindex]),
.black-skin a.btn:not([href]):not([tabindex]):focus,
.black-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.black-skin table.table a.btn.btn-default {
  color: #fff;
}
.black-skin .btn-outline-primary {
  border: 2px solid #2ad1a3 !important;
  background-color: transparent !important;
  color: #2ad1a3 !important;
}
.black-skin .btn-outline-primary:hover, .black-skin .btn-outline-primary:focus, .black-skin .btn-outline-primary:active, .black-skin .btn-outline-primary:active:focus, .black-skin .btn-outline-primary.active {
  border-color: #2ad1a3 !important;
  background-color: transparent !important;
  color: #2ad1a3 !important;
}
.black-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .black-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .black-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #2ad1a3 !important;
}
.black-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .black-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .btn-outline-secondary {
  border: 2px solid #0f478a !important;
  background-color: transparent !important;
  color: #0f478a !important;
}
.black-skin .btn-outline-secondary:hover, .black-skin .btn-outline-secondary:focus, .black-skin .btn-outline-secondary:active, .black-skin .btn-outline-secondary:active:focus, .black-skin .btn-outline-secondary.active {
  border-color: #0f478a !important;
  background-color: transparent !important;
  color: #0f478a !important;
}
.black-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .black-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .black-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #0f478a !important;
}
.black-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .black-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .btn-outline-default {
  border: 2px solid #222222 !important;
  background-color: transparent !important;
  color: #222222 !important;
}
.black-skin .btn-outline-default:hover, .black-skin .btn-outline-default:focus, .black-skin .btn-outline-default:active, .black-skin .btn-outline-default:active:focus, .black-skin .btn-outline-default.active {
  border-color: #222222 !important;
  background-color: transparent !important;
  color: #222222 !important;
}
.black-skin .btn-outline-default:not([disabled]):not(.disabled):active, .black-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .black-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #222222 !important;
}
.black-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .black-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .black-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.black-skin .card .btn-action {
  background: #222222;
}
.black-skin .card .btn-action:hover, .black-skin .card .btn-action:focus {
  background-color: #2f2f2f !important;
}
.black-skin .card .btn-action.active {
  background-color: black !important;
}
.black-skin .md-outline input[type=text]:focus:not([readonly]),
.black-skin .md-outline input[type=password]:focus:not([readonly]),
.black-skin .md-outline input[type=email]:focus:not([readonly]),
.black-skin .md-outline input[type=url]:focus:not([readonly]),
.black-skin .md-outline input[type=time]:focus:not([readonly]),
.black-skin .md-outline input[type=date]:focus:not([readonly]),
.black-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.black-skin .md-outline input[type=tel]:focus:not([readonly]),
.black-skin .md-outline input[type=number]:focus:not([readonly]),
.black-skin .md-outline input[type=search-md]:focus:not([readonly]),
.black-skin .md-outline input[type=search]:focus:not([readonly]),
.black-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #2ad1a3;
  box-shadow: inset 0px 0px 0px 1px #2ad1a3;
}
.black-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.black-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.black-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #2ad1a3;
}
.black-skin .md-bg input[type=text],
.black-skin .md-bg input[type=password],
.black-skin .md-bg input[type=email],
.black-skin .md-bg input[type=url],
.black-skin .md-bg input[type=time],
.black-skin .md-bg input[type=date],
.black-skin .md-bg input[type=datetime-local],
.black-skin .md-bg input[type=tel],
.black-skin .md-bg input[type=number],
.black-skin .md-bg input[type=search-md],
.black-skin .md-bg input[type=search],
.black-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #2ad1a3, #2ad1a3), linear-gradient(to bottom, #ced4da, #ced4da);
}
.black-skin input[type=email]:focus:not([readonly]),
.black-skin input[type=text]:focus:not([readonly]),
.black-skin input[type=password]:focus:not([readonly]),
.black-skin input[type=number]:focus:not([readonly]),
.black-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #2ad1a3;
  box-shadow: 0 1px 0 0 #2ad1a3;
}
.black-skin input[type=email]:focus:not([readonly]) + label,
.black-skin input[type=text]:focus:not([readonly]) + label,
.black-skin input[type=password]:focus:not([readonly]) + label,
.black-skin input[type=number]:focus:not([readonly]) + label,
.black-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #2ad1a3;
}
.black-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #2ad1a3;
  border-bottom: 2px solid #2ad1a3;
}
.black-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.black-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #2ad1a3;
  border-color: #2ad1a3;
}
.black-skin .md-form .prefix.active {
  color: #2ad1a3;
}
.black-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #2ad1a3 !important;
}
.black-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #2ad1a3;
  box-shadow: inset 0px 0px 0px 1px #2ad1a3;
}
.black-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #2ad1a3;
}
.black-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.black-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.black-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #2ad1a3 !important;
}
.black-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.black-skin .top-nav-collapse {
  background-color: #222;
}
.black-skin .carousel-multi-item .controls-top > a,
.black-skin .carousel-multi-item .carousel-indicators li,
.black-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #2ad1a3;
}
.black-skin .form-header,
.black-skin .card-header {
  background-color: #30d5a8;
}
.black-skin .spinner-primary-color,
.black-skin .spinner-primary-color-only {
  border-color: #2ad1a3;
}
.black-skin .pagination-primary-color .page-item.active .page-link,
.black-skin .pagination-primary-color .page-item.active .page-link:focus,
.black-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #2ad1a3;
}
.black-skin .pagination-primary-color .page-link {
  color: #2ad1a3;
}
.black-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.cyan-skin .gradient {
  background: #114861;
  background: linear-gradient(135deg, #114861 0%, #45b0c0 100%);
}
.cyan-skin .primary-color {
  background-color: #4fada7 !important;
}
.cyan-skin .navbar {
  background-color: #114861;
  color: #fff;
}
.cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .cyan-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #eea82f;
}
.cyan-skin .navbar.double-nav a {
  color: #fff;
}
.cyan-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.cyan-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.cyan-skin .page-footer {
  background-color: #114861;
}
.cyan-skin .side-nav {
  background-color: #24879d;
}
.cyan-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.cyan-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #d99012;
}
.cyan-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.cyan-skin .side-nav .social a:hover .fas,
.cyan-skin .side-nav .social a:hover .fab,
.cyan-skin .side-nav .social a:hover .far {
  color: #f0b247 !important;
  transition: all 0.3s linear;
}
.cyan-skin .side-nav .collapsible li {
  background-color: transparent;
}
.cyan-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.cyan-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(29, 65, 82, 0.8);
}
.cyan-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(29, 65, 82, 0.8);
}
.cyan-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.cyan-skin .side-nav .collapsible li .collapsible-body a:hover, .cyan-skin .side-nav .collapsible li .collapsible-body a.active, .cyan-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #ffa742;
}
.cyan-skin .side-nav .collapsible li .collapsible-body a .fas,
.cyan-skin .side-nav .collapsible li .collapsible-body a .fab,
.cyan-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.cyan-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.cyan-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .cyan-skin .side-nav .collapsible li a:not(.collapsible-header).active, .cyan-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #ffa742 !important;
}
.cyan-skin .side-nav .sidenav-bg:after, .cyan-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(21, 78, 96, 0.8);
}
.cyan-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(21, 78, 96, 0.65);
}
.cyan-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(21, 78, 96, 0.5);
}
.cyan-skin .btn-primary {
  background-color: #ffa742 !important;
  color: #fff;
}
.cyan-skin .btn-primary:hover {
  background-color: #ffb35c;
  color: #fff;
}
.cyan-skin .btn-primary:focus, .cyan-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .btn-primary:focus, .cyan-skin .btn-primary:active, .cyan-skin .btn-primary.active {
  background-color: #db7500;
}
.cyan-skin .btn-primary.dropdown-toggle {
  background-color: #ffa742 !important;
}
.cyan-skin .btn-primary.dropdown-toggle:hover, .cyan-skin .btn-primary.dropdown-toggle:focus {
  background-color: #ffb35c !important;
}
.cyan-skin .btn-primary:not([disabled]):not(.disabled):active, .cyan-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #db7500 !important;
}
.cyan-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .primary-ic {
  color: #ffa742 !important;
}
.cyan-skin .primary-ic:hover, .cyan-skin .primary-ic:focus {
  color: #ffa742;
}
.cyan-skin a.btn:not([href]):not([tabindex]),
.cyan-skin a.btn:not([href]):not([tabindex]):focus,
.cyan-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.cyan-skin table.table a.btn.btn-primary {
  color: #fff;
}
.cyan-skin .btn-secondary {
  background-color: #45b0c0 !important;
  color: #fff;
}
.cyan-skin .btn-secondary:hover {
  background-color: #58b8c6;
  color: #fff;
}
.cyan-skin .btn-secondary:focus, .cyan-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .btn-secondary:focus, .cyan-skin .btn-secondary:active, .cyan-skin .btn-secondary.active {
  background-color: #286d77;
}
.cyan-skin .btn-secondary.dropdown-toggle {
  background-color: #45b0c0 !important;
}
.cyan-skin .btn-secondary.dropdown-toggle:hover, .cyan-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #58b8c6 !important;
}
.cyan-skin .btn-secondary:not([disabled]):not(.disabled):active, .cyan-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #286d77 !important;
}
.cyan-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .secondary-ic {
  color: #45b0c0 !important;
}
.cyan-skin .secondary-ic:hover, .cyan-skin .secondary-ic:focus {
  color: #45b0c0;
}
.cyan-skin a.btn:not([href]):not([tabindex]),
.cyan-skin a.btn:not([href]):not([tabindex]):focus,
.cyan-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.cyan-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.cyan-skin .btn-default {
  background-color: #114861 !important;
  color: #fff;
}
.cyan-skin .btn-default:hover {
  background-color: #155877;
  color: #fff;
}
.cyan-skin .btn-default:focus, .cyan-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .btn-default:focus, .cyan-skin .btn-default:active, .cyan-skin .btn-default.active {
  background-color: #02080a;
}
.cyan-skin .btn-default.dropdown-toggle {
  background-color: #114861 !important;
}
.cyan-skin .btn-default.dropdown-toggle:hover, .cyan-skin .btn-default.dropdown-toggle:focus {
  background-color: #155877 !important;
}
.cyan-skin .btn-default:not([disabled]):not(.disabled):active, .cyan-skin .btn-default:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #02080a !important;
}
.cyan-skin .btn-default:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .default-ic {
  color: #114861 !important;
}
.cyan-skin .default-ic:hover, .cyan-skin .default-ic:focus {
  color: #114861;
}
.cyan-skin a.btn:not([href]):not([tabindex]),
.cyan-skin a.btn:not([href]):not([tabindex]):focus,
.cyan-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.cyan-skin table.table a.btn.btn-default {
  color: #fff;
}
.cyan-skin .btn-outline-primary {
  border: 2px solid #ffa742 !important;
  background-color: transparent !important;
  color: #ffa742 !important;
}
.cyan-skin .btn-outline-primary:hover, .cyan-skin .btn-outline-primary:focus, .cyan-skin .btn-outline-primary:active, .cyan-skin .btn-outline-primary:active:focus, .cyan-skin .btn-outline-primary.active {
  border-color: #ffa742 !important;
  background-color: transparent !important;
  color: #ffa742 !important;
}
.cyan-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .cyan-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ffa742 !important;
}
.cyan-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .btn-outline-secondary {
  border: 2px solid #45b0c0 !important;
  background-color: transparent !important;
  color: #45b0c0 !important;
}
.cyan-skin .btn-outline-secondary:hover, .cyan-skin .btn-outline-secondary:focus, .cyan-skin .btn-outline-secondary:active, .cyan-skin .btn-outline-secondary:active:focus, .cyan-skin .btn-outline-secondary.active {
  border-color: #45b0c0 !important;
  background-color: transparent !important;
  color: #45b0c0 !important;
}
.cyan-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .cyan-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #45b0c0 !important;
}
.cyan-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .btn-outline-default {
  border: 2px solid #114861 !important;
  background-color: transparent !important;
  color: #114861 !important;
}
.cyan-skin .btn-outline-default:hover, .cyan-skin .btn-outline-default:focus, .cyan-skin .btn-outline-default:active, .cyan-skin .btn-outline-default:active:focus, .cyan-skin .btn-outline-default.active {
  border-color: #114861 !important;
  background-color: transparent !important;
  color: #114861 !important;
}
.cyan-skin .btn-outline-default:not([disabled]):not(.disabled):active, .cyan-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .cyan-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #114861 !important;
}
.cyan-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .cyan-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .cyan-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.cyan-skin .card .btn-action {
  background: #114861;
}
.cyan-skin .card .btn-action:hover, .cyan-skin .card .btn-action:focus {
  background-color: #155877 !important;
}
.cyan-skin .card .btn-action.active {
  background-color: #02080a !important;
}
.cyan-skin .md-outline input[type=text]:focus:not([readonly]),
.cyan-skin .md-outline input[type=password]:focus:not([readonly]),
.cyan-skin .md-outline input[type=email]:focus:not([readonly]),
.cyan-skin .md-outline input[type=url]:focus:not([readonly]),
.cyan-skin .md-outline input[type=time]:focus:not([readonly]),
.cyan-skin .md-outline input[type=date]:focus:not([readonly]),
.cyan-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.cyan-skin .md-outline input[type=tel]:focus:not([readonly]),
.cyan-skin .md-outline input[type=number]:focus:not([readonly]),
.cyan-skin .md-outline input[type=search-md]:focus:not([readonly]),
.cyan-skin .md-outline input[type=search]:focus:not([readonly]),
.cyan-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #f0b247;
  box-shadow: inset 0px 0px 0px 1px #f0b247;
}
.cyan-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.cyan-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.cyan-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #f0b247;
}
.cyan-skin .md-bg input[type=text],
.cyan-skin .md-bg input[type=password],
.cyan-skin .md-bg input[type=email],
.cyan-skin .md-bg input[type=url],
.cyan-skin .md-bg input[type=time],
.cyan-skin .md-bg input[type=date],
.cyan-skin .md-bg input[type=datetime-local],
.cyan-skin .md-bg input[type=tel],
.cyan-skin .md-bg input[type=number],
.cyan-skin .md-bg input[type=search-md],
.cyan-skin .md-bg input[type=search],
.cyan-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #f0b247, #f0b247), linear-gradient(to bottom, #ced4da, #ced4da);
}
.cyan-skin input[type=email]:focus:not([readonly]),
.cyan-skin input[type=text]:focus:not([readonly]),
.cyan-skin input[type=password]:focus:not([readonly]),
.cyan-skin input[type=number]:focus:not([readonly]),
.cyan-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #f0b247;
  box-shadow: 0 1px 0 0 #f0b247;
}
.cyan-skin input[type=email]:focus:not([readonly]) + label,
.cyan-skin input[type=text]:focus:not([readonly]) + label,
.cyan-skin input[type=password]:focus:not([readonly]) + label,
.cyan-skin input[type=number]:focus:not([readonly]) + label,
.cyan-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #f0b247;
}
.cyan-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #f0b247;
  border-bottom: 2px solid #f0b247;
}
.cyan-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.cyan-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #f0b247;
  border-color: #f0b247;
}
.cyan-skin .md-form .prefix.active {
  color: #f0b247;
}
.cyan-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #f0b247 !important;
}
.cyan-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #f0b247;
  box-shadow: inset 0px 0px 0px 1px #f0b247;
}
.cyan-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #f0b247;
}
.cyan-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.cyan-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.cyan-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #f0b247 !important;
}
.cyan-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.cyan-skin .top-nav-collapse {
  background-color: #114861;
}
.cyan-skin .carousel-multi-item .controls-top > a,
.cyan-skin .carousel-multi-item .carousel-indicators li,
.cyan-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #f0b247;
}
.cyan-skin .form-header,
.cyan-skin .card-header {
  background-color: #f1b650;
}
.cyan-skin .spinner-primary-color,
.cyan-skin .spinner-primary-color-only {
  border-color: #4fada7;
}
.cyan-skin .pagination-primary-color .page-item.active .page-link,
.cyan-skin .pagination-primary-color .page-item.active .page-link:focus,
.cyan-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #4fada7;
}
.cyan-skin .pagination-primary-color .page-link {
  color: #4fada7;
}
.cyan-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.mdb-skin .gradient {
  background: #0e2135;
  background: linear-gradient(135deg, #0e2135 0%, #2c76b8 100%);
}
.mdb-skin .primary-color {
  background-color: #33b5e5 !important;
}
.mdb-skin .navbar {
  background-color: #243a51;
  color: #fff;
}
.mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #1dade2;
}
.mdb-skin .navbar.double-nav a {
  color: #fff;
}
.mdb-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.mdb-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.mdb-skin .page-footer {
  background-color: #243a51;
}
.mdb-skin .side-nav {
  background-color: #224562;
}
.mdb-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.mdb-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #178ab4;
}
.mdb-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.mdb-skin .side-nav .social a:hover .fas,
.mdb-skin .side-nav .social a:hover .fab,
.mdb-skin .side-nav .social a:hover .far {
  color: #33b5e5 !important;
  transition: all 0.3s linear;
}
.mdb-skin .side-nav .collapsible li {
  background-color: transparent;
}
.mdb-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.mdb-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(72, 198, 255, 0.8);
}
.mdb-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(72, 198, 255, 0.8);
}
.mdb-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.mdb-skin .side-nav .collapsible li .collapsible-body a:hover, .mdb-skin .side-nav .collapsible li .collapsible-body a.active, .mdb-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #67d5ff;
}
.mdb-skin .side-nav .collapsible li .collapsible-body a .fas,
.mdb-skin .side-nav .collapsible li .collapsible-body a .fab,
.mdb-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.mdb-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.mdb-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .mdb-skin .side-nav .collapsible li a:not(.collapsible-header).active, .mdb-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #67d5ff !important;
}
.mdb-skin .side-nav .sidenav-bg:after, .mdb-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(13, 36, 60, 0.8);
}
.mdb-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(13, 36, 60, 0.65);
}
.mdb-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(13, 36, 60, 0.5);
}
.mdb-skin .btn-primary {
  background-color: #33b5e5 !important;
  color: #fff;
}
.mdb-skin .btn-primary:hover {
  background-color: #4abde8;
  color: #fff;
}
.mdb-skin .btn-primary:focus, .mdb-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .btn-primary:focus, .mdb-skin .btn-primary:active, .mdb-skin .btn-primary.active {
  background-color: #14799e;
}
.mdb-skin .btn-primary.dropdown-toggle {
  background-color: #33b5e5 !important;
}
.mdb-skin .btn-primary.dropdown-toggle:hover, .mdb-skin .btn-primary.dropdown-toggle:focus {
  background-color: #4abde8 !important;
}
.mdb-skin .btn-primary:not([disabled]):not(.disabled):active, .mdb-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #14799e !important;
}
.mdb-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .primary-ic {
  color: #33b5e5 !important;
}
.mdb-skin .primary-ic:hover, .mdb-skin .primary-ic:focus {
  color: #33b5e5;
}
.mdb-skin a.btn:not([href]):not([tabindex]),
.mdb-skin a.btn:not([href]):not([tabindex]):focus,
.mdb-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.mdb-skin table.table a.btn.btn-primary {
  color: #fff;
}
.mdb-skin .btn-secondary {
  background-color: #0e3d67 !important;
  color: #fff;
}
.mdb-skin .btn-secondary:hover {
  background-color: #114a7d;
  color: #fff;
}
.mdb-skin .btn-secondary:focus, .mdb-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .btn-secondary:focus, .mdb-skin .btn-secondary:active, .mdb-skin .btn-secondary.active {
  background-color: #02080d;
}
.mdb-skin .btn-secondary.dropdown-toggle {
  background-color: #0e3d67 !important;
}
.mdb-skin .btn-secondary.dropdown-toggle:hover, .mdb-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #114a7d !important;
}
.mdb-skin .btn-secondary:not([disabled]):not(.disabled):active, .mdb-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #02080d !important;
}
.mdb-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .secondary-ic {
  color: #0e3d67 !important;
}
.mdb-skin .secondary-ic:hover, .mdb-skin .secondary-ic:focus {
  color: #0e3d67;
}
.mdb-skin a.btn:not([href]):not([tabindex]),
.mdb-skin a.btn:not([href]):not([tabindex]):focus,
.mdb-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.mdb-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.mdb-skin .btn-default {
  background-color: #1a6398 !important;
  color: #fff;
}
.mdb-skin .btn-default:hover {
  background-color: #1e71ae;
  color: #fff;
}
.mdb-skin .btn-default:focus, .mdb-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .btn-default:focus, .mdb-skin .btn-default:active, .mdb-skin .btn-default.active {
  background-color: #0b2a41;
}
.mdb-skin .btn-default.dropdown-toggle {
  background-color: #1a6398 !important;
}
.mdb-skin .btn-default.dropdown-toggle:hover, .mdb-skin .btn-default.dropdown-toggle:focus {
  background-color: #1e71ae !important;
}
.mdb-skin .btn-default:not([disabled]):not(.disabled):active, .mdb-skin .btn-default:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #0b2a41 !important;
}
.mdb-skin .btn-default:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .default-ic {
  color: #1a6398 !important;
}
.mdb-skin .default-ic:hover, .mdb-skin .default-ic:focus {
  color: #1a6398;
}
.mdb-skin a.btn:not([href]):not([tabindex]),
.mdb-skin a.btn:not([href]):not([tabindex]):focus,
.mdb-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.mdb-skin table.table a.btn.btn-default {
  color: #fff;
}
.mdb-skin .btn-outline-primary {
  border: 2px solid #33b5e5 !important;
  background-color: transparent !important;
  color: #33b5e5 !important;
}
.mdb-skin .btn-outline-primary:hover, .mdb-skin .btn-outline-primary:focus, .mdb-skin .btn-outline-primary:active, .mdb-skin .btn-outline-primary:active:focus, .mdb-skin .btn-outline-primary.active {
  border-color: #33b5e5 !important;
  background-color: transparent !important;
  color: #33b5e5 !important;
}
.mdb-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .mdb-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #33b5e5 !important;
}
.mdb-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .btn-outline-secondary {
  border: 2px solid #0e3d67 !important;
  background-color: transparent !important;
  color: #0e3d67 !important;
}
.mdb-skin .btn-outline-secondary:hover, .mdb-skin .btn-outline-secondary:focus, .mdb-skin .btn-outline-secondary:active, .mdb-skin .btn-outline-secondary:active:focus, .mdb-skin .btn-outline-secondary.active {
  border-color: #0e3d67 !important;
  background-color: transparent !important;
  color: #0e3d67 !important;
}
.mdb-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .mdb-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #0e3d67 !important;
}
.mdb-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .btn-outline-default {
  border: 2px solid #1a6398 !important;
  background-color: transparent !important;
  color: #1a6398 !important;
}
.mdb-skin .btn-outline-default:hover, .mdb-skin .btn-outline-default:focus, .mdb-skin .btn-outline-default:active, .mdb-skin .btn-outline-default:active:focus, .mdb-skin .btn-outline-default.active {
  border-color: #1a6398 !important;
  background-color: transparent !important;
  color: #1a6398 !important;
}
.mdb-skin .btn-outline-default:not([disabled]):not(.disabled):active, .mdb-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .mdb-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #1a6398 !important;
}
.mdb-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .mdb-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .mdb-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.mdb-skin .card .btn-action {
  background: #1a6398;
}
.mdb-skin .card .btn-action:hover, .mdb-skin .card .btn-action:focus {
  background-color: #1e71ae !important;
}
.mdb-skin .card .btn-action.active {
  background-color: #0b2a41 !important;
}
.mdb-skin .md-outline input[type=text]:focus:not([readonly]),
.mdb-skin .md-outline input[type=password]:focus:not([readonly]),
.mdb-skin .md-outline input[type=email]:focus:not([readonly]),
.mdb-skin .md-outline input[type=url]:focus:not([readonly]),
.mdb-skin .md-outline input[type=time]:focus:not([readonly]),
.mdb-skin .md-outline input[type=date]:focus:not([readonly]),
.mdb-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.mdb-skin .md-outline input[type=tel]:focus:not([readonly]),
.mdb-skin .md-outline input[type=number]:focus:not([readonly]),
.mdb-skin .md-outline input[type=search-md]:focus:not([readonly]),
.mdb-skin .md-outline input[type=search]:focus:not([readonly]),
.mdb-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #33b5e5;
  box-shadow: inset 0px 0px 0px 1px #33b5e5;
}
.mdb-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.mdb-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.mdb-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #33b5e5;
}
.mdb-skin .md-bg input[type=text],
.mdb-skin .md-bg input[type=password],
.mdb-skin .md-bg input[type=email],
.mdb-skin .md-bg input[type=url],
.mdb-skin .md-bg input[type=time],
.mdb-skin .md-bg input[type=date],
.mdb-skin .md-bg input[type=datetime-local],
.mdb-skin .md-bg input[type=tel],
.mdb-skin .md-bg input[type=number],
.mdb-skin .md-bg input[type=search-md],
.mdb-skin .md-bg input[type=search],
.mdb-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #33b5e5, #33b5e5), linear-gradient(to bottom, #ced4da, #ced4da);
}
.mdb-skin input[type=email]:focus:not([readonly]),
.mdb-skin input[type=text]:focus:not([readonly]),
.mdb-skin input[type=password]:focus:not([readonly]),
.mdb-skin input[type=number]:focus:not([readonly]),
.mdb-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #33b5e5;
  box-shadow: 0 1px 0 0 #33b5e5;
}
.mdb-skin input[type=email]:focus:not([readonly]) + label,
.mdb-skin input[type=text]:focus:not([readonly]) + label,
.mdb-skin input[type=password]:focus:not([readonly]) + label,
.mdb-skin input[type=number]:focus:not([readonly]) + label,
.mdb-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #33b5e5;
}
.mdb-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #33b5e5;
  border-bottom: 2px solid #33b5e5;
}
.mdb-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.mdb-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #33b5e5;
  border-color: #33b5e5;
}
.mdb-skin .md-form .prefix.active {
  color: #33b5e5;
}
.mdb-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #33b5e5 !important;
}
.mdb-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #33b5e5;
  box-shadow: inset 0px 0px 0px 1px #33b5e5;
}
.mdb-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #33b5e5;
}
.mdb-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.mdb-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.mdb-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #33b5e5 !important;
}
.mdb-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.mdb-skin .top-nav-collapse {
  background-color: #243a51;
}
.mdb-skin .carousel-multi-item .controls-top > a,
.mdb-skin .carousel-multi-item .carousel-indicators li,
.mdb-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #33b5e5;
}
.mdb-skin .form-header,
.mdb-skin .card-header {
  background-color: #3cb8e6;
}
.mdb-skin .spinner-primary-color,
.mdb-skin .spinner-primary-color-only {
  border-color: #33b5e5;
}
.mdb-skin .pagination-primary-color .page-item.active .page-link,
.mdb-skin .pagination-primary-color .page-item.active .page-link:focus,
.mdb-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #33b5e5;
}
.mdb-skin .pagination-primary-color .page-link {
  color: #33b5e5;
}
.mdb-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.deep-purple-skin .gradient {
  background: #2e1f49;
  background: linear-gradient(135deg, #2e1f49 0%, #a47fe3 100%);
}
.deep-purple-skin .primary-color {
  background-color: #d0637c !important;
}
.deep-purple-skin .navbar {
  background-color: #7f7e91;
  color: #fff;
}
.deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .deep-purple-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #9d6eed;
}
.deep-purple-skin .navbar.double-nav a {
  color: #fff;
}
.deep-purple-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.deep-purple-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.deep-purple-skin .page-footer {
  background-color: #7f7e91;
}
.deep-purple-skin .side-nav {
  background-color: #372e5f;
}
.deep-purple-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.deep-purple-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #7e41e8;
}
.deep-purple-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.deep-purple-skin .side-nav .social a:hover .fas,
.deep-purple-skin .side-nav .social a:hover .fab,
.deep-purple-skin .side-nav .social a:hover .far {
  color: #ac85f0 !important;
  transition: all 0.3s linear;
}
.deep-purple-skin .side-nav .collapsible li {
  background-color: transparent;
}
.deep-purple-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.deep-purple-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(176, 139, 240, 0.5);
}
.deep-purple-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(176, 139, 240, 0.5);
}
.deep-purple-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible li .collapsible-body a:hover, .deep-purple-skin .side-nav .collapsible li .collapsible-body a.active, .deep-purple-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #c9abfc;
}
.deep-purple-skin .side-nav .collapsible li .collapsible-body a .fas,
.deep-purple-skin .side-nav .collapsible li .collapsible-body a .fab,
.deep-purple-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.deep-purple-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .deep-purple-skin .side-nav .collapsible li a:not(.collapsible-header).active, .deep-purple-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #c9abfc !important;
}
.deep-purple-skin .side-nav .sidenav-bg:after, .deep-purple-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(36, 9, 56, 0.88);
}
.deep-purple-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(36, 9, 56, 0.65);
}
.deep-purple-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(36, 9, 56, 0.5);
}
.deep-purple-skin .btn-primary {
  background-color: #ac85f0 !important;
  color: #fff;
}
.deep-purple-skin .btn-primary:hover {
  background-color: #bb9cf3;
  color: #fff;
}
.deep-purple-skin .btn-primary:focus, .deep-purple-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .btn-primary:focus, .deep-purple-skin .btn-primary:active, .deep-purple-skin .btn-primary.active {
  background-color: #6e2ae5;
}
.deep-purple-skin .btn-primary.dropdown-toggle {
  background-color: #ac85f0 !important;
}
.deep-purple-skin .btn-primary.dropdown-toggle:hover, .deep-purple-skin .btn-primary.dropdown-toggle:focus {
  background-color: #bb9cf3 !important;
}
.deep-purple-skin .btn-primary:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #6e2ae5 !important;
}
.deep-purple-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .primary-ic {
  color: #ac85f0 !important;
}
.deep-purple-skin .primary-ic:hover, .deep-purple-skin .primary-ic:focus {
  color: #ac85f0;
}
.deep-purple-skin a.btn:not([href]):not([tabindex]),
.deep-purple-skin a.btn:not([href]):not([tabindex]):focus,
.deep-purple-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.deep-purple-skin table.table a.btn.btn-primary {
  color: #fff;
}
.deep-purple-skin .btn-secondary {
  background-color: #6e4ca3 !important;
  color: #fff;
}
.deep-purple-skin .btn-secondary:hover {
  background-color: #7a58b1;
  color: #fff;
}
.deep-purple-skin .btn-secondary:focus, .deep-purple-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .btn-secondary:focus, .deep-purple-skin .btn-secondary:active, .deep-purple-skin .btn-secondary.active {
  background-color: #3f2c5d;
}
.deep-purple-skin .btn-secondary.dropdown-toggle {
  background-color: #6e4ca3 !important;
}
.deep-purple-skin .btn-secondary.dropdown-toggle:hover, .deep-purple-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #7a58b1 !important;
}
.deep-purple-skin .btn-secondary:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #3f2c5d !important;
}
.deep-purple-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .secondary-ic {
  color: #6e4ca3 !important;
}
.deep-purple-skin .secondary-ic:hover, .deep-purple-skin .secondary-ic:focus {
  color: #6e4ca3;
}
.deep-purple-skin a.btn:not([href]):not([tabindex]),
.deep-purple-skin a.btn:not([href]):not([tabindex]):focus,
.deep-purple-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.deep-purple-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.deep-purple-skin .btn-default {
  background-color: #372e5f !important;
  color: #fff;
}
.deep-purple-skin .btn-default:hover {
  background-color: #413670;
  color: #fff;
}
.deep-purple-skin .btn-default:focus, .deep-purple-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .btn-default:focus, .deep-purple-skin .btn-default:active, .deep-purple-skin .btn-default.active {
  background-color: #0f0d1a;
}
.deep-purple-skin .btn-default.dropdown-toggle {
  background-color: #372e5f !important;
}
.deep-purple-skin .btn-default.dropdown-toggle:hover, .deep-purple-skin .btn-default.dropdown-toggle:focus {
  background-color: #413670 !important;
}
.deep-purple-skin .btn-default:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-default:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #0f0d1a !important;
}
.deep-purple-skin .btn-default:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .default-ic {
  color: #372e5f !important;
}
.deep-purple-skin .default-ic:hover, .deep-purple-skin .default-ic:focus {
  color: #372e5f;
}
.deep-purple-skin a.btn:not([href]):not([tabindex]),
.deep-purple-skin a.btn:not([href]):not([tabindex]):focus,
.deep-purple-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.deep-purple-skin table.table a.btn.btn-default {
  color: #fff;
}
.deep-purple-skin .btn-outline-primary {
  border: 2px solid #ac85f0 !important;
  background-color: transparent !important;
  color: #ac85f0 !important;
}
.deep-purple-skin .btn-outline-primary:hover, .deep-purple-skin .btn-outline-primary:focus, .deep-purple-skin .btn-outline-primary:active, .deep-purple-skin .btn-outline-primary:active:focus, .deep-purple-skin .btn-outline-primary.active {
  border-color: #ac85f0 !important;
  background-color: transparent !important;
  color: #ac85f0 !important;
}
.deep-purple-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ac85f0 !important;
}
.deep-purple-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .btn-outline-secondary {
  border: 2px solid #6e4ca3 !important;
  background-color: transparent !important;
  color: #6e4ca3 !important;
}
.deep-purple-skin .btn-outline-secondary:hover, .deep-purple-skin .btn-outline-secondary:focus, .deep-purple-skin .btn-outline-secondary:active, .deep-purple-skin .btn-outline-secondary:active:focus, .deep-purple-skin .btn-outline-secondary.active {
  border-color: #6e4ca3 !important;
  background-color: transparent !important;
  color: #6e4ca3 !important;
}
.deep-purple-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #6e4ca3 !important;
}
.deep-purple-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .btn-outline-default {
  border: 2px solid #372e5f !important;
  background-color: transparent !important;
  color: #372e5f !important;
}
.deep-purple-skin .btn-outline-default:hover, .deep-purple-skin .btn-outline-default:focus, .deep-purple-skin .btn-outline-default:active, .deep-purple-skin .btn-outline-default:active:focus, .deep-purple-skin .btn-outline-default.active {
  border-color: #372e5f !important;
  background-color: transparent !important;
  color: #372e5f !important;
}
.deep-purple-skin .btn-outline-default:not([disabled]):not(.disabled):active, .deep-purple-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .deep-purple-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #372e5f !important;
}
.deep-purple-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .deep-purple-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .deep-purple-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.deep-purple-skin .card .btn-action {
  background: #372e5f;
}
.deep-purple-skin .card .btn-action:hover, .deep-purple-skin .card .btn-action:focus {
  background-color: #413670 !important;
}
.deep-purple-skin .card .btn-action.active {
  background-color: #0f0d1a !important;
}
.deep-purple-skin .md-outline input[type=text]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=password]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=email]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=url]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=time]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=date]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=tel]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=number]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=search-md]:focus:not([readonly]),
.deep-purple-skin .md-outline input[type=search]:focus:not([readonly]),
.deep-purple-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #ac85f0;
  box-shadow: inset 0px 0px 0px 1px #ac85f0;
}
.deep-purple-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.deep-purple-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #ac85f0;
}
.deep-purple-skin .md-bg input[type=text],
.deep-purple-skin .md-bg input[type=password],
.deep-purple-skin .md-bg input[type=email],
.deep-purple-skin .md-bg input[type=url],
.deep-purple-skin .md-bg input[type=time],
.deep-purple-skin .md-bg input[type=date],
.deep-purple-skin .md-bg input[type=datetime-local],
.deep-purple-skin .md-bg input[type=tel],
.deep-purple-skin .md-bg input[type=number],
.deep-purple-skin .md-bg input[type=search-md],
.deep-purple-skin .md-bg input[type=search],
.deep-purple-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #ac85f0, #ac85f0), linear-gradient(to bottom, #ced4da, #ced4da);
}
.deep-purple-skin input[type=email]:focus:not([readonly]),
.deep-purple-skin input[type=text]:focus:not([readonly]),
.deep-purple-skin input[type=password]:focus:not([readonly]),
.deep-purple-skin input[type=number]:focus:not([readonly]),
.deep-purple-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #ac85f0;
  box-shadow: 0 1px 0 0 #ac85f0;
}
.deep-purple-skin input[type=email]:focus:not([readonly]) + label,
.deep-purple-skin input[type=text]:focus:not([readonly]) + label,
.deep-purple-skin input[type=password]:focus:not([readonly]) + label,
.deep-purple-skin input[type=number]:focus:not([readonly]) + label,
.deep-purple-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #ac85f0;
}
.deep-purple-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #ac85f0;
  border-bottom: 2px solid #ac85f0;
}
.deep-purple-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.deep-purple-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #ac85f0;
  border-color: #ac85f0;
}
.deep-purple-skin .md-form .prefix.active {
  color: #ac85f0;
}
.deep-purple-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #ac85f0 !important;
}
.deep-purple-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #ac85f0;
  box-shadow: inset 0px 0px 0px 1px #ac85f0;
}
.deep-purple-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #ac85f0;
}
.deep-purple-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.deep-purple-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.deep-purple-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #ac85f0 !important;
}
.deep-purple-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.deep-purple-skin .top-nav-collapse {
  background-color: #7f7e91;
}
.deep-purple-skin .carousel-multi-item .controls-top > a,
.deep-purple-skin .carousel-multi-item .carousel-indicators li,
.deep-purple-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #ac85f0;
}
.deep-purple-skin .form-header,
.deep-purple-skin .card-header {
  background-color: #b28ef1;
}
.deep-purple-skin .spinner-primary-color,
.deep-purple-skin .spinner-primary-color-only {
  border-color: #d0637c;
}
.deep-purple-skin .pagination-primary-color .page-item.active .page-link,
.deep-purple-skin .pagination-primary-color .page-item.active .page-link:focus,
.deep-purple-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #d0637c;
}
.deep-purple-skin .pagination-primary-color .page-link {
  color: #d0637c;
}
.deep-purple-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.navy-blue-skin .gradient {
  background: #222735;
  background: linear-gradient(135deg, #222735 0%, #4f68a6 100%);
}
.navy-blue-skin .primary-color {
  background-color: #309e9e !important;
}
.navy-blue-skin .navbar {
  background-color: #353b50;
  color: #fff;
}
.navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .navy-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #36b6b6;
}
.navy-blue-skin .navbar.double-nav a {
  color: #fff;
}
.navy-blue-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.navy-blue-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.navy-blue-skin .page-footer {
  background-color: #353b50;
}
.navy-blue-skin .side-nav {
  background-color: #4c5678;
}
.navy-blue-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.navy-blue-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #2b8f8f;
}
.navy-blue-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.navy-blue-skin .side-nav .social a:hover .fas,
.navy-blue-skin .side-nav .social a:hover .fab,
.navy-blue-skin .side-nav .social a:hover .far {
  color: #40c6c6 !important;
  transition: all 0.3s linear;
}
.navy-blue-skin .side-nav .collapsible li {
  background-color: transparent;
}
.navy-blue-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.navy-blue-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(37, 207, 207, 0.8);
}
.navy-blue-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(37, 207, 207, 0.8);
}
.navy-blue-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible li .collapsible-body a:hover, .navy-blue-skin .side-nav .collapsible li .collapsible-body a.active, .navy-blue-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #40c6c6;
}
.navy-blue-skin .side-nav .collapsible li .collapsible-body a .fas,
.navy-blue-skin .side-nav .collapsible li .collapsible-body a .fab,
.navy-blue-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.navy-blue-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .navy-blue-skin .side-nav .collapsible li a:not(.collapsible-header).active, .navy-blue-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #40c6c6 !important;
}
.navy-blue-skin .side-nav .sidenav-bg:after, .navy-blue-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(14, 15, 32, 0.8);
}
.navy-blue-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(14, 15, 32, 0.65);
}
.navy-blue-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(14, 15, 32, 0.5);
}
.navy-blue-skin .btn-primary {
  background-color: #40c6c6 !important;
  color: #fff;
}
.navy-blue-skin .btn-primary:hover {
  background-color: #54cccc;
  color: #fff;
}
.navy-blue-skin .btn-primary:focus, .navy-blue-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .btn-primary:focus, .navy-blue-skin .btn-primary:active, .navy-blue-skin .btn-primary.active {
  background-color: #257b7b;
}
.navy-blue-skin .btn-primary.dropdown-toggle {
  background-color: #40c6c6 !important;
}
.navy-blue-skin .btn-primary.dropdown-toggle:hover, .navy-blue-skin .btn-primary.dropdown-toggle:focus {
  background-color: #54cccc !important;
}
.navy-blue-skin .btn-primary:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #257b7b !important;
}
.navy-blue-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .primary-ic {
  color: #40c6c6 !important;
}
.navy-blue-skin .primary-ic:hover, .navy-blue-skin .primary-ic:focus {
  color: #40c6c6;
}
.navy-blue-skin a.btn:not([href]):not([tabindex]),
.navy-blue-skin a.btn:not([href]):not([tabindex]):focus,
.navy-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.navy-blue-skin table.table a.btn.btn-primary {
  color: #fff;
}
.navy-blue-skin .btn-secondary {
  background-color: #7e51b4 !important;
  color: #fff;
}
.navy-blue-skin .btn-secondary:hover {
  background-color: #8b63bc;
  color: #fff;
}
.navy-blue-skin .btn-secondary:focus, .navy-blue-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .btn-secondary:focus, .navy-blue-skin .btn-secondary:active, .navy-blue-skin .btn-secondary.active {
  background-color: #4d306f;
}
.navy-blue-skin .btn-secondary.dropdown-toggle {
  background-color: #7e51b4 !important;
}
.navy-blue-skin .btn-secondary.dropdown-toggle:hover, .navy-blue-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #8b63bc !important;
}
.navy-blue-skin .btn-secondary:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #4d306f !important;
}
.navy-blue-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .secondary-ic {
  color: #7e51b4 !important;
}
.navy-blue-skin .secondary-ic:hover, .navy-blue-skin .secondary-ic:focus {
  color: #7e51b4;
}
.navy-blue-skin a.btn:not([href]):not([tabindex]),
.navy-blue-skin a.btn:not([href]):not([tabindex]):focus,
.navy-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.navy-blue-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.navy-blue-skin .btn-default {
  background-color: #293756 !important;
  color: #fff;
}
.navy-blue-skin .btn-default:hover {
  background-color: #314267;
  color: #fff;
}
.navy-blue-skin .btn-default:focus, .navy-blue-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .btn-default:focus, .navy-blue-skin .btn-default:active, .navy-blue-skin .btn-default.active {
  background-color: #080b11;
}
.navy-blue-skin .btn-default.dropdown-toggle {
  background-color: #293756 !important;
}
.navy-blue-skin .btn-default.dropdown-toggle:hover, .navy-blue-skin .btn-default.dropdown-toggle:focus {
  background-color: #314267 !important;
}
.navy-blue-skin .btn-default:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-default:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #080b11 !important;
}
.navy-blue-skin .btn-default:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .default-ic {
  color: #293756 !important;
}
.navy-blue-skin .default-ic:hover, .navy-blue-skin .default-ic:focus {
  color: #293756;
}
.navy-blue-skin a.btn:not([href]):not([tabindex]),
.navy-blue-skin a.btn:not([href]):not([tabindex]):focus,
.navy-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.navy-blue-skin table.table a.btn.btn-default {
  color: #fff;
}
.navy-blue-skin .btn-outline-primary {
  border: 2px solid #40c6c6 !important;
  background-color: transparent !important;
  color: #40c6c6 !important;
}
.navy-blue-skin .btn-outline-primary:hover, .navy-blue-skin .btn-outline-primary:focus, .navy-blue-skin .btn-outline-primary:active, .navy-blue-skin .btn-outline-primary:active:focus, .navy-blue-skin .btn-outline-primary.active {
  border-color: #40c6c6 !important;
  background-color: transparent !important;
  color: #40c6c6 !important;
}
.navy-blue-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #40c6c6 !important;
}
.navy-blue-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .btn-outline-secondary {
  border: 2px solid #7e51b4 !important;
  background-color: transparent !important;
  color: #7e51b4 !important;
}
.navy-blue-skin .btn-outline-secondary:hover, .navy-blue-skin .btn-outline-secondary:focus, .navy-blue-skin .btn-outline-secondary:active, .navy-blue-skin .btn-outline-secondary:active:focus, .navy-blue-skin .btn-outline-secondary.active {
  border-color: #7e51b4 !important;
  background-color: transparent !important;
  color: #7e51b4 !important;
}
.navy-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #7e51b4 !important;
}
.navy-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .btn-outline-default {
  border: 2px solid #293756 !important;
  background-color: transparent !important;
  color: #293756 !important;
}
.navy-blue-skin .btn-outline-default:hover, .navy-blue-skin .btn-outline-default:focus, .navy-blue-skin .btn-outline-default:active, .navy-blue-skin .btn-outline-default:active:focus, .navy-blue-skin .btn-outline-default.active {
  border-color: #293756 !important;
  background-color: transparent !important;
  color: #293756 !important;
}
.navy-blue-skin .btn-outline-default:not([disabled]):not(.disabled):active, .navy-blue-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .navy-blue-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #293756 !important;
}
.navy-blue-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .navy-blue-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .navy-blue-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.navy-blue-skin .card .btn-action {
  background: #293756;
}
.navy-blue-skin .card .btn-action:hover, .navy-blue-skin .card .btn-action:focus {
  background-color: #314267 !important;
}
.navy-blue-skin .card .btn-action.active {
  background-color: #080b11 !important;
}
.navy-blue-skin .md-outline input[type=text]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=password]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=email]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=url]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=time]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=date]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=tel]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=number]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=search-md]:focus:not([readonly]),
.navy-blue-skin .md-outline input[type=search]:focus:not([readonly]),
.navy-blue-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #40c6c6;
  box-shadow: inset 0px 0px 0px 1px #40c6c6;
}
.navy-blue-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.navy-blue-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #40c6c6;
}
.navy-blue-skin .md-bg input[type=text],
.navy-blue-skin .md-bg input[type=password],
.navy-blue-skin .md-bg input[type=email],
.navy-blue-skin .md-bg input[type=url],
.navy-blue-skin .md-bg input[type=time],
.navy-blue-skin .md-bg input[type=date],
.navy-blue-skin .md-bg input[type=datetime-local],
.navy-blue-skin .md-bg input[type=tel],
.navy-blue-skin .md-bg input[type=number],
.navy-blue-skin .md-bg input[type=search-md],
.navy-blue-skin .md-bg input[type=search],
.navy-blue-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #40c6c6, #40c6c6), linear-gradient(to bottom, #ced4da, #ced4da);
}
.navy-blue-skin input[type=email]:focus:not([readonly]),
.navy-blue-skin input[type=text]:focus:not([readonly]),
.navy-blue-skin input[type=password]:focus:not([readonly]),
.navy-blue-skin input[type=number]:focus:not([readonly]),
.navy-blue-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #40c6c6;
  box-shadow: 0 1px 0 0 #40c6c6;
}
.navy-blue-skin input[type=email]:focus:not([readonly]) + label,
.navy-blue-skin input[type=text]:focus:not([readonly]) + label,
.navy-blue-skin input[type=password]:focus:not([readonly]) + label,
.navy-blue-skin input[type=number]:focus:not([readonly]) + label,
.navy-blue-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #40c6c6;
}
.navy-blue-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #40c6c6;
  border-bottom: 2px solid #40c6c6;
}
.navy-blue-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.navy-blue-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #40c6c6;
  border-color: #40c6c6;
}
.navy-blue-skin .md-form .prefix.active {
  color: #40c6c6;
}
.navy-blue-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #40c6c6 !important;
}
.navy-blue-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #40c6c6;
  box-shadow: inset 0px 0px 0px 1px #40c6c6;
}
.navy-blue-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #40c6c6;
}
.navy-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.navy-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.navy-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #40c6c6 !important;
}
.navy-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.navy-blue-skin .top-nav-collapse {
  background-color: #353b50;
}
.navy-blue-skin .carousel-multi-item .controls-top > a,
.navy-blue-skin .carousel-multi-item .carousel-indicators li,
.navy-blue-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #40c6c6;
}
.navy-blue-skin .form-header,
.navy-blue-skin .card-header {
  background-color: #48c8c8;
}
.navy-blue-skin .spinner-primary-color,
.navy-blue-skin .spinner-primary-color-only {
  border-color: #309e9e;
}
.navy-blue-skin .pagination-primary-color .page-item.active .page-link,
.navy-blue-skin .pagination-primary-color .page-item.active .page-link:focus,
.navy-blue-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #309e9e;
}
.navy-blue-skin .pagination-primary-color .page-link {
  color: #309e9e;
}
.navy-blue-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.pink-skin .gradient {
  background: #812b5a;
  background: linear-gradient(135deg, #812b5a 0%, #f28cc0 100%);
}
.pink-skin .primary-color {
  background-color: #bd5d70 !important;
}
.pink-skin .navbar {
  background-color: #535466;
  color: #fff;
}
.pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .pink-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #9192a2;
}
.pink-skin .navbar.double-nav a {
  color: #fff;
}
.pink-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.pink-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.pink-skin .page-footer {
  background-color: #535466;
}
.pink-skin .side-nav {
  background-color: #aa5077;
}
.pink-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.pink-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #76778b;
}
.pink-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.pink-skin .side-nav .social a:hover .fas,
.pink-skin .side-nav .social a:hover .fab,
.pink-skin .side-nav .social a:hover .far {
  color: #9fa0ae !important;
  transition: all 0.3s linear;
}
.pink-skin .side-nav .collapsible li {
  background-color: transparent;
}
.pink-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.pink-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(0, 0, 0, 0.4);
}
.pink-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.pink-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.pink-skin .side-nav .collapsible li .collapsible-body a:hover, .pink-skin .side-nav .collapsible li .collapsible-body a.active, .pink-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #ffb0e6;
}
.pink-skin .side-nav .collapsible li .collapsible-body a .fas,
.pink-skin .side-nav .collapsible li .collapsible-body a .fab,
.pink-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.pink-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.pink-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .pink-skin .side-nav .collapsible li a:not(.collapsible-header).active, .pink-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #ffb0e6 !important;
}
.pink-skin .side-nav .sidenav-bg:after, .pink-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(152, 47, 88, 0.8);
}
.pink-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(152, 47, 88, 0.65);
}
.pink-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(152, 47, 88, 0.5);
}
.pink-skin .btn-primary {
  background-color: #601f39 !important;
  color: #fff;
}
.pink-skin .btn-primary:hover {
  background-color: #732544;
  color: #fff;
}
.pink-skin .btn-primary:focus, .pink-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .btn-primary:focus, .pink-skin .btn-primary:active, .pink-skin .btn-primary.active {
  background-color: #13060b;
}
.pink-skin .btn-primary.dropdown-toggle {
  background-color: #601f39 !important;
}
.pink-skin .btn-primary.dropdown-toggle:hover, .pink-skin .btn-primary.dropdown-toggle:focus {
  background-color: #732544 !important;
}
.pink-skin .btn-primary:not([disabled]):not(.disabled):active, .pink-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #13060b !important;
}
.pink-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .primary-ic {
  color: #601f39 !important;
}
.pink-skin .primary-ic:hover, .pink-skin .primary-ic:focus {
  color: #601f39;
}
.pink-skin a.btn:not([href]):not([tabindex]),
.pink-skin a.btn:not([href]):not([tabindex]):focus,
.pink-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.pink-skin table.table a.btn.btn-primary {
  color: #fff;
}
.pink-skin .btn-secondary {
  background-color: #e7649d !important;
  color: #fff;
}
.pink-skin .btn-secondary:hover {
  background-color: #ea7aab;
  color: #fff;
}
.pink-skin .btn-secondary:focus, .pink-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .btn-secondary:focus, .pink-skin .btn-secondary:active, .pink-skin .btn-secondary.active {
  background-color: #c61f68;
}
.pink-skin .btn-secondary.dropdown-toggle {
  background-color: #e7649d !important;
}
.pink-skin .btn-secondary.dropdown-toggle:hover, .pink-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #ea7aab !important;
}
.pink-skin .btn-secondary:not([disabled]):not(.disabled):active, .pink-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #c61f68 !important;
}
.pink-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .secondary-ic {
  color: #e7649d !important;
}
.pink-skin .secondary-ic:hover, .pink-skin .secondary-ic:focus {
  color: #e7649d;
}
.pink-skin a.btn:not([href]):not([tabindex]),
.pink-skin a.btn:not([href]):not([tabindex]):focus,
.pink-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.pink-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.pink-skin .btn-default {
  background-color: #535466 !important;
  color: #fff;
}
.pink-skin .btn-default:hover {
  background-color: #5e6074;
  color: #fff;
}
.pink-skin .btn-default:focus, .pink-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .btn-default:focus, .pink-skin .btn-default:active, .pink-skin .btn-default.active {
  background-color: #25262e;
}
.pink-skin .btn-default.dropdown-toggle {
  background-color: #535466 !important;
}
.pink-skin .btn-default.dropdown-toggle:hover, .pink-skin .btn-default.dropdown-toggle:focus {
  background-color: #5e6074 !important;
}
.pink-skin .btn-default:not([disabled]):not(.disabled):active, .pink-skin .btn-default:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #25262e !important;
}
.pink-skin .btn-default:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .default-ic {
  color: #535466 !important;
}
.pink-skin .default-ic:hover, .pink-skin .default-ic:focus {
  color: #535466;
}
.pink-skin a.btn:not([href]):not([tabindex]),
.pink-skin a.btn:not([href]):not([tabindex]):focus,
.pink-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.pink-skin table.table a.btn.btn-default {
  color: #fff;
}
.pink-skin .btn-outline-primary {
  border: 2px solid #601f39 !important;
  background-color: transparent !important;
  color: #601f39 !important;
}
.pink-skin .btn-outline-primary:hover, .pink-skin .btn-outline-primary:focus, .pink-skin .btn-outline-primary:active, .pink-skin .btn-outline-primary:active:focus, .pink-skin .btn-outline-primary.active {
  border-color: #601f39 !important;
  background-color: transparent !important;
  color: #601f39 !important;
}
.pink-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .pink-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #601f39 !important;
}
.pink-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .btn-outline-secondary {
  border: 2px solid #e7649d !important;
  background-color: transparent !important;
  color: #e7649d !important;
}
.pink-skin .btn-outline-secondary:hover, .pink-skin .btn-outline-secondary:focus, .pink-skin .btn-outline-secondary:active, .pink-skin .btn-outline-secondary:active:focus, .pink-skin .btn-outline-secondary.active {
  border-color: #e7649d !important;
  background-color: transparent !important;
  color: #e7649d !important;
}
.pink-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .pink-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #e7649d !important;
}
.pink-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .btn-outline-default {
  border: 2px solid #535466 !important;
  background-color: transparent !important;
  color: #535466 !important;
}
.pink-skin .btn-outline-default:hover, .pink-skin .btn-outline-default:focus, .pink-skin .btn-outline-default:active, .pink-skin .btn-outline-default:active:focus, .pink-skin .btn-outline-default.active {
  border-color: #535466 !important;
  background-color: transparent !important;
  color: #535466 !important;
}
.pink-skin .btn-outline-default:not([disabled]):not(.disabled):active, .pink-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .pink-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #535466 !important;
}
.pink-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .pink-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .pink-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.pink-skin .card .btn-action {
  background: #535466;
}
.pink-skin .card .btn-action:hover, .pink-skin .card .btn-action:focus {
  background-color: #5e6074 !important;
}
.pink-skin .card .btn-action.active {
  background-color: #25262e !important;
}
.pink-skin .md-outline input[type=text]:focus:not([readonly]),
.pink-skin .md-outline input[type=password]:focus:not([readonly]),
.pink-skin .md-outline input[type=email]:focus:not([readonly]),
.pink-skin .md-outline input[type=url]:focus:not([readonly]),
.pink-skin .md-outline input[type=time]:focus:not([readonly]),
.pink-skin .md-outline input[type=date]:focus:not([readonly]),
.pink-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.pink-skin .md-outline input[type=tel]:focus:not([readonly]),
.pink-skin .md-outline input[type=number]:focus:not([readonly]),
.pink-skin .md-outline input[type=search-md]:focus:not([readonly]),
.pink-skin .md-outline input[type=search]:focus:not([readonly]),
.pink-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #9fa0ae;
  box-shadow: inset 0px 0px 0px 1px #9fa0ae;
}
.pink-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.pink-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.pink-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #9fa0ae;
}
.pink-skin .md-bg input[type=text],
.pink-skin .md-bg input[type=password],
.pink-skin .md-bg input[type=email],
.pink-skin .md-bg input[type=url],
.pink-skin .md-bg input[type=time],
.pink-skin .md-bg input[type=date],
.pink-skin .md-bg input[type=datetime-local],
.pink-skin .md-bg input[type=tel],
.pink-skin .md-bg input[type=number],
.pink-skin .md-bg input[type=search-md],
.pink-skin .md-bg input[type=search],
.pink-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #9fa0ae, #9fa0ae), linear-gradient(to bottom, #ced4da, #ced4da);
}
.pink-skin input[type=email]:focus:not([readonly]),
.pink-skin input[type=text]:focus:not([readonly]),
.pink-skin input[type=password]:focus:not([readonly]),
.pink-skin input[type=number]:focus:not([readonly]),
.pink-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #9fa0ae;
  box-shadow: 0 1px 0 0 #9fa0ae;
}
.pink-skin input[type=email]:focus:not([readonly]) + label,
.pink-skin input[type=text]:focus:not([readonly]) + label,
.pink-skin input[type=password]:focus:not([readonly]) + label,
.pink-skin input[type=number]:focus:not([readonly]) + label,
.pink-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #9fa0ae;
}
.pink-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #9fa0ae;
  border-bottom: 2px solid #9fa0ae;
}
.pink-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.pink-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #9fa0ae;
  border-color: #9fa0ae;
}
.pink-skin .md-form .prefix.active {
  color: #9fa0ae;
}
.pink-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #9fa0ae !important;
}
.pink-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #9fa0ae;
  box-shadow: inset 0px 0px 0px 1px #9fa0ae;
}
.pink-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #9fa0ae;
}
.pink-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.pink-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.pink-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #9fa0ae !important;
}
.pink-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.pink-skin .top-nav-collapse {
  background-color: #535466;
}
.pink-skin .carousel-multi-item .controls-top > a,
.pink-skin .carousel-multi-item .carousel-indicators li,
.pink-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #9fa0ae;
}
.pink-skin .form-header,
.pink-skin .card-header {
  background-color: #a5a5b3;
}
.pink-skin .spinner-primary-color,
.pink-skin .spinner-primary-color-only {
  border-color: #bd5d70;
}
.pink-skin .pagination-primary-color .page-item.active .page-link,
.pink-skin .pagination-primary-color .page-item.active .page-link:focus,
.pink-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #bd5d70;
}
.pink-skin .pagination-primary-color .page-link {
  color: #bd5d70;
}
.pink-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.indigo-skin .gradient {
  background: #272d6e;
  background: linear-gradient(135deg, #272d6e 0%, #90a8ff 100%);
}
.indigo-skin .primary-color {
  background-color: #7f82c5 !important;
}
.indigo-skin .navbar {
  background-color: #9095aa;
  color: #fff;
}
.indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .indigo-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #fd4d79;
}
.indigo-skin .navbar.double-nav a {
  color: #fff;
}
.indigo-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.indigo-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.indigo-skin .page-footer {
  background-color: #9095aa;
}
.indigo-skin .side-nav {
  background-color: #404f9f;
}
.indigo-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.indigo-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #fc1a53;
}
.indigo-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.indigo-skin .side-nav .social a:hover .fas,
.indigo-skin .side-nav .social a:hover .fab,
.indigo-skin .side-nav .social a:hover .far {
  color: #fd668c !important;
  transition: all 0.3s linear;
}
.indigo-skin .side-nav .collapsible li {
  background-color: transparent;
}
.indigo-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.indigo-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(250, 80, 121, 0.8);
}
.indigo-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(250, 80, 121, 0.8);
}
.indigo-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.indigo-skin .side-nav .collapsible li .collapsible-body a:hover, .indigo-skin .side-nav .collapsible li .collapsible-body a.active, .indigo-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #ff89ac;
}
.indigo-skin .side-nav .collapsible li .collapsible-body a .fas,
.indigo-skin .side-nav .collapsible li .collapsible-body a .fab,
.indigo-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.indigo-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.indigo-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .indigo-skin .side-nav .collapsible li a:not(.collapsible-header).active, .indigo-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #ff89ac !important;
}
.indigo-skin .side-nav .sidenav-bg:after, .indigo-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(35, 65, 134, 0.8);
}
.indigo-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(35, 65, 134, 0.65);
}
.indigo-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(35, 65, 134, 0.5);
}
.indigo-skin .btn-primary {
  background-color: #fd668c !important;
  color: #fff;
}
.indigo-skin .btn-primary:hover {
  background-color: #fd7f9f;
  color: #fff;
}
.indigo-skin .btn-primary:focus, .indigo-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .btn-primary:focus, .indigo-skin .btn-primary:active, .indigo-skin .btn-primary.active {
  background-color: #fa0341;
}
.indigo-skin .btn-primary.dropdown-toggle {
  background-color: #fd668c !important;
}
.indigo-skin .btn-primary.dropdown-toggle:hover, .indigo-skin .btn-primary.dropdown-toggle:focus {
  background-color: #fd7f9f !important;
}
.indigo-skin .btn-primary:not([disabled]):not(.disabled):active, .indigo-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #fa0341 !important;
}
.indigo-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .primary-ic {
  color: #fd668c !important;
}
.indigo-skin .primary-ic:hover, .indigo-skin .primary-ic:focus {
  color: #fd668c;
}
.indigo-skin a.btn:not([href]):not([tabindex]),
.indigo-skin a.btn:not([href]):not([tabindex]):focus,
.indigo-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.indigo-skin table.table a.btn.btn-primary {
  color: #fff;
}
.indigo-skin .btn-secondary {
  background-color: #3c61ae !important;
  color: #fff;
}
.indigo-skin .btn-secondary:hover {
  background-color: #456cbf;
  color: #fff;
}
.indigo-skin .btn-secondary:focus, .indigo-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .btn-secondary:focus, .indigo-skin .btn-secondary:active, .indigo-skin .btn-secondary.active {
  background-color: #223762;
}
.indigo-skin .btn-secondary.dropdown-toggle {
  background-color: #3c61ae !important;
}
.indigo-skin .btn-secondary.dropdown-toggle:hover, .indigo-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #456cbf !important;
}
.indigo-skin .btn-secondary:not([disabled]):not(.disabled):active, .indigo-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #223762 !important;
}
.indigo-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .secondary-ic {
  color: #3c61ae !important;
}
.indigo-skin .secondary-ic:hover, .indigo-skin .secondary-ic:focus {
  color: #3c61ae;
}
.indigo-skin a.btn:not([href]):not([tabindex]),
.indigo-skin a.btn:not([href]):not([tabindex]):focus,
.indigo-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.indigo-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.indigo-skin .btn-default {
  background-color: #9095aa !important;
  color: #fff;
}
.indigo-skin .btn-default:hover {
  background-color: #9ea3b5;
  color: #fff;
}
.indigo-skin .btn-default:focus, .indigo-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .btn-default:focus, .indigo-skin .btn-default:active, .indigo-skin .btn-default.active {
  background-color: #5c6178;
}
.indigo-skin .btn-default.dropdown-toggle {
  background-color: #9095aa !important;
}
.indigo-skin .btn-default.dropdown-toggle:hover, .indigo-skin .btn-default.dropdown-toggle:focus {
  background-color: #9ea3b5 !important;
}
.indigo-skin .btn-default:not([disabled]):not(.disabled):active, .indigo-skin .btn-default:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #5c6178 !important;
}
.indigo-skin .btn-default:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .default-ic {
  color: #9095aa !important;
}
.indigo-skin .default-ic:hover, .indigo-skin .default-ic:focus {
  color: #9095aa;
}
.indigo-skin a.btn:not([href]):not([tabindex]),
.indigo-skin a.btn:not([href]):not([tabindex]):focus,
.indigo-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.indigo-skin table.table a.btn.btn-default {
  color: #fff;
}
.indigo-skin .btn-outline-primary {
  border: 2px solid #fd668c !important;
  background-color: transparent !important;
  color: #fd668c !important;
}
.indigo-skin .btn-outline-primary:hover, .indigo-skin .btn-outline-primary:focus, .indigo-skin .btn-outline-primary:active, .indigo-skin .btn-outline-primary:active:focus, .indigo-skin .btn-outline-primary.active {
  border-color: #fd668c !important;
  background-color: transparent !important;
  color: #fd668c !important;
}
.indigo-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .indigo-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #fd668c !important;
}
.indigo-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .btn-outline-secondary {
  border: 2px solid #3c61ae !important;
  background-color: transparent !important;
  color: #3c61ae !important;
}
.indigo-skin .btn-outline-secondary:hover, .indigo-skin .btn-outline-secondary:focus, .indigo-skin .btn-outline-secondary:active, .indigo-skin .btn-outline-secondary:active:focus, .indigo-skin .btn-outline-secondary.active {
  border-color: #3c61ae !important;
  background-color: transparent !important;
  color: #3c61ae !important;
}
.indigo-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .indigo-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #3c61ae !important;
}
.indigo-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .btn-outline-default {
  border: 2px solid #9095aa !important;
  background-color: transparent !important;
  color: #9095aa !important;
}
.indigo-skin .btn-outline-default:hover, .indigo-skin .btn-outline-default:focus, .indigo-skin .btn-outline-default:active, .indigo-skin .btn-outline-default:active:focus, .indigo-skin .btn-outline-default.active {
  border-color: #9095aa !important;
  background-color: transparent !important;
  color: #9095aa !important;
}
.indigo-skin .btn-outline-default:not([disabled]):not(.disabled):active, .indigo-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .indigo-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #9095aa !important;
}
.indigo-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .indigo-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .indigo-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.indigo-skin .card .btn-action {
  background: #9095aa;
}
.indigo-skin .card .btn-action:hover, .indigo-skin .card .btn-action:focus {
  background-color: #9ea3b5 !important;
}
.indigo-skin .card .btn-action.active {
  background-color: #5c6178 !important;
}
.indigo-skin .md-outline input[type=text]:focus:not([readonly]),
.indigo-skin .md-outline input[type=password]:focus:not([readonly]),
.indigo-skin .md-outline input[type=email]:focus:not([readonly]),
.indigo-skin .md-outline input[type=url]:focus:not([readonly]),
.indigo-skin .md-outline input[type=time]:focus:not([readonly]),
.indigo-skin .md-outline input[type=date]:focus:not([readonly]),
.indigo-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.indigo-skin .md-outline input[type=tel]:focus:not([readonly]),
.indigo-skin .md-outline input[type=number]:focus:not([readonly]),
.indigo-skin .md-outline input[type=search-md]:focus:not([readonly]),
.indigo-skin .md-outline input[type=search]:focus:not([readonly]),
.indigo-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #fd668c;
  box-shadow: inset 0px 0px 0px 1px #fd668c;
}
.indigo-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.indigo-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.indigo-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #fd668c;
}
.indigo-skin .md-bg input[type=text],
.indigo-skin .md-bg input[type=password],
.indigo-skin .md-bg input[type=email],
.indigo-skin .md-bg input[type=url],
.indigo-skin .md-bg input[type=time],
.indigo-skin .md-bg input[type=date],
.indigo-skin .md-bg input[type=datetime-local],
.indigo-skin .md-bg input[type=tel],
.indigo-skin .md-bg input[type=number],
.indigo-skin .md-bg input[type=search-md],
.indigo-skin .md-bg input[type=search],
.indigo-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #fd668c, #fd668c), linear-gradient(to bottom, #ced4da, #ced4da);
}
.indigo-skin input[type=email]:focus:not([readonly]),
.indigo-skin input[type=text]:focus:not([readonly]),
.indigo-skin input[type=password]:focus:not([readonly]),
.indigo-skin input[type=number]:focus:not([readonly]),
.indigo-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #fd668c;
  box-shadow: 0 1px 0 0 #fd668c;
}
.indigo-skin input[type=email]:focus:not([readonly]) + label,
.indigo-skin input[type=text]:focus:not([readonly]) + label,
.indigo-skin input[type=password]:focus:not([readonly]) + label,
.indigo-skin input[type=number]:focus:not([readonly]) + label,
.indigo-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #fd668c;
}
.indigo-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #fd668c;
  border-bottom: 2px solid #fd668c;
}
.indigo-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.indigo-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #fd668c;
  border-color: #fd668c;
}
.indigo-skin .md-form .prefix.active {
  color: #fd668c;
}
.indigo-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #fd668c !important;
}
.indigo-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #fd668c;
  box-shadow: inset 0px 0px 0px 1px #fd668c;
}
.indigo-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #fd668c;
}
.indigo-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.indigo-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.indigo-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #fd668c !important;
}
.indigo-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.indigo-skin .top-nav-collapse {
  background-color: #9095aa;
}
.indigo-skin .carousel-multi-item .controls-top > a,
.indigo-skin .carousel-multi-item .carousel-indicators li,
.indigo-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #fd668c;
}
.indigo-skin .form-header,
.indigo-skin .card-header {
  background-color: #fd7094;
}
.indigo-skin .spinner-primary-color,
.indigo-skin .spinner-primary-color-only {
  border-color: #7f82c5;
}
.indigo-skin .pagination-primary-color .page-item.active .page-link,
.indigo-skin .pagination-primary-color .page-item.active .page-link:focus,
.indigo-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #7f82c5;
}
.indigo-skin .pagination-primary-color .page-link {
  color: #7f82c5;
}
.indigo-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.light-blue-skin .gradient {
  background: #69adf4;
  background: linear-gradient(135deg, #69adf4 0%, #69adf4 100%);
}
.light-blue-skin .primary-color {
  background-color: #3d799c !important;
}
.light-blue-skin .navbar {
  background-color: #3f5c80;
  color: #fff;
}
.light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .light-blue-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #a5b6be;
}
.light-blue-skin .navbar.double-nav a {
  color: #fff;
}
.light-blue-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.light-blue-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.light-blue-skin .page-footer {
  background-color: #3f5c80;
}
.light-blue-skin .side-nav {
  background-color: #6a9ed3;
}
.light-blue-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.light-blue-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #889ea9;
}
.light-blue-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.light-blue-skin .side-nav .social a:hover .fas,
.light-blue-skin .side-nav .social a:hover .fab,
.light-blue-skin .side-nav .social a:hover .far {
  color: #b4c2c9 !important;
  transition: all 0.3s linear;
}
.light-blue-skin .side-nav .collapsible li {
  background-color: transparent;
}
.light-blue-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.light-blue-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(29, 54, 86, 0.6);
}
.light-blue-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(29, 54, 86, 0.6);
}
.light-blue-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible li .collapsible-body a:hover, .light-blue-skin .side-nav .collapsible li .collapsible-body a.active, .light-blue-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #aadeff;
}
.light-blue-skin .side-nav .collapsible li .collapsible-body a .fas,
.light-blue-skin .side-nav .collapsible li .collapsible-body a .fab,
.light-blue-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.light-blue-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .light-blue-skin .side-nav .collapsible li a:not(.collapsible-header).active, .light-blue-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #aadeff !important;
}
.light-blue-skin .side-nav .sidenav-bg:after, .light-blue-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(87, 134, 180, 0.8);
}
.light-blue-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(87, 134, 180, 0.65);
}
.light-blue-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(87, 134, 180, 0.5);
}
.light-blue-skin .btn-primary {
  background-color: #ff4a67 !important;
  color: #fff;
}
.light-blue-skin .btn-primary:hover {
  background-color: #ff647c;
  color: #fff;
}
.light-blue-skin .btn-primary:focus, .light-blue-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .btn-primary:focus, .light-blue-skin .btn-primary:active, .light-blue-skin .btn-primary.active {
  background-color: #e30024;
}
.light-blue-skin .btn-primary.dropdown-toggle {
  background-color: #ff4a67 !important;
}
.light-blue-skin .btn-primary.dropdown-toggle:hover, .light-blue-skin .btn-primary.dropdown-toggle:focus {
  background-color: #ff647c !important;
}
.light-blue-skin .btn-primary:not([disabled]):not(.disabled):active, .light-blue-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #e30024 !important;
}
.light-blue-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .primary-ic {
  color: #ff4a67 !important;
}
.light-blue-skin .primary-ic:hover, .light-blue-skin .primary-ic:focus {
  color: #ff4a67;
}
.light-blue-skin a.btn:not([href]):not([tabindex]),
.light-blue-skin a.btn:not([href]):not([tabindex]):focus,
.light-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.light-blue-skin table.table a.btn.btn-primary {
  color: #fff;
}
.light-blue-skin .btn-secondary {
  background-color: #64c3f0 !important;
  color: #fff;
}
.light-blue-skin .btn-secondary:hover {
  background-color: #7bccf2;
  color: #fff;
}
.light-blue-skin .btn-secondary:focus, .light-blue-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .btn-secondary:focus, .light-blue-skin .btn-secondary:active, .light-blue-skin .btn-secondary.active {
  background-color: #159ad9;
}
.light-blue-skin .btn-secondary.dropdown-toggle {
  background-color: #64c3f0 !important;
}
.light-blue-skin .btn-secondary.dropdown-toggle:hover, .light-blue-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #7bccf2 !important;
}
.light-blue-skin .btn-secondary:not([disabled]):not(.disabled):active, .light-blue-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #159ad9 !important;
}
.light-blue-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .secondary-ic {
  color: #64c3f0 !important;
}
.light-blue-skin .secondary-ic:hover, .light-blue-skin .secondary-ic:focus {
  color: #64c3f0;
}
.light-blue-skin a.btn:not([href]):not([tabindex]),
.light-blue-skin a.btn:not([href]):not([tabindex]):focus,
.light-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.light-blue-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.light-blue-skin .btn-default {
  background-color: #2d486a !important;
  color: #fff;
}
.light-blue-skin .btn-default:hover {
  background-color: #35547c;
  color: #fff;
}
.light-blue-skin .btn-default:focus, .light-blue-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .btn-default:focus, .light-blue-skin .btn-default:active, .light-blue-skin .btn-default.active {
  background-color: #0f1722;
}
.light-blue-skin .btn-default.dropdown-toggle {
  background-color: #2d486a !important;
}
.light-blue-skin .btn-default.dropdown-toggle:hover, .light-blue-skin .btn-default.dropdown-toggle:focus {
  background-color: #35547c !important;
}
.light-blue-skin .btn-default:not([disabled]):not(.disabled):active, .light-blue-skin .btn-default:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #0f1722 !important;
}
.light-blue-skin .btn-default:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .default-ic {
  color: #2d486a !important;
}
.light-blue-skin .default-ic:hover, .light-blue-skin .default-ic:focus {
  color: #2d486a;
}
.light-blue-skin a.btn:not([href]):not([tabindex]),
.light-blue-skin a.btn:not([href]):not([tabindex]):focus,
.light-blue-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.light-blue-skin table.table a.btn.btn-default {
  color: #fff;
}
.light-blue-skin .btn-outline-primary {
  border: 2px solid #ff4a67 !important;
  background-color: transparent !important;
  color: #ff4a67 !important;
}
.light-blue-skin .btn-outline-primary:hover, .light-blue-skin .btn-outline-primary:focus, .light-blue-skin .btn-outline-primary:active, .light-blue-skin .btn-outline-primary:active:focus, .light-blue-skin .btn-outline-primary.active {
  border-color: #ff4a67 !important;
  background-color: transparent !important;
  color: #ff4a67 !important;
}
.light-blue-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .light-blue-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ff4a67 !important;
}
.light-blue-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .btn-outline-secondary {
  border: 2px solid #64c3f0 !important;
  background-color: transparent !important;
  color: #64c3f0 !important;
}
.light-blue-skin .btn-outline-secondary:hover, .light-blue-skin .btn-outline-secondary:focus, .light-blue-skin .btn-outline-secondary:active, .light-blue-skin .btn-outline-secondary:active:focus, .light-blue-skin .btn-outline-secondary.active {
  border-color: #64c3f0 !important;
  background-color: transparent !important;
  color: #64c3f0 !important;
}
.light-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .light-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #64c3f0 !important;
}
.light-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .btn-outline-default {
  border: 2px solid #2d486a !important;
  background-color: transparent !important;
  color: #2d486a !important;
}
.light-blue-skin .btn-outline-default:hover, .light-blue-skin .btn-outline-default:focus, .light-blue-skin .btn-outline-default:active, .light-blue-skin .btn-outline-default:active:focus, .light-blue-skin .btn-outline-default.active {
  border-color: #2d486a !important;
  background-color: transparent !important;
  color: #2d486a !important;
}
.light-blue-skin .btn-outline-default:not([disabled]):not(.disabled):active, .light-blue-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .light-blue-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #2d486a !important;
}
.light-blue-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .light-blue-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .light-blue-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.light-blue-skin .card .btn-action {
  background: #2d486a;
}
.light-blue-skin .card .btn-action:hover, .light-blue-skin .card .btn-action:focus {
  background-color: #35547c !important;
}
.light-blue-skin .card .btn-action.active {
  background-color: #0f1722 !important;
}
.light-blue-skin .md-outline input[type=text]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=password]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=email]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=url]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=time]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=date]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=tel]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=number]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=search-md]:focus:not([readonly]),
.light-blue-skin .md-outline input[type=search]:focus:not([readonly]),
.light-blue-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #b4c2c9;
  box-shadow: inset 0px 0px 0px 1px #b4c2c9;
}
.light-blue-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.light-blue-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.light-blue-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #b4c2c9;
}
.light-blue-skin .md-bg input[type=text],
.light-blue-skin .md-bg input[type=password],
.light-blue-skin .md-bg input[type=email],
.light-blue-skin .md-bg input[type=url],
.light-blue-skin .md-bg input[type=time],
.light-blue-skin .md-bg input[type=date],
.light-blue-skin .md-bg input[type=datetime-local],
.light-blue-skin .md-bg input[type=tel],
.light-blue-skin .md-bg input[type=number],
.light-blue-skin .md-bg input[type=search-md],
.light-blue-skin .md-bg input[type=search],
.light-blue-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #b4c2c9, #b4c2c9), linear-gradient(to bottom, #ced4da, #ced4da);
}
.light-blue-skin input[type=email]:focus:not([readonly]),
.light-blue-skin input[type=text]:focus:not([readonly]),
.light-blue-skin input[type=password]:focus:not([readonly]),
.light-blue-skin input[type=number]:focus:not([readonly]),
.light-blue-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #b4c2c9;
  box-shadow: 0 1px 0 0 #b4c2c9;
}
.light-blue-skin input[type=email]:focus:not([readonly]) + label,
.light-blue-skin input[type=text]:focus:not([readonly]) + label,
.light-blue-skin input[type=password]:focus:not([readonly]) + label,
.light-blue-skin input[type=number]:focus:not([readonly]) + label,
.light-blue-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #b4c2c9;
}
.light-blue-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #b4c2c9;
  border-bottom: 2px solid #b4c2c9;
}
.light-blue-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.light-blue-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #b4c2c9;
  border-color: #b4c2c9;
}
.light-blue-skin .md-form .prefix.active {
  color: #b4c2c9;
}
.light-blue-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #b4c2c9 !important;
}
.light-blue-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #b4c2c9;
  box-shadow: inset 0px 0px 0px 1px #b4c2c9;
}
.light-blue-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #b4c2c9;
}
.light-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.light-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.light-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #b4c2c9 !important;
}
.light-blue-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.light-blue-skin .top-nav-collapse {
  background-color: #3f5c80;
}
.light-blue-skin .carousel-multi-item .controls-top > a,
.light-blue-skin .carousel-multi-item .carousel-indicators li,
.light-blue-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #b4c2c9;
}
.light-blue-skin .form-header,
.light-blue-skin .card-header {
  background-color: #bac7cd;
}
.light-blue-skin .spinner-primary-color,
.light-blue-skin .spinner-primary-color-only {
  border-color: #3d799c;
}
.light-blue-skin .pagination-primary-color .page-item.active .page-link,
.light-blue-skin .pagination-primary-color .page-item.active .page-link:focus,
.light-blue-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #3d799c;
}
.light-blue-skin .pagination-primary-color .page-link {
  color: #3d799c;
}
.light-blue-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.grey-skin .gradient {
  background: #383838;
  background: linear-gradient(135deg, #383838 0%, #a5a4a4 100%);
}
.grey-skin .primary-color {
  background-color: #2b2b2b !important;
}
.grey-skin .navbar {
  background-color: #44474b;
  color: #fff;
}
.grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #5eb7ee;
}
.grey-skin .navbar.double-nav a {
  color: #fff;
}
.grey-skin .navbar form .md-form .form-control {
  color: #fff;
  font-weight: 300;
}
.grey-skin .navbar form .md-form .form-control::placeholder {
  color: #fff;
}
.grey-skin .page-footer {
  background-color: #44474b;
}
.grey-skin .side-nav {
  background-color: #a5a4a4;
}
.grey-skin .side-nav .logo-wrapper > div {
  background-color: transparent !important;
}
.grey-skin .side-nav .sn-avatar-wrapper img {
  border: 3px solid #30a2e9;
}
.grey-skin .side-nav .social {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}
.grey-skin .side-nav .social a:hover .fas,
.grey-skin .side-nav .social a:hover .fab,
.grey-skin .side-nav .social a:hover .far {
  color: #75c1f0 !important;
  transition: all 0.3s linear;
}
.grey-skin .side-nav .collapsible li {
  background-color: transparent;
}
.grey-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
}
.grey-skin .side-nav .collapsible li .collapsible-header.active {
  background-color: rgba(0, 0, 0, 0.4);
}
.grey-skin .side-nav .collapsible li .collapsible-header:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.grey-skin .side-nav .collapsible li .collapsible-body a {
  color: #fff;
}
.grey-skin .side-nav .collapsible li .collapsible-body a:hover, .grey-skin .side-nav .collapsible li .collapsible-body a.active, .grey-skin .side-nav .collapsible li .collapsible-body a:active {
  color: #afdffc;
}
.grey-skin .side-nav .collapsible li .collapsible-body a .fas,
.grey-skin .side-nav .collapsible li .collapsible-body a .fab,
.grey-skin .side-nav .collapsible li .collapsible-body a .far {
  color: #fff;
}
.grey-skin .side-nav .collapsible li a:not(.collapsible-header) {
  color: #fff;
  transition: all 0.3s linear;
}
.grey-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .grey-skin .side-nav .collapsible li a:not(.collapsible-header).active, .grey-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: #afdffc !important;
}
.grey-skin .side-nav .sidenav-bg:after, .grey-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgba(121, 121, 121, 0.8);
}
.grey-skin .side-nav .sidenav-bg.mask-light:after {
  background: rgba(121, 121, 121, 0.65);
}
.grey-skin .side-nav .sidenav-bg.mask-slight:after {
  background: rgba(121, 121, 121, 0.5);
}
.grey-skin .btn-primary {
  background-color: #46aded !important;
  color: #fff;
}
.grey-skin .btn-primary:hover {
  background-color: #5db7ef;
  color: #fff;
}
.grey-skin .btn-primary:focus, .grey-skin .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .btn-primary:focus, .grey-skin .btn-primary:active, .grey-skin .btn-primary.active {
  background-color: #127abb;
}
.grey-skin .btn-primary.dropdown-toggle {
  background-color: #46aded !important;
}
.grey-skin .btn-primary.dropdown-toggle:hover, .grey-skin .btn-primary.dropdown-toggle:focus {
  background-color: #5db7ef !important;
}
.grey-skin .btn-primary:not([disabled]):not(.disabled):active, .grey-skin .btn-primary:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #127abb !important;
}
.grey-skin .btn-primary:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .primary-ic {
  color: #46aded !important;
}
.grey-skin .primary-ic:hover, .grey-skin .primary-ic:focus {
  color: #46aded;
}
.grey-skin a.btn:not([href]):not([tabindex]),
.grey-skin a.btn:not([href]):not([tabindex]):focus,
.grey-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.grey-skin table.table a.btn.btn-primary {
  color: #fff;
}
.grey-skin .btn-secondary {
  background-color: #7d8488 !important;
  color: #fff;
}
.grey-skin .btn-secondary:hover {
  background-color: #8a9194;
  color: #fff;
}
.grey-skin .btn-secondary:focus, .grey-skin .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .btn-secondary:focus, .grey-skin .btn-secondary:active, .grey-skin .btn-secondary.active {
  background-color: #4c5053;
}
.grey-skin .btn-secondary.dropdown-toggle {
  background-color: #7d8488 !important;
}
.grey-skin .btn-secondary.dropdown-toggle:hover, .grey-skin .btn-secondary.dropdown-toggle:focus {
  background-color: #8a9194 !important;
}
.grey-skin .btn-secondary:not([disabled]):not(.disabled):active, .grey-skin .btn-secondary:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #4c5053 !important;
}
.grey-skin .btn-secondary:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .secondary-ic {
  color: #7d8488 !important;
}
.grey-skin .secondary-ic:hover, .grey-skin .secondary-ic:focus {
  color: #7d8488;
}
.grey-skin a.btn:not([href]):not([tabindex]),
.grey-skin a.btn:not([href]):not([tabindex]):focus,
.grey-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.grey-skin table.table a.btn.btn-secondary {
  color: #fff;
}
.grey-skin .btn-default {
  background-color: #353637 !important;
  color: #fff;
}
.grey-skin .btn-default:hover {
  background-color: #424344;
  color: #fff;
}
.grey-skin .btn-default:focus, .grey-skin .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .btn-default:focus, .grey-skin .btn-default:active, .grey-skin .btn-default.active {
  background-color: #030303;
}
.grey-skin .btn-default.dropdown-toggle {
  background-color: #353637 !important;
}
.grey-skin .btn-default.dropdown-toggle:hover, .grey-skin .btn-default.dropdown-toggle:focus {
  background-color: #424344 !important;
}
.grey-skin .btn-default:not([disabled]):not(.disabled):active, .grey-skin .btn-default:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #030303 !important;
}
.grey-skin .btn-default:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-default:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .default-ic {
  color: #353637 !important;
}
.grey-skin .default-ic:hover, .grey-skin .default-ic:focus {
  color: #353637;
}
.grey-skin a.btn:not([href]):not([tabindex]),
.grey-skin a.btn:not([href]):not([tabindex]):focus,
.grey-skin a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.grey-skin table.table a.btn.btn-default {
  color: #fff;
}
.grey-skin .btn-outline-primary {
  border: 2px solid #46aded !important;
  background-color: transparent !important;
  color: #46aded !important;
}
.grey-skin .btn-outline-primary:hover, .grey-skin .btn-outline-primary:focus, .grey-skin .btn-outline-primary:active, .grey-skin .btn-outline-primary:active:focus, .grey-skin .btn-outline-primary.active {
  border-color: #46aded !important;
  background-color: transparent !important;
  color: #46aded !important;
}
.grey-skin .btn-outline-primary:not([disabled]):not(.disabled):active, .grey-skin .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #46aded !important;
}
.grey-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .btn-outline-secondary {
  border: 2px solid #7d8488 !important;
  background-color: transparent !important;
  color: #7d8488 !important;
}
.grey-skin .btn-outline-secondary:hover, .grey-skin .btn-outline-secondary:focus, .grey-skin .btn-outline-secondary:active, .grey-skin .btn-outline-secondary:active:focus, .grey-skin .btn-outline-secondary.active {
  border-color: #7d8488 !important;
  background-color: transparent !important;
  color: #7d8488 !important;
}
.grey-skin .btn-outline-secondary:not([disabled]):not(.disabled):active, .grey-skin .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #7d8488 !important;
}
.grey-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .btn-outline-default {
  border: 2px solid #353637 !important;
  background-color: transparent !important;
  color: #353637 !important;
}
.grey-skin .btn-outline-default:hover, .grey-skin .btn-outline-default:focus, .grey-skin .btn-outline-default:active, .grey-skin .btn-outline-default:active:focus, .grey-skin .btn-outline-default.active {
  border-color: #353637 !important;
  background-color: transparent !important;
  color: #353637 !important;
}
.grey-skin .btn-outline-default:not([disabled]):not(.disabled):active, .grey-skin .btn-outline-default:not([disabled]):not(.disabled).active, .show > .grey-skin .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #353637 !important;
}
.grey-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus, .grey-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .grey-skin .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.grey-skin .card .btn-action {
  background: #353637;
}
.grey-skin .card .btn-action:hover, .grey-skin .card .btn-action:focus {
  background-color: #424344 !important;
}
.grey-skin .card .btn-action.active {
  background-color: #030303 !important;
}
.grey-skin .md-outline input[type=text]:focus:not([readonly]),
.grey-skin .md-outline input[type=password]:focus:not([readonly]),
.grey-skin .md-outline input[type=email]:focus:not([readonly]),
.grey-skin .md-outline input[type=url]:focus:not([readonly]),
.grey-skin .md-outline input[type=time]:focus:not([readonly]),
.grey-skin .md-outline input[type=date]:focus:not([readonly]),
.grey-skin .md-outline input[type=datetime-local]:focus:not([readonly]),
.grey-skin .md-outline input[type=tel]:focus:not([readonly]),
.grey-skin .md-outline input[type=number]:focus:not([readonly]),
.grey-skin .md-outline input[type=search-md]:focus:not([readonly]),
.grey-skin .md-outline input[type=search]:focus:not([readonly]),
.grey-skin .md-outline textarea:focus:not([readonly]) {
  border-color: #75c1f0;
  box-shadow: inset 0px 0px 0px 1px #75c1f0;
}
.grey-skin .md-outline input[type=text]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=password]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=email]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=url]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=time]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=date]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=datetime-local]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=tel]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=number]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=search-md]:focus:not([readonly]) + label,
.grey-skin .md-outline input[type=search]:focus:not([readonly]) + label,
.grey-skin .md-outline textarea:focus:not([readonly]) + label {
  color: #75c1f0;
}
.grey-skin .md-bg input[type=text],
.grey-skin .md-bg input[type=password],
.grey-skin .md-bg input[type=email],
.grey-skin .md-bg input[type=url],
.grey-skin .md-bg input[type=time],
.grey-skin .md-bg input[type=date],
.grey-skin .md-bg input[type=datetime-local],
.grey-skin .md-bg input[type=tel],
.grey-skin .md-bg input[type=number],
.grey-skin .md-bg input[type=search-md],
.grey-skin .md-bg input[type=search],
.grey-skin .md-bg textarea.md-textarea {
  background-image: linear-gradient(to bottom, #75c1f0, #75c1f0), linear-gradient(to bottom, #ced4da, #ced4da);
}
.grey-skin input[type=email]:focus:not([readonly]),
.grey-skin input[type=text]:focus:not([readonly]),
.grey-skin input[type=password]:focus:not([readonly]),
.grey-skin input[type=number]:focus:not([readonly]),
.grey-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #75c1f0;
  box-shadow: 0 1px 0 0 #75c1f0;
}
.grey-skin input[type=email]:focus:not([readonly]) + label,
.grey-skin input[type=text]:focus:not([readonly]) + label,
.grey-skin input[type=password]:focus:not([readonly]) + label,
.grey-skin input[type=number]:focus:not([readonly]) + label,
.grey-skin textarea.md-textarea:focus:not([readonly]) + label {
  color: #75c1f0;
}
.grey-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #75c1f0;
  border-bottom: 2px solid #75c1f0;
}
.grey-skin input[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.grey-skin input[type=checkbox].filled-in:checked + label:after {
  background-color: #75c1f0;
  border-color: #75c1f0;
}
.grey-skin .md-form .prefix.active {
  color: #75c1f0;
}
.grey-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
  color: #75c1f0 !important;
}
.grey-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
  border-color: #75c1f0;
  box-shadow: inset 0px 0px 0px 1px #75c1f0;
}
.grey-skin .select-wrapper.colorful-select.md-form.md-outline + label.active {
  color: #75c1f0;
}
.grey-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,
.grey-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,
.grey-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
  background-color: #75c1f0 !important;
}
.grey-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
  background-color: transparent !important;
}
.grey-skin .top-nav-collapse {
  background-color: #44474b;
}
.grey-skin .carousel-multi-item .controls-top > a,
.grey-skin .carousel-multi-item .carousel-indicators li,
.grey-skin .carousel-multi-item .carousel-indicators li.active {
  background-color: #75c1f0;
}
.grey-skin .form-header,
.grey-skin .card-header {
  background-color: #7ec5f1;
}
.grey-skin .spinner-primary-color,
.grey-skin .spinner-primary-color-only {
  border-color: #2b2b2b;
}
.grey-skin .pagination-primary-color .page-item.active .page-link,
.grey-skin .pagination-primary-color .page-item.active .page-link:focus,
.grey-skin .pagination-primary-color .page-item.active .page-link:hover {
  color: #fff;
  background-color: #2b2b2b;
}
.grey-skin .pagination-primary-color .page-link {
  color: #2b2b2b;
}
.grey-skin .pagination-primary-color .page-link:focus {
  box-shadow: none;
}

.white-skin .pills-primary .nav-link.active {
  background-color: #4285f4 !important;
}
.white-skin .pills-secondary .nav-link.active {
  background-color: #fc685f !important;
}
.white-skin .pills-default .nav-link.active {
  background-color: #454545 !important;
}
.white-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.white-skin .navbar-nav .nav-item .dropdown-menu a:hover, .white-skin .navbar-nav .nav-item .dropdown-menu a:focus, .white-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #2a75f3 !important;
}
.white-skin .navbar-nav .nav-item a {
  color: #424242;
}
.white-skin .dropdown-menu a {
  color: #000;
}
.white-skin .dropdown-menu a:hover, .white-skin .dropdown-menu a:focus, .white-skin .dropdown-menu a:active {
  background-color: #2a75f3 !important;
}
.white-skin .range-cloud {
  background-color: #4285f4 !important;
}
.white-skin .range-cloud:after {
  border-top-color: #4285f4 !important;
}
.white-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #4285f4;
}
.white-skin .range-field input[type=range]::-moz-range-thumb {
  background: #4285f4;
}
.white-skin .range-field input[type=range]::-ms-thumb {
  background: #4285f4;
}
.white-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.white-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.white-skin .side-nav .sidenav-bg {
  position: fixed;
}
.white-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.white-skin .side-nav .social a:hover .fa {
  color: #4285f4;
  transition: 0.3s;
}
.white-skin .side-nav .collapsible .card .card-header a h5 {
  color: #424242;
}
.white-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.white-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: #4285f4;
  color: #4285f4;
  background-color: transparent;
}
.white-skin .side-nav .collapsible .card.active > mdb-accordion-item-head .card-header a h5 {
  color: #4285f4;
  background-color: transparent;
}
.white-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #424242;
}
.white-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #424242;
}
.white-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(0, 0, 0, 0.05);
}
.white-skin .side-nav .collapsible .card-body li a {
  color: #424242;
}
.white-skin .side-nav .collapsible .card-body li a:hover {
  color: #4285f4;
}
.white-skin .side-nav .collapsible .card-body li a.active {
  color: #4285f4;
}

.black-skin .pills-primary .nav-link.active {
  background-color: #2ad1a3 !important;
}
.black-skin .pills-secondary .nav-link.active {
  background-color: #0f478a !important;
}
.black-skin .pills-default .nav-link.active {
  background-color: #222222 !important;
}
.black-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.black-skin .navbar-nav .nav-item .dropdown-menu a:hover, .black-skin .navbar-nav .nav-item .dropdown-menu a:focus, .black-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #26bc92 !important;
}
.black-skin .dropdown-menu a {
  color: #000;
}
.black-skin .dropdown-menu a:hover, .black-skin .dropdown-menu a:focus, .black-skin .dropdown-menu a:active {
  background-color: #26bc92 !important;
}
.black-skin .range-cloud {
  background-color: #2ad1a3 !important;
}
.black-skin .range-cloud:after {
  border-top-color: #2ad1a3 !important;
}
.black-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #2ad1a3;
}
.black-skin .range-field input[type=range]::-moz-range-thumb {
  background: #2ad1a3;
}
.black-skin .range-field input[type=range]::-ms-thumb {
  background: #2ad1a3;
}
.black-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.black-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.black-skin .side-nav .sidenav-bg {
  position: fixed;
}
.black-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.black-skin .side-nav .social a:hover .fa {
  color: #2ad1a3;
  transition: 0.3s;
}
.black-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.black-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: #2ad1a3;
}
.black-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: #2ad1a3;
}
.black-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.black-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.black-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: #2ad1a3;
}
.black-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.black-skin .side-nav .collapsible .card-body li a:hover {
  color: #2ad1a3;
}
.black-skin .side-nav .collapsible .card-body li a.active {
  color: #2ad1a3;
}

.cyan-skin .pills-primary .nav-link.active {
  background-color: #ffa742 !important;
}
.cyan-skin .pills-secondary .nav-link.active {
  background-color: #45b0c0 !important;
}
.cyan-skin .pills-default .nav-link.active {
  background-color: #114861 !important;
}
.cyan-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.cyan-skin .navbar-nav .nav-item .dropdown-menu a:hover, .cyan-skin .navbar-nav .nav-item .dropdown-menu a:focus, .cyan-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #eea82f !important;
}
.cyan-skin .dropdown-menu a {
  color: #000;
}
.cyan-skin .dropdown-menu a:hover, .cyan-skin .dropdown-menu a:focus, .cyan-skin .dropdown-menu a:active {
  background-color: #eea82f !important;
}
.cyan-skin .range-cloud {
  background-color: #4fada7 !important;
}
.cyan-skin .range-cloud:after {
  border-top-color: #4fada7 !important;
}
.cyan-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #4fada7;
}
.cyan-skin .range-field input[type=range]::-moz-range-thumb {
  background: #4fada7;
}
.cyan-skin .range-field input[type=range]::-ms-thumb {
  background: #4fada7;
}
.cyan-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.cyan-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.cyan-skin .side-nav .sidenav-bg {
  position: fixed;
}
.cyan-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.cyan-skin .side-nav .social a:hover .fa {
  color: #f0b247;
  transition: 0.3s;
}
.cyan-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.cyan-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(29, 65, 82, 0.8);
}
.cyan-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(29, 65, 82, 0.8);
}
.cyan-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.cyan-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.cyan-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(29, 65, 82, 0.8);
}
.cyan-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.cyan-skin .side-nav .collapsible .card-body li a:hover {
  color: #ffa742;
}
.cyan-skin .side-nav .collapsible .card-body li a.active {
  color: #ffa742;
}

.mdb-skin .pills-primary .nav-link.active {
  background-color: #33b5e5 !important;
}
.mdb-skin .pills-secondary .nav-link.active {
  background-color: #0e3d67 !important;
}
.mdb-skin .pills-default .nav-link.active {
  background-color: #1a6398 !important;
}
.mdb-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.mdb-skin .navbar-nav .nav-item .dropdown-menu a:hover, .mdb-skin .navbar-nav .nav-item .dropdown-menu a:focus, .mdb-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #1dade2 !important;
}
.mdb-skin .dropdown-menu a {
  color: #000;
}
.mdb-skin .dropdown-menu a:hover, .mdb-skin .dropdown-menu a:focus, .mdb-skin .dropdown-menu a:active {
  background-color: #1dade2 !important;
}
.mdb-skin .range-cloud {
  background-color: #33b5e5 !important;
}
.mdb-skin .range-cloud:after {
  border-top-color: #33b5e5 !important;
}
.mdb-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #33b5e5;
}
.mdb-skin .range-field input[type=range]::-moz-range-thumb {
  background: #33b5e5;
}
.mdb-skin .range-field input[type=range]::-ms-thumb {
  background: #33b5e5;
}
.mdb-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.mdb-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.mdb-skin .side-nav .sidenav-bg {
  position: fixed;
}
.mdb-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.mdb-skin .side-nav .social a:hover .fa {
  color: #33b5e5;
  transition: 0.3s;
}
.mdb-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.mdb-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(72, 198, 255, 0.8);
}
.mdb-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(72, 198, 255, 0.8);
}
.mdb-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.mdb-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.mdb-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(72, 198, 255, 0.8);
}
.mdb-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.mdb-skin .side-nav .collapsible .card-body li a:hover {
  color: #67d5ff;
}
.mdb-skin .side-nav .collapsible .card-body li a.active {
  color: #67d5ff;
}

.deep-purple-skin .pills-primary .nav-link.active {
  background-color: #ac85f0 !important;
}
.deep-purple-skin .pills-secondary .nav-link.active {
  background-color: #6e4ca3 !important;
}
.deep-purple-skin .pills-default .nav-link.active {
  background-color: #372e5f !important;
}
.deep-purple-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.deep-purple-skin .navbar-nav .nav-item .dropdown-menu a:hover, .deep-purple-skin .navbar-nav .nav-item .dropdown-menu a:focus, .deep-purple-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #9d6eed !important;
}
.deep-purple-skin .dropdown-menu a {
  color: #000;
}
.deep-purple-skin .dropdown-menu a:hover, .deep-purple-skin .dropdown-menu a:focus, .deep-purple-skin .dropdown-menu a:active {
  background-color: #9d6eed !important;
}
.deep-purple-skin .range-cloud {
  background-color: #d0637c !important;
}
.deep-purple-skin .range-cloud:after {
  border-top-color: #d0637c !important;
}
.deep-purple-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #d0637c;
}
.deep-purple-skin .range-field input[type=range]::-moz-range-thumb {
  background: #d0637c;
}
.deep-purple-skin .range-field input[type=range]::-ms-thumb {
  background: #d0637c;
}
.deep-purple-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.deep-purple-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.deep-purple-skin .side-nav .sidenav-bg {
  position: fixed;
}
.deep-purple-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.deep-purple-skin .side-nav .social a:hover .fa {
  color: #ac85f0;
  transition: 0.3s;
}
.deep-purple-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(176, 139, 240, 0.5);
}
.deep-purple-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(176, 139, 240, 0.5);
}
.deep-purple-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(176, 139, 240, 0.5);
}
.deep-purple-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.deep-purple-skin .side-nav .collapsible .card-body li a:hover {
  color: #c9abfc;
}
.deep-purple-skin .side-nav .collapsible .card-body li a.active {
  color: #c9abfc;
}

.navy-blue-skin .pills-primary .nav-link.active {
  background-color: #40c6c6 !important;
}
.navy-blue-skin .pills-secondary .nav-link.active {
  background-color: #7e51b4 !important;
}
.navy-blue-skin .pills-default .nav-link.active {
  background-color: #293756 !important;
}
.navy-blue-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.navy-blue-skin .navbar-nav .nav-item .dropdown-menu a:hover, .navy-blue-skin .navbar-nav .nav-item .dropdown-menu a:focus, .navy-blue-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #36b6b6 !important;
}
.navy-blue-skin .dropdown-menu a {
  color: #000;
}
.navy-blue-skin .dropdown-menu a:hover, .navy-blue-skin .dropdown-menu a:focus, .navy-blue-skin .dropdown-menu a:active {
  background-color: #36b6b6 !important;
}
.navy-blue-skin .range-cloud {
  background-color: #309e9e !important;
}
.navy-blue-skin .range-cloud:after {
  border-top-color: #309e9e !important;
}
.navy-blue-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #309e9e;
}
.navy-blue-skin .range-field input[type=range]::-moz-range-thumb {
  background: #309e9e;
}
.navy-blue-skin .range-field input[type=range]::-ms-thumb {
  background: #309e9e;
}
.navy-blue-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.navy-blue-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.navy-blue-skin .side-nav .sidenav-bg {
  position: fixed;
}
.navy-blue-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.navy-blue-skin .side-nav .social a:hover .fa {
  color: #40c6c6;
  transition: 0.3s;
}
.navy-blue-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(37, 207, 207, 0.8);
}
.navy-blue-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(37, 207, 207, 0.8);
}
.navy-blue-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(37, 207, 207, 0.8);
}
.navy-blue-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.navy-blue-skin .side-nav .collapsible .card-body li a:hover {
  color: #40c6c6;
}
.navy-blue-skin .side-nav .collapsible .card-body li a.active {
  color: #40c6c6;
}

.pink-skin .pills-primary .nav-link.active {
  background-color: #601f39 !important;
}
.pink-skin .pills-secondary .nav-link.active {
  background-color: #e7649d !important;
}
.pink-skin .pills-default .nav-link.active {
  background-color: #535466 !important;
}
.pink-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.pink-skin .navbar-nav .nav-item .dropdown-menu a:hover, .pink-skin .navbar-nav .nav-item .dropdown-menu a:focus, .pink-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #9192a2 !important;
}
.pink-skin .dropdown-menu a {
  color: #000;
}
.pink-skin .dropdown-menu a:hover, .pink-skin .dropdown-menu a:focus, .pink-skin .dropdown-menu a:active {
  background-color: #9192a2 !important;
}
.pink-skin .range-cloud {
  background-color: #bd5d70 !important;
}
.pink-skin .range-cloud:after {
  border-top-color: #bd5d70 !important;
}
.pink-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #bd5d70;
}
.pink-skin .range-field input[type=range]::-moz-range-thumb {
  background: #bd5d70;
}
.pink-skin .range-field input[type=range]::-ms-thumb {
  background: #bd5d70;
}
.pink-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.pink-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.pink-skin .side-nav .sidenav-bg {
  position: fixed;
}
.pink-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.pink-skin .side-nav .social a:hover .fa {
  color: #9fa0ae;
  transition: 0.3s;
}
.pink-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.pink-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.pink-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(0, 0, 0, 0.4);
}
.pink-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.pink-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.pink-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(0, 0, 0, 0.4);
}
.pink-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.pink-skin .side-nav .collapsible .card-body li a:hover {
  color: #ffb0e6;
}
.pink-skin .side-nav .collapsible .card-body li a.active {
  color: #ffb0e6;
}

.indigo-skin .pills-primary .nav-link.active {
  background-color: #fd668c !important;
}
.indigo-skin .pills-secondary .nav-link.active {
  background-color: #3c61ae !important;
}
.indigo-skin .pills-default .nav-link.active {
  background-color: #9095aa !important;
}
.indigo-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.indigo-skin .navbar-nav .nav-item .dropdown-menu a:hover, .indigo-skin .navbar-nav .nav-item .dropdown-menu a:focus, .indigo-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #fd4d79 !important;
}
.indigo-skin .dropdown-menu a {
  color: #000;
}
.indigo-skin .dropdown-menu a:hover, .indigo-skin .dropdown-menu a:focus, .indigo-skin .dropdown-menu a:active {
  background-color: #fd4d79 !important;
}
.indigo-skin .range-cloud {
  background-color: #7f82c5 !important;
}
.indigo-skin .range-cloud:after {
  border-top-color: #7f82c5 !important;
}
.indigo-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #7f82c5;
}
.indigo-skin .range-field input[type=range]::-moz-range-thumb {
  background: #7f82c5;
}
.indigo-skin .range-field input[type=range]::-ms-thumb {
  background: #7f82c5;
}
.indigo-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.indigo-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.indigo-skin .side-nav .sidenav-bg {
  position: fixed;
}
.indigo-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.indigo-skin .side-nav .social a:hover .fa {
  color: #fd668c;
  transition: 0.3s;
}
.indigo-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.indigo-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(250, 80, 121, 0.8);
}
.indigo-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(250, 80, 121, 0.8);
}
.indigo-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.indigo-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.indigo-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(250, 80, 121, 0.8);
}
.indigo-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.indigo-skin .side-nav .collapsible .card-body li a:hover {
  color: #ff89ac;
}
.indigo-skin .side-nav .collapsible .card-body li a.active {
  color: #ff89ac;
}

.light-blue-skin .pills-primary .nav-link.active {
  background-color: #ff4a67 !important;
}
.light-blue-skin .pills-secondary .nav-link.active {
  background-color: #64c3f0 !important;
}
.light-blue-skin .pills-default .nav-link.active {
  background-color: #2d486a !important;
}
.light-blue-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.light-blue-skin .navbar-nav .nav-item .dropdown-menu a:hover, .light-blue-skin .navbar-nav .nav-item .dropdown-menu a:focus, .light-blue-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #a5b6be !important;
}
.light-blue-skin .dropdown-menu a {
  color: #000;
}
.light-blue-skin .dropdown-menu a:hover, .light-blue-skin .dropdown-menu a:focus, .light-blue-skin .dropdown-menu a:active {
  background-color: #a5b6be !important;
}
.light-blue-skin .range-cloud {
  background-color: #3d799c !important;
}
.light-blue-skin .range-cloud:after {
  border-top-color: #3d799c !important;
}
.light-blue-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #3d799c;
}
.light-blue-skin .range-field input[type=range]::-moz-range-thumb {
  background: #3d799c;
}
.light-blue-skin .range-field input[type=range]::-ms-thumb {
  background: #3d799c;
}
.light-blue-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.light-blue-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.light-blue-skin .side-nav .sidenav-bg {
  position: fixed;
}
.light-blue-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.light-blue-skin .side-nav .social a:hover .fa {
  color: #b4c2c9;
  transition: 0.3s;
}
.light-blue-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(29, 54, 86, 0.6);
}
.light-blue-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(29, 54, 86, 0.6);
}
.light-blue-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(29, 54, 86, 0.6);
}
.light-blue-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.light-blue-skin .side-nav .collapsible .card-body li a:hover {
  color: #aadeff;
}
.light-blue-skin .side-nav .collapsible .card-body li a.active {
  color: #aadeff;
}

.grey-skin .pills-primary .nav-link.active {
  background-color: #46aded !important;
}
.grey-skin .pills-secondary .nav-link.active {
  background-color: #7d8488 !important;
}
.grey-skin .pills-default .nav-link.active {
  background-color: #353637 !important;
}
.grey-skin .navbar-nav .nav-item .dropdown-menu a {
  color: #000;
}
.grey-skin .navbar-nav .nav-item .dropdown-menu a:hover, .grey-skin .navbar-nav .nav-item .dropdown-menu a:focus, .grey-skin .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #5eb7ee !important;
}
.grey-skin .dropdown-menu a {
  color: #000;
}
.grey-skin .dropdown-menu a:hover, .grey-skin .dropdown-menu a:focus, .grey-skin .dropdown-menu a:active {
  background-color: #5eb7ee !important;
}
.grey-skin .range-cloud {
  background-color: #2b2b2b !important;
}
.grey-skin .range-cloud:after {
  border-top-color: #2b2b2b !important;
}
.grey-skin .range-field input[type=range]::-webkit-slider-thumb {
  background: #2b2b2b;
}
.grey-skin .range-field input[type=range]::-moz-range-thumb {
  background: #2b2b2b;
}
.grey-skin .range-field input[type=range]::-ms-thumb {
  background: #2b2b2b;
}
.grey-skin .side-nav {
  /* .search-form .md-form input:focus {
    color: map-get($data, skin-text);
    border-bottom: $skins-white-search-border-bottom solid $skins-border-color;
  } */
}
.grey-skin .side-nav form.search-form > div {
  overflow-x: hidden;
}
.grey-skin .side-nav .sidenav-bg {
  position: fixed;
}
.grey-skin .side-nav .social a .fa {
  transition: 0.3s;
}
.grey-skin .side-nav .social a:hover .fa {
  color: #75c1f0;
  transition: 0.3s;
}
.grey-skin .side-nav .collapsible .card .card-header a h5 {
  color: #fff;
}
.grey-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.grey-skin .side-nav .collapsible .card.active > mdb-accordion-item-head {
  background-color: rgba(0, 0, 0, 0.4);
}
.grey-skin .side-nav .collapsible .card.active .card-header a h5 {
  color: #fff;
}
.grey-skin .side-nav .collapsible .card.active .card-header a h5 .fa {
  color: #fff;
}
.grey-skin .side-nav .collapsible .card mdb-accordion-item-head.active a {
  background-color: rgba(0, 0, 0, 0.4);
}
.grey-skin .side-nav .collapsible .card-body li a {
  color: #fff;
}
.grey-skin .side-nav .collapsible .card-body li a:hover {
  color: #afdffc;
}
.grey-skin .side-nav .collapsible .card-body li a.active {
  color: #afdffc;
}

* {
  border-radius: 0px;
}

.bg-sidenav-custom {
  background-color: var(--dynamic-sidenav);
}

.user-avatar {
  width: 40px;
  height: 40px;
  font-weight: bold;
  background: #f2f3f8;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: #1d3d71;
}

@media (max-width: 575.98px) {
  .user-avatar {
    width: 36px;
    height: 36px;
  }
}
@media (max-width: 991.98px) {
  .tooltip.bs-tooltip-top {
    display: none;
  }
}
ng-select.ng-select-wrap.ng-select-small .ng-select-container {
  min-height: calc(1.5em + 0.5rem + 2px) !important;
  max-height: 150px !important;
}
ng-select.ng-select-wrap.ng-select-small .ng-select-container .ng-value-container {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #9e9e9e;
  border: 1px solid #e6e6e6;
}

:root {
  --dynamic-sidenav: #2b373d;
  --primary: #4285f4;
  --danger: #ff3547;
  --warning: #ffbb33;
  --success: #00c851;
  --info: #33b5e5;
  --default: #2bbbad;
  --secondary: #a6c;
  --elegant: #2e2e2e;
  --unique: #880e4f;
  --dark-green: #388e3c;
  --mdb-color: #59698d;
  --red: #d32f2f;
  --pink: #ec407a;
  --purple: #8e24aa;
  --deep-purple: #512da8;
  --indigo: #3f51b5;
  --blue: #1976d2;
  --light-blue: #82b1ff;
  --cyan: #00bcd4;
  --teal: #00796b;
  --green: #388e3c;
  --light-green: #8bc34a;
  --lime: #afb42b;
  --yellow: #fbc02d;
  --amber: #ffa000;
  --orange: #f57c00;
  --deep-orange: #ff7043;
  --brown: #795548;
  --grey: #616161;
  --blue-grey: #78909c;
  --dark: #212121;
  --light: #e0e0e0;
  --white: #fff;
  --black: #000;
  --primary-color: var(--dynamic-colour);
  --primary: #4285f4;
  --danger: #cc0000;
  --default: #2bbbad;
  --secondary: #a6c;
  --success: #00c851;
  --info: #33b5e5;
  --warning: #ffbb33;
  --dark: #2e2e2e;
  --ins: #2e5e86;
}

.xyzbox {
  background-color: var(--dynamic-colour);
}

.default-theme .btn-dynamic-2c {
  background-color: var(--dynamic-colour) !important;
  color: #fff !important;
}
.default-theme .bg-dynamic-2c {
  background-color: var(--dynamic-colour) !important;
}
.default-theme .text-dynamic-2c {
  color: var(--dynamic-colour) !important;
}
.default-theme .btn-dynamic-secondary-2c {
  background-color: var(--dynamic-secondary-colour) !important;
  color: #fff !important;
}
.default-theme .bg-dynamic-secondary-2c {
  background-color: var(--dynamic-secondary-colour) !important;
}
.default-theme .text-dynamic-secondary-2c {
  color: var(--dynamic-secondary-colour) !important;
}
.default-theme .modal.fade .modal-full-height {
  margin: 0 !important;
}
.default-theme dp-day-calendar {
  display: inline-block;
  z-index: 9999999999 !important;
  position: relative;
}
.default-theme .ng-dropdown-panel {
  z-index: 999999 !important;
}
.default-theme button:focus {
  outline: none;
  outline: none;
}
.default-theme .btn-primary {
  background-color: #4285f4 !important;
  color: #fff;
}
.default-theme .btn-primary:hover {
  background-color: #5a95f5;
  color: #fff;
}
.default-theme .btn-primary:focus, .default-theme .btn-primary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-primary:focus, .default-theme .btn-primary:active, .default-theme .btn-primary.active {
  background-color: #0b51c5;
}
.default-theme .btn-primary.dropdown-toggle {
  background-color: #4285f4 !important;
}
.default-theme .btn-primary.dropdown-toggle:hover, .default-theme .btn-primary.dropdown-toggle:focus {
  background-color: #5a95f5 !important;
}
.default-theme .btn-primary:not([disabled]):not(.disabled):active, .default-theme .btn-primary:not([disabled]):not(.disabled).active, .show > .default-theme .btn-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #0b51c5 !important;
}
.default-theme .btn-primary:not([disabled]):not(.disabled):active:focus, .default-theme .btn-primary:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .primary-ic {
  color: #4285f4 !important;
}
.default-theme .primary-ic:hover, .default-theme .primary-ic:focus {
  color: #4285f4;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-primary {
  color: #fff;
}
.default-theme .text-primary {
  color: #4285f4 !important;
}
.default-theme a.text-primary:hover, .default-theme a.text-primary:focus {
  color: #1266f1 !important;
}
.default-theme .bg-primary {
  background-color: #4285f4 !important;
}
.default-theme a.bg-primary:hover, .default-theme a.bg-primary:focus,
.default-theme button.bg-primary:hover,
.default-theme button.bg-primary:focus {
  background-color: #1266f1 !important;
}
.default-theme .badge-bg-primary {
  background-color: #4285f4 !important;
}
.default-theme a.badge-bg-primary:hover, .default-theme a.badge-bg-primary:focus,
.default-theme button.badge-bg-primary:hover,
.default-theme button.badge-bg-primary:focus {
  background-color: #1266f1 !important;
}
.default-theme .btn-outline-primary {
  border: 2px solid #4285f4 !important;
  background-color: transparent !important;
  color: #4285f4 !important;
}
.default-theme .btn-outline-primary:hover, .default-theme .btn-outline-primary:focus, .default-theme .btn-outline-primary:active, .default-theme .btn-outline-primary:active:focus, .default-theme .btn-outline-primary.active {
  border-color: #4285f4 !important;
  background-color: transparent !important;
  color: #4285f4 !important;
}
.default-theme .btn-outline-primary:not([disabled]):not(.disabled):active, .default-theme .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-primary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #4285f4 !important;
}
.default-theme .btn-outline-primary:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-primary:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-danger {
  background-color: #ff3547 !important;
  color: #fff;
}
.default-theme .btn-danger:hover {
  background-color: #ff4f5e;
  color: #fff;
}
.default-theme .btn-danger:focus, .default-theme .btn-danger.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-danger:focus, .default-theme .btn-danger:active, .default-theme .btn-danger.active {
  background-color: #ce0012;
}
.default-theme .btn-danger.dropdown-toggle {
  background-color: #ff3547 !important;
}
.default-theme .btn-danger.dropdown-toggle:hover, .default-theme .btn-danger.dropdown-toggle:focus {
  background-color: #ff4f5e !important;
}
.default-theme .btn-danger:not([disabled]):not(.disabled):active, .default-theme .btn-danger:not([disabled]):not(.disabled).active, .show > .default-theme .btn-danger.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #ce0012 !important;
}
.default-theme .btn-danger:not([disabled]):not(.disabled):active:focus, .default-theme .btn-danger:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .danger-ic {
  color: #ff3547 !important;
}
.default-theme .danger-ic:hover, .default-theme .danger-ic:focus {
  color: #ff3547;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-danger {
  color: #fff;
}
.default-theme .text-danger {
  color: #ff3547 !important;
}
.default-theme a.text-danger:hover, .default-theme a.text-danger:focus {
  color: #ff0219 !important;
}
.default-theme .bg-danger {
  background-color: #ff3547 !important;
}
.default-theme a.bg-danger:hover, .default-theme a.bg-danger:focus,
.default-theme button.bg-danger:hover,
.default-theme button.bg-danger:focus {
  background-color: #ff0219 !important;
}
.default-theme .badge-bg-danger {
  background-color: #ff3547 !important;
}
.default-theme a.badge-bg-danger:hover, .default-theme a.badge-bg-danger:focus,
.default-theme button.badge-bg-danger:hover,
.default-theme button.badge-bg-danger:focus {
  background-color: #ff0219 !important;
}
.default-theme .btn-outline-danger {
  border: 2px solid #ff3547 !important;
  background-color: transparent !important;
  color: #ff3547 !important;
}
.default-theme .btn-outline-danger:hover, .default-theme .btn-outline-danger:focus, .default-theme .btn-outline-danger:active, .default-theme .btn-outline-danger:active:focus, .default-theme .btn-outline-danger.active {
  border-color: #ff3547 !important;
  background-color: transparent !important;
  color: #ff3547 !important;
}
.default-theme .btn-outline-danger:not([disabled]):not(.disabled):active, .default-theme .btn-outline-danger:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-danger.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ff3547 !important;
}
.default-theme .btn-outline-danger:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-danger:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-warning {
  background-color: #ffbb33 !important;
  color: #fff;
}
.default-theme .btn-warning:hover {
  background-color: #ffc44d;
  color: #fff;
}
.default-theme .btn-warning:focus, .default-theme .btn-warning.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-warning:focus, .default-theme .btn-warning:active, .default-theme .btn-warning.active {
  background-color: #cc8800;
}
.default-theme .btn-warning.dropdown-toggle {
  background-color: #ffbb33 !important;
}
.default-theme .btn-warning.dropdown-toggle:hover, .default-theme .btn-warning.dropdown-toggle:focus {
  background-color: #ffc44d !important;
}
.default-theme .btn-warning:not([disabled]):not(.disabled):active, .default-theme .btn-warning:not([disabled]):not(.disabled).active, .show > .default-theme .btn-warning.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #cc8800 !important;
}
.default-theme .btn-warning:not([disabled]):not(.disabled):active:focus, .default-theme .btn-warning:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .warning-ic {
  color: #ffbb33 !important;
}
.default-theme .warning-ic:hover, .default-theme .warning-ic:focus {
  color: #ffbb33;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-warning {
  color: #fff;
}
.default-theme .text-warning {
  color: #ffbb33 !important;
}
.default-theme a.text-warning:hover, .default-theme a.text-warning:focus {
  color: #ffaa00 !important;
}
.default-theme .bg-warning {
  background-color: #ffbb33 !important;
}
.default-theme a.bg-warning:hover, .default-theme a.bg-warning:focus,
.default-theme button.bg-warning:hover,
.default-theme button.bg-warning:focus {
  background-color: #ffaa00 !important;
}
.default-theme .badge-bg-warning {
  background-color: #ffbb33 !important;
}
.default-theme a.badge-bg-warning:hover, .default-theme a.badge-bg-warning:focus,
.default-theme button.badge-bg-warning:hover,
.default-theme button.badge-bg-warning:focus {
  background-color: #ffaa00 !important;
}
.default-theme .btn-outline-warning {
  border: 2px solid #ffbb33 !important;
  background-color: transparent !important;
  color: #ffbb33 !important;
}
.default-theme .btn-outline-warning:hover, .default-theme .btn-outline-warning:focus, .default-theme .btn-outline-warning:active, .default-theme .btn-outline-warning:active:focus, .default-theme .btn-outline-warning.active {
  border-color: #ffbb33 !important;
  background-color: transparent !important;
  color: #ffbb33 !important;
}
.default-theme .btn-outline-warning:not([disabled]):not(.disabled):active, .default-theme .btn-outline-warning:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-warning.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ffbb33 !important;
}
.default-theme .btn-outline-warning:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-warning:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-success {
  background-color: #00c851 !important;
  color: #fff;
}
.default-theme .btn-success:hover {
  background-color: #00e25b;
  color: #fff;
}
.default-theme .btn-success:focus, .default-theme .btn-success.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-success:focus, .default-theme .btn-success:active, .default-theme .btn-success.active {
  background-color: #006228;
}
.default-theme .btn-success.dropdown-toggle {
  background-color: #00c851 !important;
}
.default-theme .btn-success.dropdown-toggle:hover, .default-theme .btn-success.dropdown-toggle:focus {
  background-color: #00e25b !important;
}
.default-theme .btn-success:not([disabled]):not(.disabled):active, .default-theme .btn-success:not([disabled]):not(.disabled).active, .show > .default-theme .btn-success.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #006228 !important;
}
.default-theme .btn-success:not([disabled]):not(.disabled):active:focus, .default-theme .btn-success:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-success.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .success-ic {
  color: #00c851 !important;
}
.default-theme .success-ic:hover, .default-theme .success-ic:focus {
  color: #00c851;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-success {
  color: #fff;
}
.default-theme .text-success {
  color: #00c851 !important;
}
.default-theme a.text-success:hover, .default-theme a.text-success:focus {
  color: #00953c !important;
}
.default-theme .bg-success {
  background-color: #00c851 !important;
}
.default-theme a.bg-success:hover, .default-theme a.bg-success:focus,
.default-theme button.bg-success:hover,
.default-theme button.bg-success:focus {
  background-color: #00953c !important;
}
.default-theme .badge-bg-success {
  background-color: #00c851 !important;
}
.default-theme a.badge-bg-success:hover, .default-theme a.badge-bg-success:focus,
.default-theme button.badge-bg-success:hover,
.default-theme button.badge-bg-success:focus {
  background-color: #00953c !important;
}
.default-theme .btn-outline-success {
  border: 2px solid #00c851 !important;
  background-color: transparent !important;
  color: #00c851 !important;
}
.default-theme .btn-outline-success:hover, .default-theme .btn-outline-success:focus, .default-theme .btn-outline-success:active, .default-theme .btn-outline-success:active:focus, .default-theme .btn-outline-success.active {
  border-color: #00c851 !important;
  background-color: transparent !important;
  color: #00c851 !important;
}
.default-theme .btn-outline-success:not([disabled]):not(.disabled):active, .default-theme .btn-outline-success:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-success.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #00c851 !important;
}
.default-theme .btn-outline-success:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-success:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-info {
  background-color: #33b5e5 !important;
  color: #fff;
}
.default-theme .btn-info:hover {
  background-color: #4abde8;
  color: #fff;
}
.default-theme .btn-info:focus, .default-theme .btn-info.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-info:focus, .default-theme .btn-info:active, .default-theme .btn-info.active {
  background-color: #14799e;
}
.default-theme .btn-info.dropdown-toggle {
  background-color: #33b5e5 !important;
}
.default-theme .btn-info.dropdown-toggle:hover, .default-theme .btn-info.dropdown-toggle:focus {
  background-color: #4abde8 !important;
}
.default-theme .btn-info:not([disabled]):not(.disabled):active, .default-theme .btn-info:not([disabled]):not(.disabled).active, .show > .default-theme .btn-info.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #14799e !important;
}
.default-theme .btn-info:not([disabled]):not(.disabled):active:focus, .default-theme .btn-info:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-info.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .info-ic {
  color: #33b5e5 !important;
}
.default-theme .info-ic:hover, .default-theme .info-ic:focus {
  color: #33b5e5;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-info {
  color: #fff;
}
.default-theme .text-info {
  color: #33b5e5 !important;
}
.default-theme a.text-info:hover, .default-theme a.text-info:focus {
  color: #1a9bcb !important;
}
.default-theme .bg-info {
  background-color: #33b5e5 !important;
}
.default-theme a.bg-info:hover, .default-theme a.bg-info:focus,
.default-theme button.bg-info:hover,
.default-theme button.bg-info:focus {
  background-color: #1a9bcb !important;
}
.default-theme .badge-bg-info {
  background-color: #33b5e5 !important;
}
.default-theme a.badge-bg-info:hover, .default-theme a.badge-bg-info:focus,
.default-theme button.badge-bg-info:hover,
.default-theme button.badge-bg-info:focus {
  background-color: #1a9bcb !important;
}
.default-theme .btn-outline-info {
  border: 2px solid #33b5e5 !important;
  background-color: transparent !important;
  color: #33b5e5 !important;
}
.default-theme .btn-outline-info:hover, .default-theme .btn-outline-info:focus, .default-theme .btn-outline-info:active, .default-theme .btn-outline-info:active:focus, .default-theme .btn-outline-info.active {
  border-color: #33b5e5 !important;
  background-color: transparent !important;
  color: #33b5e5 !important;
}
.default-theme .btn-outline-info:not([disabled]):not(.disabled):active, .default-theme .btn-outline-info:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-info.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #33b5e5 !important;
}
.default-theme .btn-outline-info:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-info:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-default {
  background-color: #2bbbad !important;
  color: #fff;
}
.default-theme .btn-default:hover {
  background-color: #30cfc0;
  color: #fff;
}
.default-theme .btn-default:focus, .default-theme .btn-default.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-default:focus, .default-theme .btn-default:active, .default-theme .btn-default.active {
  background-color: #186860;
}
.default-theme .btn-default.dropdown-toggle {
  background-color: #2bbbad !important;
}
.default-theme .btn-default.dropdown-toggle:hover, .default-theme .btn-default.dropdown-toggle:focus {
  background-color: #30cfc0 !important;
}
.default-theme .btn-default:not([disabled]):not(.disabled):active, .default-theme .btn-default:not([disabled]):not(.disabled).active, .show > .default-theme .btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #186860 !important;
}
.default-theme .btn-default:not([disabled]):not(.disabled):active:focus, .default-theme .btn-default:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .default-ic {
  color: #2bbbad !important;
}
.default-theme .default-ic:hover, .default-theme .default-ic:focus {
  color: #2bbbad;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-default {
  color: #fff;
}
.default-theme .text-default {
  color: #2bbbad !important;
}
.default-theme a.text-default:hover, .default-theme a.text-default:focus {
  color: #219287 !important;
}
.default-theme .bg-default {
  background-color: #2bbbad !important;
}
.default-theme a.bg-default:hover, .default-theme a.bg-default:focus,
.default-theme button.bg-default:hover,
.default-theme button.bg-default:focus {
  background-color: #219287 !important;
}
.default-theme .badge-bg-default {
  background-color: #2bbbad !important;
}
.default-theme a.badge-bg-default:hover, .default-theme a.badge-bg-default:focus,
.default-theme button.badge-bg-default:hover,
.default-theme button.badge-bg-default:focus {
  background-color: #219287 !important;
}
.default-theme .btn-outline-default {
  border: 2px solid #2bbbad !important;
  background-color: transparent !important;
  color: #2bbbad !important;
}
.default-theme .btn-outline-default:hover, .default-theme .btn-outline-default:focus, .default-theme .btn-outline-default:active, .default-theme .btn-outline-default:active:focus, .default-theme .btn-outline-default.active {
  border-color: #2bbbad !important;
  background-color: transparent !important;
  color: #2bbbad !important;
}
.default-theme .btn-outline-default:not([disabled]):not(.disabled):active, .default-theme .btn-outline-default:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #2bbbad !important;
}
.default-theme .btn-outline-default:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-default:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-secondary {
  background-color: #a6c !important;
  color: #fff;
}
.default-theme .btn-secondary:hover {
  background-color: #b579d2;
  color: #fff;
}
.default-theme .btn-secondary:focus, .default-theme .btn-secondary.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-secondary:focus, .default-theme .btn-secondary:active, .default-theme .btn-secondary.active {
  background-color: #773399;
}
.default-theme .btn-secondary.dropdown-toggle {
  background-color: #a6c !important;
}
.default-theme .btn-secondary.dropdown-toggle:hover, .default-theme .btn-secondary.dropdown-toggle:focus {
  background-color: #b579d2 !important;
}
.default-theme .btn-secondary:not([disabled]):not(.disabled):active, .default-theme .btn-secondary:not([disabled]):not(.disabled).active, .show > .default-theme .btn-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #773399 !important;
}
.default-theme .btn-secondary:not([disabled]):not(.disabled):active:focus, .default-theme .btn-secondary:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .secondary-ic {
  color: #a6c !important;
}
.default-theme .secondary-ic:hover, .default-theme .secondary-ic:focus {
  color: #a6c;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-secondary {
  color: #fff;
}
.default-theme .text-secondary {
  color: #a6c !important;
}
.default-theme a.text-secondary:hover, .default-theme a.text-secondary:focus {
  color: #9540bf !important;
}
.default-theme .bg-secondary {
  background-color: #a6c !important;
}
.default-theme a.bg-secondary:hover, .default-theme a.bg-secondary:focus,
.default-theme button.bg-secondary:hover,
.default-theme button.bg-secondary:focus {
  background-color: #9540bf !important;
}
.default-theme .badge-bg-secondary {
  background-color: #a6c !important;
}
.default-theme a.badge-bg-secondary:hover, .default-theme a.badge-bg-secondary:focus,
.default-theme button.badge-bg-secondary:hover,
.default-theme button.badge-bg-secondary:focus {
  background-color: #9540bf !important;
}
.default-theme .btn-outline-secondary {
  border: 2px solid #a6c !important;
  background-color: transparent !important;
  color: #a6c !important;
}
.default-theme .btn-outline-secondary:hover, .default-theme .btn-outline-secondary:focus, .default-theme .btn-outline-secondary:active, .default-theme .btn-outline-secondary:active:focus, .default-theme .btn-outline-secondary.active {
  border-color: #a6c !important;
  background-color: transparent !important;
  color: #a6c !important;
}
.default-theme .btn-outline-secondary:not([disabled]):not(.disabled):active, .default-theme .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-secondary.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #a6c !important;
}
.default-theme .btn-outline-secondary:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-secondary:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-elegant {
  background-color: #2e2e2e !important;
  color: #fff;
}
.default-theme .btn-elegant:hover {
  background-color: #3b3b3b;
  color: #fff;
}
.default-theme .btn-elegant:focus, .default-theme .btn-elegant.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-elegant:focus, .default-theme .btn-elegant:active, .default-theme .btn-elegant.active {
  background-color: black;
}
.default-theme .btn-elegant.dropdown-toggle {
  background-color: #2e2e2e !important;
}
.default-theme .btn-elegant.dropdown-toggle:hover, .default-theme .btn-elegant.dropdown-toggle:focus {
  background-color: #3b3b3b !important;
}
.default-theme .btn-elegant:not([disabled]):not(.disabled):active, .default-theme .btn-elegant:not([disabled]):not(.disabled).active, .show > .default-theme .btn-elegant.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: black !important;
}
.default-theme .btn-elegant:not([disabled]):not(.disabled):active:focus, .default-theme .btn-elegant:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-elegant.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .elegant-ic {
  color: #2e2e2e !important;
}
.default-theme .elegant-ic:hover, .default-theme .elegant-ic:focus {
  color: #2e2e2e;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-elegant {
  color: #fff;
}
.default-theme .text-elegant {
  color: #2e2e2e !important;
}
.default-theme a.text-elegant:hover, .default-theme a.text-elegant:focus {
  color: #151515 !important;
}
.default-theme .bg-elegant {
  background-color: #2e2e2e !important;
}
.default-theme a.bg-elegant:hover, .default-theme a.bg-elegant:focus,
.default-theme button.bg-elegant:hover,
.default-theme button.bg-elegant:focus {
  background-color: #151515 !important;
}
.default-theme .badge-bg-elegant {
  background-color: #2e2e2e !important;
}
.default-theme a.badge-bg-elegant:hover, .default-theme a.badge-bg-elegant:focus,
.default-theme button.badge-bg-elegant:hover,
.default-theme button.badge-bg-elegant:focus {
  background-color: #151515 !important;
}
.default-theme .btn-outline-elegant {
  border: 2px solid #2e2e2e !important;
  background-color: transparent !important;
  color: #2e2e2e !important;
}
.default-theme .btn-outline-elegant:hover, .default-theme .btn-outline-elegant:focus, .default-theme .btn-outline-elegant:active, .default-theme .btn-outline-elegant:active:focus, .default-theme .btn-outline-elegant.active {
  border-color: #2e2e2e !important;
  background-color: transparent !important;
  color: #2e2e2e !important;
}
.default-theme .btn-outline-elegant:not([disabled]):not(.disabled):active, .default-theme .btn-outline-elegant:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-elegant.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #2e2e2e !important;
}
.default-theme .btn-outline-elegant:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-elegant:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-elegant.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-unique {
  background-color: #880e4f !important;
  color: #fff;
}
.default-theme .btn-unique:hover {
  background-color: #9f105c;
  color: #fff;
}
.default-theme .btn-unique:focus, .default-theme .btn-unique.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-unique:focus, .default-theme .btn-unique:active, .default-theme .btn-unique.active {
  background-color: #2c0419;
}
.default-theme .btn-unique.dropdown-toggle {
  background-color: #880e4f !important;
}
.default-theme .btn-unique.dropdown-toggle:hover, .default-theme .btn-unique.dropdown-toggle:focus {
  background-color: #9f105c !important;
}
.default-theme .btn-unique:not([disabled]):not(.disabled):active, .default-theme .btn-unique:not([disabled]):not(.disabled).active, .show > .default-theme .btn-unique.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #2c0419 !important;
}
.default-theme .btn-unique:not([disabled]):not(.disabled):active:focus, .default-theme .btn-unique:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-unique.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .unique-ic {
  color: #880e4f !important;
}
.default-theme .unique-ic:hover, .default-theme .unique-ic:focus {
  color: #880e4f;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-unique {
  color: #fff;
}
.default-theme .text-unique {
  color: #880e4f !important;
}
.default-theme a.text-unique:hover, .default-theme a.text-unique:focus {
  color: #5a0934 !important;
}
.default-theme .bg-unique {
  background-color: #880e4f !important;
}
.default-theme a.bg-unique:hover, .default-theme a.bg-unique:focus,
.default-theme button.bg-unique:hover,
.default-theme button.bg-unique:focus {
  background-color: #5a0934 !important;
}
.default-theme .badge-bg-unique {
  background-color: #880e4f !important;
}
.default-theme a.badge-bg-unique:hover, .default-theme a.badge-bg-unique:focus,
.default-theme button.badge-bg-unique:hover,
.default-theme button.badge-bg-unique:focus {
  background-color: #5a0934 !important;
}
.default-theme .btn-outline-unique {
  border: 2px solid #880e4f !important;
  background-color: transparent !important;
  color: #880e4f !important;
}
.default-theme .btn-outline-unique:hover, .default-theme .btn-outline-unique:focus, .default-theme .btn-outline-unique:active, .default-theme .btn-outline-unique:active:focus, .default-theme .btn-outline-unique.active {
  border-color: #880e4f !important;
  background-color: transparent !important;
  color: #880e4f !important;
}
.default-theme .btn-outline-unique:not([disabled]):not(.disabled):active, .default-theme .btn-outline-unique:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-unique.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #880e4f !important;
}
.default-theme .btn-outline-unique:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-unique:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-unique.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-dark-green {
  background-color: #388e3c !important;
  color: #fff;
}
.default-theme .btn-dark-green:hover {
  background-color: #3fa044;
  color: #fff;
}
.default-theme .btn-dark-green:focus, .default-theme .btn-dark-green.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-dark-green:focus, .default-theme .btn-dark-green:active, .default-theme .btn-dark-green.active {
  background-color: #1b451d;
}
.default-theme .btn-dark-green.dropdown-toggle {
  background-color: #388e3c !important;
}
.default-theme .btn-dark-green.dropdown-toggle:hover, .default-theme .btn-dark-green.dropdown-toggle:focus {
  background-color: #3fa044 !important;
}
.default-theme .btn-dark-green:not([disabled]):not(.disabled):active, .default-theme .btn-dark-green:not([disabled]):not(.disabled).active, .show > .default-theme .btn-dark-green.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #1b451d !important;
}
.default-theme .btn-dark-green:not([disabled]):not(.disabled):active:focus, .default-theme .btn-dark-green:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-dark-green.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .dark-green-ic {
  color: #388e3c !important;
}
.default-theme .dark-green-ic:hover, .default-theme .dark-green-ic:focus {
  color: #388e3c;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-dark-green {
  color: #fff;
}
.default-theme .text-dark-green {
  color: #388e3c !important;
}
.default-theme a.text-dark-green:hover, .default-theme a.text-dark-green:focus {
  color: #2a692d !important;
}
.default-theme .bg-dark-green {
  background-color: #388e3c !important;
}
.default-theme a.bg-dark-green:hover, .default-theme a.bg-dark-green:focus,
.default-theme button.bg-dark-green:hover,
.default-theme button.bg-dark-green:focus {
  background-color: #2a692d !important;
}
.default-theme .badge-bg-dark-green {
  background-color: #388e3c !important;
}
.default-theme a.badge-bg-dark-green:hover, .default-theme a.badge-bg-dark-green:focus,
.default-theme button.badge-bg-dark-green:hover,
.default-theme button.badge-bg-dark-green:focus {
  background-color: #2a692d !important;
}
.default-theme .btn-outline-dark-green {
  border: 2px solid #388e3c !important;
  background-color: transparent !important;
  color: #388e3c !important;
}
.default-theme .btn-outline-dark-green:hover, .default-theme .btn-outline-dark-green:focus, .default-theme .btn-outline-dark-green:active, .default-theme .btn-outline-dark-green:active:focus, .default-theme .btn-outline-dark-green.active {
  border-color: #388e3c !important;
  background-color: transparent !important;
  color: #388e3c !important;
}
.default-theme .btn-outline-dark-green:not([disabled]):not(.disabled):active, .default-theme .btn-outline-dark-green:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-dark-green.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #388e3c !important;
}
.default-theme .btn-outline-dark-green:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-dark-green:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-dark-green.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-mdb-color {
  background-color: #59698d !important;
  color: #fff;
}
.default-theme .btn-mdb-color:hover {
  background-color: #63759d;
  color: #fff;
}
.default-theme .btn-mdb-color:focus, .default-theme .btn-mdb-color.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-mdb-color:focus, .default-theme .btn-mdb-color:active, .default-theme .btn-mdb-color.active {
  background-color: #323a4e;
}
.default-theme .btn-mdb-color.dropdown-toggle {
  background-color: #59698d !important;
}
.default-theme .btn-mdb-color.dropdown-toggle:hover, .default-theme .btn-mdb-color.dropdown-toggle:focus {
  background-color: #63759d !important;
}
.default-theme .btn-mdb-color:not([disabled]):not(.disabled):active, .default-theme .btn-mdb-color:not([disabled]):not(.disabled).active, .show > .default-theme .btn-mdb-color.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #323a4e !important;
}
.default-theme .btn-mdb-color:not([disabled]):not(.disabled):active:focus, .default-theme .btn-mdb-color:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-mdb-color.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .mdb-color-ic {
  color: #59698d !important;
}
.default-theme .mdb-color-ic:hover, .default-theme .mdb-color-ic:focus {
  color: #59698d;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-mdb-color {
  color: #fff;
}
.default-theme .text-mdb-color {
  color: #59698d !important;
}
.default-theme a.text-mdb-color:hover, .default-theme a.text-mdb-color:focus {
  color: #45526e !important;
}
.default-theme .bg-mdb-color {
  background-color: #59698d !important;
}
.default-theme a.bg-mdb-color:hover, .default-theme a.bg-mdb-color:focus,
.default-theme button.bg-mdb-color:hover,
.default-theme button.bg-mdb-color:focus {
  background-color: #45526e !important;
}
.default-theme .badge-bg-mdb-color {
  background-color: #59698d !important;
}
.default-theme a.badge-bg-mdb-color:hover, .default-theme a.badge-bg-mdb-color:focus,
.default-theme button.badge-bg-mdb-color:hover,
.default-theme button.badge-bg-mdb-color:focus {
  background-color: #45526e !important;
}
.default-theme .btn-outline-mdb-color {
  border: 2px solid #59698d !important;
  background-color: transparent !important;
  color: #59698d !important;
}
.default-theme .btn-outline-mdb-color:hover, .default-theme .btn-outline-mdb-color:focus, .default-theme .btn-outline-mdb-color:active, .default-theme .btn-outline-mdb-color:active:focus, .default-theme .btn-outline-mdb-color.active {
  border-color: #59698d !important;
  background-color: transparent !important;
  color: #59698d !important;
}
.default-theme .btn-outline-mdb-color:not([disabled]):not(.disabled):active, .default-theme .btn-outline-mdb-color:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-mdb-color.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #59698d !important;
}
.default-theme .btn-outline-mdb-color:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-mdb-color:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-mdb-color.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-red {
  background-color: #d32f2f !important;
  color: #fff;
}
.default-theme .btn-red:hover {
  background-color: #d74444;
  color: #fff;
}
.default-theme .btn-red:focus, .default-theme .btn-red.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-red:focus, .default-theme .btn-red:active, .default-theme .btn-red.active {
  background-color: #811b1b;
}
.default-theme .btn-red.dropdown-toggle {
  background-color: #d32f2f !important;
}
.default-theme .btn-red.dropdown-toggle:hover, .default-theme .btn-red.dropdown-toggle:focus {
  background-color: #d74444 !important;
}
.default-theme .btn-red:not([disabled]):not(.disabled):active, .default-theme .btn-red:not([disabled]):not(.disabled).active, .show > .default-theme .btn-red.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #811b1b !important;
}
.default-theme .btn-red:not([disabled]):not(.disabled):active:focus, .default-theme .btn-red:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-red.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .red-ic {
  color: #d32f2f !important;
}
.default-theme .red-ic:hover, .default-theme .red-ic:focus {
  color: #d32f2f;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-red {
  color: #fff;
}
.default-theme .text-red {
  color: #d32f2f !important;
}
.default-theme a.text-red:hover, .default-theme a.text-red:focus {
  color: #ab2424 !important;
}
.default-theme .bg-red {
  background-color: #d32f2f !important;
}
.default-theme a.bg-red:hover, .default-theme a.bg-red:focus,
.default-theme button.bg-red:hover,
.default-theme button.bg-red:focus {
  background-color: #ab2424 !important;
}
.default-theme .badge-bg-red {
  background-color: #d32f2f !important;
}
.default-theme a.badge-bg-red:hover, .default-theme a.badge-bg-red:focus,
.default-theme button.badge-bg-red:hover,
.default-theme button.badge-bg-red:focus {
  background-color: #ab2424 !important;
}
.default-theme .btn-outline-red {
  border: 2px solid #d32f2f !important;
  background-color: transparent !important;
  color: #d32f2f !important;
}
.default-theme .btn-outline-red:hover, .default-theme .btn-outline-red:focus, .default-theme .btn-outline-red:active, .default-theme .btn-outline-red:active:focus, .default-theme .btn-outline-red.active {
  border-color: #d32f2f !important;
  background-color: transparent !important;
  color: #d32f2f !important;
}
.default-theme .btn-outline-red:not([disabled]):not(.disabled):active, .default-theme .btn-outline-red:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-red.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #d32f2f !important;
}
.default-theme .btn-outline-red:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-red:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-red.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-pink {
  background-color: #ec407a !important;
  color: #fff;
}
.default-theme .btn-pink:hover {
  background-color: #ee578a;
  color: #fff;
}
.default-theme .btn-pink:focus, .default-theme .btn-pink.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-pink:focus, .default-theme .btn-pink:active, .default-theme .btn-pink.active {
  background-color: #b41249;
}
.default-theme .btn-pink.dropdown-toggle {
  background-color: #ec407a !important;
}
.default-theme .btn-pink.dropdown-toggle:hover, .default-theme .btn-pink.dropdown-toggle:focus {
  background-color: #ee578a !important;
}
.default-theme .btn-pink:not([disabled]):not(.disabled):active, .default-theme .btn-pink:not([disabled]):not(.disabled).active, .show > .default-theme .btn-pink.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #b41249 !important;
}
.default-theme .btn-pink:not([disabled]):not(.disabled):active:focus, .default-theme .btn-pink:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .pink-ic {
  color: #ec407a !important;
}
.default-theme .pink-ic:hover, .default-theme .pink-ic:focus {
  color: #ec407a;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-pink {
  color: #fff;
}
.default-theme .text-pink {
  color: #ec407a !important;
}
.default-theme a.text-pink:hover, .default-theme a.text-pink:focus {
  color: #e2175b !important;
}
.default-theme .bg-pink {
  background-color: #ec407a !important;
}
.default-theme a.bg-pink:hover, .default-theme a.bg-pink:focus,
.default-theme button.bg-pink:hover,
.default-theme button.bg-pink:focus {
  background-color: #e2175b !important;
}
.default-theme .badge-bg-pink {
  background-color: #ec407a !important;
}
.default-theme a.badge-bg-pink:hover, .default-theme a.badge-bg-pink:focus,
.default-theme button.badge-bg-pink:hover,
.default-theme button.badge-bg-pink:focus {
  background-color: #e2175b !important;
}
.default-theme .btn-outline-pink {
  border: 2px solid #ec407a !important;
  background-color: transparent !important;
  color: #ec407a !important;
}
.default-theme .btn-outline-pink:hover, .default-theme .btn-outline-pink:focus, .default-theme .btn-outline-pink:active, .default-theme .btn-outline-pink:active:focus, .default-theme .btn-outline-pink.active {
  border-color: #ec407a !important;
  background-color: transparent !important;
  color: #ec407a !important;
}
.default-theme .btn-outline-pink:not([disabled]):not(.disabled):active, .default-theme .btn-outline-pink:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-pink.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ec407a !important;
}
.default-theme .btn-outline-pink:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-pink:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-pink.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-purple {
  background-color: #8e24aa !important;
  color: #fff;
}
.default-theme .btn-purple:hover {
  background-color: #a028bf;
  color: #fff;
}
.default-theme .btn-purple:focus, .default-theme .btn-purple.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-purple:focus, .default-theme .btn-purple:active, .default-theme .btn-purple.active {
  background-color: #481256;
}
.default-theme .btn-purple.dropdown-toggle {
  background-color: #8e24aa !important;
}
.default-theme .btn-purple.dropdown-toggle:hover, .default-theme .btn-purple.dropdown-toggle:focus {
  background-color: #a028bf !important;
}
.default-theme .btn-purple:not([disabled]):not(.disabled):active, .default-theme .btn-purple:not([disabled]):not(.disabled).active, .show > .default-theme .btn-purple.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #481256 !important;
}
.default-theme .btn-purple:not([disabled]):not(.disabled):active:focus, .default-theme .btn-purple:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .purple-ic {
  color: #8e24aa !important;
}
.default-theme .purple-ic:hover, .default-theme .purple-ic:focus {
  color: #8e24aa;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-purple {
  color: #fff;
}
.default-theme .text-purple {
  color: #8e24aa !important;
}
.default-theme a.text-purple:hover, .default-theme a.text-purple:focus {
  color: #6b1b80 !important;
}
.default-theme .bg-purple {
  background-color: #8e24aa !important;
}
.default-theme a.bg-purple:hover, .default-theme a.bg-purple:focus,
.default-theme button.bg-purple:hover,
.default-theme button.bg-purple:focus {
  background-color: #6b1b80 !important;
}
.default-theme .badge-bg-purple {
  background-color: #8e24aa !important;
}
.default-theme a.badge-bg-purple:hover, .default-theme a.badge-bg-purple:focus,
.default-theme button.badge-bg-purple:hover,
.default-theme button.badge-bg-purple:focus {
  background-color: #6b1b80 !important;
}
.default-theme .btn-outline-purple {
  border: 2px solid #8e24aa !important;
  background-color: transparent !important;
  color: #8e24aa !important;
}
.default-theme .btn-outline-purple:hover, .default-theme .btn-outline-purple:focus, .default-theme .btn-outline-purple:active, .default-theme .btn-outline-purple:active:focus, .default-theme .btn-outline-purple.active {
  border-color: #8e24aa !important;
  background-color: transparent !important;
  color: #8e24aa !important;
}
.default-theme .btn-outline-purple:not([disabled]):not(.disabled):active, .default-theme .btn-outline-purple:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-purple.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #8e24aa !important;
}
.default-theme .btn-outline-purple:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-purple:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-deep-purple {
  background-color: #512da8 !important;
  color: #fff;
}
.default-theme .btn-deep-purple:hover {
  background-color: #5b32bc;
  color: #fff;
}
.default-theme .btn-deep-purple:focus, .default-theme .btn-deep-purple.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-deep-purple:focus, .default-theme .btn-deep-purple:active, .default-theme .btn-deep-purple.active {
  background-color: #2a1758;
}
.default-theme .btn-deep-purple.dropdown-toggle {
  background-color: #512da8 !important;
}
.default-theme .btn-deep-purple.dropdown-toggle:hover, .default-theme .btn-deep-purple.dropdown-toggle:focus {
  background-color: #5b32bc !important;
}
.default-theme .btn-deep-purple:not([disabled]):not(.disabled):active, .default-theme .btn-deep-purple:not([disabled]):not(.disabled).active, .show > .default-theme .btn-deep-purple.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #2a1758 !important;
}
.default-theme .btn-deep-purple:not([disabled]):not(.disabled):active:focus, .default-theme .btn-deep-purple:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-deep-purple.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .deep-purple-ic {
  color: #512da8 !important;
}
.default-theme .deep-purple-ic:hover, .default-theme .deep-purple-ic:focus {
  color: #512da8;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-deep-purple {
  color: #fff;
}
.default-theme .text-deep-purple {
  color: #512da8 !important;
}
.default-theme a.text-deep-purple:hover, .default-theme a.text-deep-purple:focus {
  color: #3e2280 !important;
}
.default-theme .bg-deep-purple {
  background-color: #512da8 !important;
}
.default-theme a.bg-deep-purple:hover, .default-theme a.bg-deep-purple:focus,
.default-theme button.bg-deep-purple:hover,
.default-theme button.bg-deep-purple:focus {
  background-color: #3e2280 !important;
}
.default-theme .badge-bg-deep-purple {
  background-color: #512da8 !important;
}
.default-theme a.badge-bg-deep-purple:hover, .default-theme a.badge-bg-deep-purple:focus,
.default-theme button.badge-bg-deep-purple:hover,
.default-theme button.badge-bg-deep-purple:focus {
  background-color: #3e2280 !important;
}
.default-theme .btn-outline-deep-purple {
  border: 2px solid #512da8 !important;
  background-color: transparent !important;
  color: #512da8 !important;
}
.default-theme .btn-outline-deep-purple:hover, .default-theme .btn-outline-deep-purple:focus, .default-theme .btn-outline-deep-purple:active, .default-theme .btn-outline-deep-purple:active:focus, .default-theme .btn-outline-deep-purple.active {
  border-color: #512da8 !important;
  background-color: transparent !important;
  color: #512da8 !important;
}
.default-theme .btn-outline-deep-purple:not([disabled]):not(.disabled):active, .default-theme .btn-outline-deep-purple:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-deep-purple.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #512da8 !important;
}
.default-theme .btn-outline-deep-purple:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-deep-purple:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-deep-purple.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-indigo {
  background-color: #3f51b5 !important;
  color: #fff;
}
.default-theme .btn-indigo:hover {
  background-color: #4d5ec1;
  color: #fff;
}
.default-theme .btn-indigo:focus, .default-theme .btn-indigo.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-indigo:focus, .default-theme .btn-indigo:active, .default-theme .btn-indigo.active {
  background-color: #252f69;
}
.default-theme .btn-indigo.dropdown-toggle {
  background-color: #3f51b5 !important;
}
.default-theme .btn-indigo.dropdown-toggle:hover, .default-theme .btn-indigo.dropdown-toggle:focus {
  background-color: #4d5ec1 !important;
}
.default-theme .btn-indigo:not([disabled]):not(.disabled):active, .default-theme .btn-indigo:not([disabled]):not(.disabled).active, .show > .default-theme .btn-indigo.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #252f69 !important;
}
.default-theme .btn-indigo:not([disabled]):not(.disabled):active:focus, .default-theme .btn-indigo:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .indigo-ic {
  color: #3f51b5 !important;
}
.default-theme .indigo-ic:hover, .default-theme .indigo-ic:focus {
  color: #3f51b5;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-indigo {
  color: #fff;
}
.default-theme .text-indigo {
  color: #3f51b5 !important;
}
.default-theme a.text-indigo:hover, .default-theme a.text-indigo:focus {
  color: #32408f !important;
}
.default-theme .bg-indigo {
  background-color: #3f51b5 !important;
}
.default-theme a.bg-indigo:hover, .default-theme a.bg-indigo:focus,
.default-theme button.bg-indigo:hover,
.default-theme button.bg-indigo:focus {
  background-color: #32408f !important;
}
.default-theme .badge-bg-indigo {
  background-color: #3f51b5 !important;
}
.default-theme a.badge-bg-indigo:hover, .default-theme a.badge-bg-indigo:focus,
.default-theme button.badge-bg-indigo:hover,
.default-theme button.badge-bg-indigo:focus {
  background-color: #32408f !important;
}
.default-theme .btn-outline-indigo {
  border: 2px solid #3f51b5 !important;
  background-color: transparent !important;
  color: #3f51b5 !important;
}
.default-theme .btn-outline-indigo:hover, .default-theme .btn-outline-indigo:focus, .default-theme .btn-outline-indigo:active, .default-theme .btn-outline-indigo:active:focus, .default-theme .btn-outline-indigo.active {
  border-color: #3f51b5 !important;
  background-color: transparent !important;
  color: #3f51b5 !important;
}
.default-theme .btn-outline-indigo:not([disabled]):not(.disabled):active, .default-theme .btn-outline-indigo:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-indigo.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #3f51b5 !important;
}
.default-theme .btn-outline-indigo:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-indigo:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-indigo.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-blue {
  background-color: #1976d2 !important;
  color: #fff;
}
.default-theme .btn-blue:hover {
  background-color: #2083e4;
  color: #fff;
}
.default-theme .btn-blue:focus, .default-theme .btn-blue.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-blue:focus, .default-theme .btn-blue:active, .default-theme .btn-blue.active {
  background-color: #0e4377;
}
.default-theme .btn-blue.dropdown-toggle {
  background-color: #1976d2 !important;
}
.default-theme .btn-blue.dropdown-toggle:hover, .default-theme .btn-blue.dropdown-toggle:focus {
  background-color: #2083e4 !important;
}
.default-theme .btn-blue:not([disabled]):not(.disabled):active, .default-theme .btn-blue:not([disabled]):not(.disabled).active, .show > .default-theme .btn-blue.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #0e4377 !important;
}
.default-theme .btn-blue:not([disabled]):not(.disabled):active:focus, .default-theme .btn-blue:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .blue-ic {
  color: #1976d2 !important;
}
.default-theme .blue-ic:hover, .default-theme .blue-ic:focus {
  color: #1976d2;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-blue {
  color: #fff;
}
.default-theme .text-blue {
  color: #1976d2 !important;
}
.default-theme a.text-blue:hover, .default-theme a.text-blue:focus {
  color: #145ca4 !important;
}
.default-theme .bg-blue {
  background-color: #1976d2 !important;
}
.default-theme a.bg-blue:hover, .default-theme a.bg-blue:focus,
.default-theme button.bg-blue:hover,
.default-theme button.bg-blue:focus {
  background-color: #145ca4 !important;
}
.default-theme .badge-bg-blue {
  background-color: #1976d2 !important;
}
.default-theme a.badge-bg-blue:hover, .default-theme a.badge-bg-blue:focus,
.default-theme button.badge-bg-blue:hover,
.default-theme button.badge-bg-blue:focus {
  background-color: #145ca4 !important;
}
.default-theme .btn-outline-blue {
  border: 2px solid #1976d2 !important;
  background-color: transparent !important;
  color: #1976d2 !important;
}
.default-theme .btn-outline-blue:hover, .default-theme .btn-outline-blue:focus, .default-theme .btn-outline-blue:active, .default-theme .btn-outline-blue:active:focus, .default-theme .btn-outline-blue.active {
  border-color: #1976d2 !important;
  background-color: transparent !important;
  color: #1976d2 !important;
}
.default-theme .btn-outline-blue:not([disabled]):not(.disabled):active, .default-theme .btn-outline-blue:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-blue.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #1976d2 !important;
}
.default-theme .btn-outline-blue:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-blue:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-light-blue {
  background-color: #82b1ff !important;
  color: #fff;
}
.default-theme .btn-light-blue:hover {
  background-color: #9cc1ff;
  color: #fff;
}
.default-theme .btn-light-blue:focus, .default-theme .btn-light-blue.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-light-blue:focus, .default-theme .btn-light-blue:active, .default-theme .btn-light-blue.active {
  background-color: #1c71ff;
}
.default-theme .btn-light-blue.dropdown-toggle {
  background-color: #82b1ff !important;
}
.default-theme .btn-light-blue.dropdown-toggle:hover, .default-theme .btn-light-blue.dropdown-toggle:focus {
  background-color: #9cc1ff !important;
}
.default-theme .btn-light-blue:not([disabled]):not(.disabled):active, .default-theme .btn-light-blue:not([disabled]):not(.disabled).active, .show > .default-theme .btn-light-blue.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #1c71ff !important;
}
.default-theme .btn-light-blue:not([disabled]):not(.disabled):active:focus, .default-theme .btn-light-blue:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-light-blue.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .light-blue-ic {
  color: #82b1ff !important;
}
.default-theme .light-blue-ic:hover, .default-theme .light-blue-ic:focus {
  color: #82b1ff;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-light-blue {
  color: #fff;
}
.default-theme .text-light-blue {
  color: #82b1ff !important;
}
.default-theme a.text-light-blue:hover, .default-theme a.text-light-blue:focus {
  color: #4f91ff !important;
}
.default-theme .bg-light-blue {
  background-color: #82b1ff !important;
}
.default-theme a.bg-light-blue:hover, .default-theme a.bg-light-blue:focus,
.default-theme button.bg-light-blue:hover,
.default-theme button.bg-light-blue:focus {
  background-color: #4f91ff !important;
}
.default-theme .badge-bg-light-blue {
  background-color: #82b1ff !important;
}
.default-theme a.badge-bg-light-blue:hover, .default-theme a.badge-bg-light-blue:focus,
.default-theme button.badge-bg-light-blue:hover,
.default-theme button.badge-bg-light-blue:focus {
  background-color: #4f91ff !important;
}
.default-theme .btn-outline-light-blue {
  border: 2px solid #82b1ff !important;
  background-color: transparent !important;
  color: #82b1ff !important;
}
.default-theme .btn-outline-light-blue:hover, .default-theme .btn-outline-light-blue:focus, .default-theme .btn-outline-light-blue:active, .default-theme .btn-outline-light-blue:active:focus, .default-theme .btn-outline-light-blue.active {
  border-color: #82b1ff !important;
  background-color: transparent !important;
  color: #82b1ff !important;
}
.default-theme .btn-outline-light-blue:not([disabled]):not(.disabled):active, .default-theme .btn-outline-light-blue:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-light-blue.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #82b1ff !important;
}
.default-theme .btn-outline-light-blue:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-light-blue:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-light-blue.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-cyan {
  background-color: #00bcd4 !important;
  color: #fff;
}
.default-theme .btn-cyan:hover {
  background-color: #00d3ee;
  color: #fff;
}
.default-theme .btn-cyan:focus, .default-theme .btn-cyan.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-cyan:focus, .default-theme .btn-cyan:active, .default-theme .btn-cyan.active {
  background-color: #00626e;
}
.default-theme .btn-cyan.dropdown-toggle {
  background-color: #00bcd4 !important;
}
.default-theme .btn-cyan.dropdown-toggle:hover, .default-theme .btn-cyan.dropdown-toggle:focus {
  background-color: #00d3ee !important;
}
.default-theme .btn-cyan:not([disabled]):not(.disabled):active, .default-theme .btn-cyan:not([disabled]):not(.disabled).active, .show > .default-theme .btn-cyan.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #00626e !important;
}
.default-theme .btn-cyan:not([disabled]):not(.disabled):active:focus, .default-theme .btn-cyan:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-cyan.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .cyan-ic {
  color: #00bcd4 !important;
}
.default-theme .cyan-ic:hover, .default-theme .cyan-ic:focus {
  color: #00bcd4;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-cyan {
  color: #fff;
}
.default-theme .text-cyan {
  color: #00bcd4 !important;
}
.default-theme a.text-cyan:hover, .default-theme a.text-cyan:focus {
  color: #008fa1 !important;
}
.default-theme .bg-cyan {
  background-color: #00bcd4 !important;
}
.default-theme a.bg-cyan:hover, .default-theme a.bg-cyan:focus,
.default-theme button.bg-cyan:hover,
.default-theme button.bg-cyan:focus {
  background-color: #008fa1 !important;
}
.default-theme .badge-bg-cyan {
  background-color: #00bcd4 !important;
}
.default-theme a.badge-bg-cyan:hover, .default-theme a.badge-bg-cyan:focus,
.default-theme button.badge-bg-cyan:hover,
.default-theme button.badge-bg-cyan:focus {
  background-color: #008fa1 !important;
}
.default-theme .btn-outline-cyan {
  border: 2px solid #00bcd4 !important;
  background-color: transparent !important;
  color: #00bcd4 !important;
}
.default-theme .btn-outline-cyan:hover, .default-theme .btn-outline-cyan:focus, .default-theme .btn-outline-cyan:active, .default-theme .btn-outline-cyan:active:focus, .default-theme .btn-outline-cyan.active {
  border-color: #00bcd4 !important;
  background-color: transparent !important;
  color: #00bcd4 !important;
}
.default-theme .btn-outline-cyan:not([disabled]):not(.disabled):active, .default-theme .btn-outline-cyan:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-cyan.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #00bcd4 !important;
}
.default-theme .btn-outline-cyan:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-cyan:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-cyan.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-teal {
  background-color: #00796b !important;
  color: #fff;
}
.default-theme .btn-teal:hover {
  background-color: #009382;
  color: #fff;
}
.default-theme .btn-teal:focus, .default-theme .btn-teal.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-teal:focus, .default-theme .btn-teal:active, .default-theme .btn-teal.active {
  background-color: #001311;
}
.default-theme .btn-teal.dropdown-toggle {
  background-color: #00796b !important;
}
.default-theme .btn-teal.dropdown-toggle:hover, .default-theme .btn-teal.dropdown-toggle:focus {
  background-color: #009382 !important;
}
.default-theme .btn-teal:not([disabled]):not(.disabled):active, .default-theme .btn-teal:not([disabled]):not(.disabled).active, .show > .default-theme .btn-teal.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #001311 !important;
}
.default-theme .btn-teal:not([disabled]):not(.disabled):active:focus, .default-theme .btn-teal:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .teal-ic {
  color: #00796b !important;
}
.default-theme .teal-ic:hover, .default-theme .teal-ic:focus {
  color: #00796b;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-teal {
  color: #fff;
}
.default-theme .text-teal {
  color: #00796b !important;
}
.default-theme a.text-teal:hover, .default-theme a.text-teal:focus {
  color: #00463e !important;
}
.default-theme .bg-teal {
  background-color: #00796b !important;
}
.default-theme a.bg-teal:hover, .default-theme a.bg-teal:focus,
.default-theme button.bg-teal:hover,
.default-theme button.bg-teal:focus {
  background-color: #00463e !important;
}
.default-theme .badge-bg-teal {
  background-color: #00796b !important;
}
.default-theme a.badge-bg-teal:hover, .default-theme a.badge-bg-teal:focus,
.default-theme button.badge-bg-teal:hover,
.default-theme button.badge-bg-teal:focus {
  background-color: #00463e !important;
}
.default-theme .btn-outline-teal {
  border: 2px solid #00796b !important;
  background-color: transparent !important;
  color: #00796b !important;
}
.default-theme .btn-outline-teal:hover, .default-theme .btn-outline-teal:focus, .default-theme .btn-outline-teal:active, .default-theme .btn-outline-teal:active:focus, .default-theme .btn-outline-teal.active {
  border-color: #00796b !important;
  background-color: transparent !important;
  color: #00796b !important;
}
.default-theme .btn-outline-teal:not([disabled]):not(.disabled):active, .default-theme .btn-outline-teal:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-teal.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #00796b !important;
}
.default-theme .btn-outline-teal:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-teal:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-teal.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-green {
  background-color: #388e3c !important;
  color: #fff;
}
.default-theme .btn-green:hover {
  background-color: #3fa044;
  color: #fff;
}
.default-theme .btn-green:focus, .default-theme .btn-green.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-green:focus, .default-theme .btn-green:active, .default-theme .btn-green.active {
  background-color: #1b451d;
}
.default-theme .btn-green.dropdown-toggle {
  background-color: #388e3c !important;
}
.default-theme .btn-green.dropdown-toggle:hover, .default-theme .btn-green.dropdown-toggle:focus {
  background-color: #3fa044 !important;
}
.default-theme .btn-green:not([disabled]):not(.disabled):active, .default-theme .btn-green:not([disabled]):not(.disabled).active, .show > .default-theme .btn-green.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #1b451d !important;
}
.default-theme .btn-green:not([disabled]):not(.disabled):active:focus, .default-theme .btn-green:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-green.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .green-ic {
  color: #388e3c !important;
}
.default-theme .green-ic:hover, .default-theme .green-ic:focus {
  color: #388e3c;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-green {
  color: #fff;
}
.default-theme .text-green {
  color: #388e3c !important;
}
.default-theme a.text-green:hover, .default-theme a.text-green:focus {
  color: #2a692d !important;
}
.default-theme .bg-green {
  background-color: #388e3c !important;
}
.default-theme a.bg-green:hover, .default-theme a.bg-green:focus,
.default-theme button.bg-green:hover,
.default-theme button.bg-green:focus {
  background-color: #2a692d !important;
}
.default-theme .badge-bg-green {
  background-color: #388e3c !important;
}
.default-theme a.badge-bg-green:hover, .default-theme a.badge-bg-green:focus,
.default-theme button.badge-bg-green:hover,
.default-theme button.badge-bg-green:focus {
  background-color: #2a692d !important;
}
.default-theme .btn-outline-green {
  border: 2px solid #388e3c !important;
  background-color: transparent !important;
  color: #388e3c !important;
}
.default-theme .btn-outline-green:hover, .default-theme .btn-outline-green:focus, .default-theme .btn-outline-green:active, .default-theme .btn-outline-green:active:focus, .default-theme .btn-outline-green.active {
  border-color: #388e3c !important;
  background-color: transparent !important;
  color: #388e3c !important;
}
.default-theme .btn-outline-green:not([disabled]):not(.disabled):active, .default-theme .btn-outline-green:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-green.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #388e3c !important;
}
.default-theme .btn-outline-green:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-green:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-green.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-light-green {
  background-color: #8bc34a !important;
  color: #fff;
}
.default-theme .btn-light-green:hover {
  background-color: #97c95d;
  color: #fff;
}
.default-theme .btn-light-green:focus, .default-theme .btn-light-green.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-light-green:focus, .default-theme .btn-light-green:active, .default-theme .btn-light-green.active {
  background-color: #577d2a;
}
.default-theme .btn-light-green.dropdown-toggle {
  background-color: #8bc34a !important;
}
.default-theme .btn-light-green.dropdown-toggle:hover, .default-theme .btn-light-green.dropdown-toggle:focus {
  background-color: #97c95d !important;
}
.default-theme .btn-light-green:not([disabled]):not(.disabled):active, .default-theme .btn-light-green:not([disabled]):not(.disabled).active, .show > .default-theme .btn-light-green.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #577d2a !important;
}
.default-theme .btn-light-green:not([disabled]):not(.disabled):active:focus, .default-theme .btn-light-green:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-light-green.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .light-green-ic {
  color: #8bc34a !important;
}
.default-theme .light-green-ic:hover, .default-theme .light-green-ic:focus {
  color: #8bc34a;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-light-green {
  color: #fff;
}
.default-theme .text-light-green {
  color: #8bc34a !important;
}
.default-theme a.text-light-green:hover, .default-theme a.text-light-green:focus {
  color: #71a436 !important;
}
.default-theme .bg-light-green {
  background-color: #8bc34a !important;
}
.default-theme a.bg-light-green:hover, .default-theme a.bg-light-green:focus,
.default-theme button.bg-light-green:hover,
.default-theme button.bg-light-green:focus {
  background-color: #71a436 !important;
}
.default-theme .badge-bg-light-green {
  background-color: #8bc34a !important;
}
.default-theme a.badge-bg-light-green:hover, .default-theme a.badge-bg-light-green:focus,
.default-theme button.badge-bg-light-green:hover,
.default-theme button.badge-bg-light-green:focus {
  background-color: #71a436 !important;
}
.default-theme .btn-outline-light-green {
  border: 2px solid #8bc34a !important;
  background-color: transparent !important;
  color: #8bc34a !important;
}
.default-theme .btn-outline-light-green:hover, .default-theme .btn-outline-light-green:focus, .default-theme .btn-outline-light-green:active, .default-theme .btn-outline-light-green:active:focus, .default-theme .btn-outline-light-green.active {
  border-color: #8bc34a !important;
  background-color: transparent !important;
  color: #8bc34a !important;
}
.default-theme .btn-outline-light-green:not([disabled]):not(.disabled):active, .default-theme .btn-outline-light-green:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-light-green.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #8bc34a !important;
}
.default-theme .btn-outline-light-green:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-light-green:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-light-green.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-lime {
  background-color: #afb42b !important;
  color: #fff;
}
.default-theme .btn-lime:hover {
  background-color: #c3c930;
  color: #fff;
}
.default-theme .btn-lime:focus, .default-theme .btn-lime.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-lime:focus, .default-theme .btn-lime:active, .default-theme .btn-lime.active {
  background-color: #5f6217;
}
.default-theme .btn-lime.dropdown-toggle {
  background-color: #afb42b !important;
}
.default-theme .btn-lime.dropdown-toggle:hover, .default-theme .btn-lime.dropdown-toggle:focus {
  background-color: #c3c930 !important;
}
.default-theme .btn-lime:not([disabled]):not(.disabled):active, .default-theme .btn-lime:not([disabled]):not(.disabled).active, .show > .default-theme .btn-lime.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #5f6217 !important;
}
.default-theme .btn-lime:not([disabled]):not(.disabled):active:focus, .default-theme .btn-lime:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-lime.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .lime-ic {
  color: #afb42b !important;
}
.default-theme .lime-ic:hover, .default-theme .lime-ic:focus {
  color: #afb42b;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-lime {
  color: #fff;
}
.default-theme .text-lime {
  color: #afb42b !important;
}
.default-theme a.text-lime:hover, .default-theme a.text-lime:focus {
  color: #878b21 !important;
}
.default-theme .bg-lime {
  background-color: #afb42b !important;
}
.default-theme a.bg-lime:hover, .default-theme a.bg-lime:focus,
.default-theme button.bg-lime:hover,
.default-theme button.bg-lime:focus {
  background-color: #878b21 !important;
}
.default-theme .badge-bg-lime {
  background-color: #afb42b !important;
}
.default-theme a.badge-bg-lime:hover, .default-theme a.badge-bg-lime:focus,
.default-theme button.badge-bg-lime:hover,
.default-theme button.badge-bg-lime:focus {
  background-color: #878b21 !important;
}
.default-theme .btn-outline-lime {
  border: 2px solid #afb42b !important;
  background-color: transparent !important;
  color: #afb42b !important;
}
.default-theme .btn-outline-lime:hover, .default-theme .btn-outline-lime:focus, .default-theme .btn-outline-lime:active, .default-theme .btn-outline-lime:active:focus, .default-theme .btn-outline-lime.active {
  border-color: #afb42b !important;
  background-color: transparent !important;
  color: #afb42b !important;
}
.default-theme .btn-outline-lime:not([disabled]):not(.disabled):active, .default-theme .btn-outline-lime:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-lime.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #afb42b !important;
}
.default-theme .btn-outline-lime:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-lime:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-lime.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-yellow {
  background-color: #fbc02d !important;
  color: #fff;
}
.default-theme .btn-yellow:hover {
  background-color: #fbc846;
  color: #fff;
}
.default-theme .btn-yellow:focus, .default-theme .btn-yellow.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-yellow:focus, .default-theme .btn-yellow:active, .default-theme .btn-yellow.active {
  background-color: #be8904;
}
.default-theme .btn-yellow.dropdown-toggle {
  background-color: #fbc02d !important;
}
.default-theme .btn-yellow.dropdown-toggle:hover, .default-theme .btn-yellow.dropdown-toggle:focus {
  background-color: #fbc846 !important;
}
.default-theme .btn-yellow:not([disabled]):not(.disabled):active, .default-theme .btn-yellow:not([disabled]):not(.disabled).active, .show > .default-theme .btn-yellow.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #be8904 !important;
}
.default-theme .btn-yellow:not([disabled]):not(.disabled):active:focus, .default-theme .btn-yellow:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .yellow-ic {
  color: #fbc02d !important;
}
.default-theme .yellow-ic:hover, .default-theme .yellow-ic:focus {
  color: #fbc02d;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-yellow {
  color: #fff;
}
.default-theme .text-yellow {
  color: #fbc02d !important;
}
.default-theme a.text-yellow:hover, .default-theme a.text-yellow:focus {
  color: #f0ad05 !important;
}
.default-theme .bg-yellow {
  background-color: #fbc02d !important;
}
.default-theme a.bg-yellow:hover, .default-theme a.bg-yellow:focus,
.default-theme button.bg-yellow:hover,
.default-theme button.bg-yellow:focus {
  background-color: #f0ad05 !important;
}
.default-theme .badge-bg-yellow {
  background-color: #fbc02d !important;
}
.default-theme a.badge-bg-yellow:hover, .default-theme a.badge-bg-yellow:focus,
.default-theme button.badge-bg-yellow:hover,
.default-theme button.badge-bg-yellow:focus {
  background-color: #f0ad05 !important;
}
.default-theme .btn-outline-yellow {
  border: 2px solid #fbc02d !important;
  background-color: transparent !important;
  color: #fbc02d !important;
}
.default-theme .btn-outline-yellow:hover, .default-theme .btn-outline-yellow:focus, .default-theme .btn-outline-yellow:active, .default-theme .btn-outline-yellow:active:focus, .default-theme .btn-outline-yellow.active {
  border-color: #fbc02d !important;
  background-color: transparent !important;
  color: #fbc02d !important;
}
.default-theme .btn-outline-yellow:not([disabled]):not(.disabled):active, .default-theme .btn-outline-yellow:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-yellow.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #fbc02d !important;
}
.default-theme .btn-outline-yellow:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-yellow:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-yellow.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-amber {
  background-color: #ffa000 !important;
  color: #fff;
}
.default-theme .btn-amber:hover {
  background-color: #ffaa1a;
  color: #fff;
}
.default-theme .btn-amber:focus, .default-theme .btn-amber.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-amber:focus, .default-theme .btn-amber:active, .default-theme .btn-amber.active {
  background-color: #996000;
}
.default-theme .btn-amber.dropdown-toggle {
  background-color: #ffa000 !important;
}
.default-theme .btn-amber.dropdown-toggle:hover, .default-theme .btn-amber.dropdown-toggle:focus {
  background-color: #ffaa1a !important;
}
.default-theme .btn-amber:not([disabled]):not(.disabled):active, .default-theme .btn-amber:not([disabled]):not(.disabled).active, .show > .default-theme .btn-amber.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #996000 !important;
}
.default-theme .btn-amber:not([disabled]):not(.disabled):active:focus, .default-theme .btn-amber:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-amber.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .amber-ic {
  color: #ffa000 !important;
}
.default-theme .amber-ic:hover, .default-theme .amber-ic:focus {
  color: #ffa000;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-amber {
  color: #fff;
}
.default-theme .text-amber {
  color: #ffa000 !important;
}
.default-theme a.text-amber:hover, .default-theme a.text-amber:focus {
  color: #cc8000 !important;
}
.default-theme .bg-amber {
  background-color: #ffa000 !important;
}
.default-theme a.bg-amber:hover, .default-theme a.bg-amber:focus,
.default-theme button.bg-amber:hover,
.default-theme button.bg-amber:focus {
  background-color: #cc8000 !important;
}
.default-theme .badge-bg-amber {
  background-color: #ffa000 !important;
}
.default-theme a.badge-bg-amber:hover, .default-theme a.badge-bg-amber:focus,
.default-theme button.badge-bg-amber:hover,
.default-theme button.badge-bg-amber:focus {
  background-color: #cc8000 !important;
}
.default-theme .btn-outline-amber {
  border: 2px solid #ffa000 !important;
  background-color: transparent !important;
  color: #ffa000 !important;
}
.default-theme .btn-outline-amber:hover, .default-theme .btn-outline-amber:focus, .default-theme .btn-outline-amber:active, .default-theme .btn-outline-amber:active:focus, .default-theme .btn-outline-amber.active {
  border-color: #ffa000 !important;
  background-color: transparent !important;
  color: #ffa000 !important;
}
.default-theme .btn-outline-amber:not([disabled]):not(.disabled):active, .default-theme .btn-outline-amber:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-amber.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ffa000 !important;
}
.default-theme .btn-outline-amber:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-amber:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-amber.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-orange {
  background-color: #f57c00 !important;
  color: #fff;
}
.default-theme .btn-orange:hover {
  background-color: #ff8910;
  color: #fff;
}
.default-theme .btn-orange:focus, .default-theme .btn-orange.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-orange:focus, .default-theme .btn-orange:active, .default-theme .btn-orange.active {
  background-color: #8f4800;
}
.default-theme .btn-orange.dropdown-toggle {
  background-color: #f57c00 !important;
}
.default-theme .btn-orange.dropdown-toggle:hover, .default-theme .btn-orange.dropdown-toggle:focus {
  background-color: #ff8910 !important;
}
.default-theme .btn-orange:not([disabled]):not(.disabled):active, .default-theme .btn-orange:not([disabled]):not(.disabled).active, .show > .default-theme .btn-orange.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #8f4800 !important;
}
.default-theme .btn-orange:not([disabled]):not(.disabled):active:focus, .default-theme .btn-orange:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .orange-ic {
  color: #f57c00 !important;
}
.default-theme .orange-ic:hover, .default-theme .orange-ic:focus {
  color: #f57c00;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-orange {
  color: #fff;
}
.default-theme .text-orange {
  color: #f57c00 !important;
}
.default-theme a.text-orange:hover, .default-theme a.text-orange:focus {
  color: #c26200 !important;
}
.default-theme .bg-orange {
  background-color: #f57c00 !important;
}
.default-theme a.bg-orange:hover, .default-theme a.bg-orange:focus,
.default-theme button.bg-orange:hover,
.default-theme button.bg-orange:focus {
  background-color: #c26200 !important;
}
.default-theme .badge-bg-orange {
  background-color: #f57c00 !important;
}
.default-theme a.badge-bg-orange:hover, .default-theme a.badge-bg-orange:focus,
.default-theme button.badge-bg-orange:hover,
.default-theme button.badge-bg-orange:focus {
  background-color: #c26200 !important;
}
.default-theme .btn-outline-orange {
  border: 2px solid #f57c00 !important;
  background-color: transparent !important;
  color: #f57c00 !important;
}
.default-theme .btn-outline-orange:hover, .default-theme .btn-outline-orange:focus, .default-theme .btn-outline-orange:active, .default-theme .btn-outline-orange:active:focus, .default-theme .btn-outline-orange.active {
  border-color: #f57c00 !important;
  background-color: transparent !important;
  color: #f57c00 !important;
}
.default-theme .btn-outline-orange:not([disabled]):not(.disabled):active, .default-theme .btn-outline-orange:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-orange.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #f57c00 !important;
}
.default-theme .btn-outline-orange:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-orange:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-deep-orange {
  background-color: #ff7043 !important;
  color: #fff;
}
.default-theme .btn-deep-orange:hover {
  background-color: #ff835d;
  color: #fff;
}
.default-theme .btn-deep-orange:focus, .default-theme .btn-deep-orange.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-deep-orange:focus, .default-theme .btn-deep-orange:active, .default-theme .btn-deep-orange.active {
  background-color: #dc3500;
}
.default-theme .btn-deep-orange.dropdown-toggle {
  background-color: #ff7043 !important;
}
.default-theme .btn-deep-orange.dropdown-toggle:hover, .default-theme .btn-deep-orange.dropdown-toggle:focus {
  background-color: #ff835d !important;
}
.default-theme .btn-deep-orange:not([disabled]):not(.disabled):active, .default-theme .btn-deep-orange:not([disabled]):not(.disabled).active, .show > .default-theme .btn-deep-orange.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #dc3500 !important;
}
.default-theme .btn-deep-orange:not([disabled]):not(.disabled):active:focus, .default-theme .btn-deep-orange:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-deep-orange.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .deep-orange-ic {
  color: #ff7043 !important;
}
.default-theme .deep-orange-ic:hover, .default-theme .deep-orange-ic:focus {
  color: #ff7043;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-deep-orange {
  color: #fff;
}
.default-theme .text-deep-orange {
  color: #ff7043 !important;
}
.default-theme a.text-deep-orange:hover, .default-theme a.text-deep-orange:focus {
  color: #ff4910 !important;
}
.default-theme .bg-deep-orange {
  background-color: #ff7043 !important;
}
.default-theme a.bg-deep-orange:hover, .default-theme a.bg-deep-orange:focus,
.default-theme button.bg-deep-orange:hover,
.default-theme button.bg-deep-orange:focus {
  background-color: #ff4910 !important;
}
.default-theme .badge-bg-deep-orange {
  background-color: #ff7043 !important;
}
.default-theme a.badge-bg-deep-orange:hover, .default-theme a.badge-bg-deep-orange:focus,
.default-theme button.badge-bg-deep-orange:hover,
.default-theme button.badge-bg-deep-orange:focus {
  background-color: #ff4910 !important;
}
.default-theme .btn-outline-deep-orange {
  border: 2px solid #ff7043 !important;
  background-color: transparent !important;
  color: #ff7043 !important;
}
.default-theme .btn-outline-deep-orange:hover, .default-theme .btn-outline-deep-orange:focus, .default-theme .btn-outline-deep-orange:active, .default-theme .btn-outline-deep-orange:active:focus, .default-theme .btn-outline-deep-orange.active {
  border-color: #ff7043 !important;
  background-color: transparent !important;
  color: #ff7043 !important;
}
.default-theme .btn-outline-deep-orange:not([disabled]):not(.disabled):active, .default-theme .btn-outline-deep-orange:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-deep-orange.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #ff7043 !important;
}
.default-theme .btn-outline-deep-orange:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-deep-orange:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-deep-orange.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-brown {
  background-color: #795548 !important;
  color: #fff;
}
.default-theme .btn-brown:hover {
  background-color: #896052;
  color: #fff;
}
.default-theme .btn-brown:focus, .default-theme .btn-brown.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-brown:focus, .default-theme .btn-brown:active, .default-theme .btn-brown.active {
  background-color: #392822;
}
.default-theme .btn-brown.dropdown-toggle {
  background-color: #795548 !important;
}
.default-theme .btn-brown.dropdown-toggle:hover, .default-theme .btn-brown.dropdown-toggle:focus {
  background-color: #896052 !important;
}
.default-theme .btn-brown:not([disabled]):not(.disabled):active, .default-theme .btn-brown:not([disabled]):not(.disabled).active, .show > .default-theme .btn-brown.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #392822 !important;
}
.default-theme .btn-brown:not([disabled]):not(.disabled):active:focus, .default-theme .btn-brown:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-brown.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .brown-ic {
  color: #795548 !important;
}
.default-theme .brown-ic:hover, .default-theme .brown-ic:focus {
  color: #795548;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-brown {
  color: #fff;
}
.default-theme .text-brown {
  color: #795548 !important;
}
.default-theme a.text-brown:hover, .default-theme a.text-brown:focus {
  color: #593f35 !important;
}
.default-theme .bg-brown {
  background-color: #795548 !important;
}
.default-theme a.bg-brown:hover, .default-theme a.bg-brown:focus,
.default-theme button.bg-brown:hover,
.default-theme button.bg-brown:focus {
  background-color: #593f35 !important;
}
.default-theme .badge-bg-brown {
  background-color: #795548 !important;
}
.default-theme a.badge-bg-brown:hover, .default-theme a.badge-bg-brown:focus,
.default-theme button.badge-bg-brown:hover,
.default-theme button.badge-bg-brown:focus {
  background-color: #593f35 !important;
}
.default-theme .btn-outline-brown {
  border: 2px solid #795548 !important;
  background-color: transparent !important;
  color: #795548 !important;
}
.default-theme .btn-outline-brown:hover, .default-theme .btn-outline-brown:focus, .default-theme .btn-outline-brown:active, .default-theme .btn-outline-brown:active:focus, .default-theme .btn-outline-brown.active {
  border-color: #795548 !important;
  background-color: transparent !important;
  color: #795548 !important;
}
.default-theme .btn-outline-brown:not([disabled]):not(.disabled):active, .default-theme .btn-outline-brown:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-brown.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #795548 !important;
}
.default-theme .btn-outline-brown:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-brown:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-brown.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-grey {
  background-color: #616161 !important;
  color: #fff;
}
.default-theme .btn-grey:hover {
  background-color: #6e6e6e;
  color: #fff;
}
.default-theme .btn-grey:focus, .default-theme .btn-grey.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-grey:focus, .default-theme .btn-grey:active, .default-theme .btn-grey.active {
  background-color: #2e2e2e;
}
.default-theme .btn-grey.dropdown-toggle {
  background-color: #616161 !important;
}
.default-theme .btn-grey.dropdown-toggle:hover, .default-theme .btn-grey.dropdown-toggle:focus {
  background-color: #6e6e6e !important;
}
.default-theme .btn-grey:not([disabled]):not(.disabled):active, .default-theme .btn-grey:not([disabled]):not(.disabled).active, .show > .default-theme .btn-grey.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #2e2e2e !important;
}
.default-theme .btn-grey:not([disabled]):not(.disabled):active:focus, .default-theme .btn-grey:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-grey.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .grey-ic {
  color: #616161 !important;
}
.default-theme .grey-ic:hover, .default-theme .grey-ic:focus {
  color: #616161;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-grey {
  color: #fff;
}
.default-theme .text-grey {
  color: #616161 !important;
}
.default-theme a.text-grey:hover, .default-theme a.text-grey:focus {
  color: #484848 !important;
}
.default-theme .bg-grey {
  background-color: #616161 !important;
}
.default-theme a.bg-grey:hover, .default-theme a.bg-grey:focus,
.default-theme button.bg-grey:hover,
.default-theme button.bg-grey:focus {
  background-color: #484848 !important;
}
.default-theme .badge-bg-grey {
  background-color: #616161 !important;
}
.default-theme a.badge-bg-grey:hover, .default-theme a.badge-bg-grey:focus,
.default-theme button.badge-bg-grey:hover,
.default-theme button.badge-bg-grey:focus {
  background-color: #484848 !important;
}
.default-theme .btn-outline-grey {
  border: 2px solid #616161 !important;
  background-color: transparent !important;
  color: #616161 !important;
}
.default-theme .btn-outline-grey:hover, .default-theme .btn-outline-grey:focus, .default-theme .btn-outline-grey:active, .default-theme .btn-outline-grey:active:focus, .default-theme .btn-outline-grey.active {
  border-color: #616161 !important;
  background-color: transparent !important;
  color: #616161 !important;
}
.default-theme .btn-outline-grey:not([disabled]):not(.disabled):active, .default-theme .btn-outline-grey:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-grey.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #616161 !important;
}
.default-theme .btn-outline-grey:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-grey:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-grey.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-blue-grey {
  background-color: #78909c !important;
  color: #fff;
}
.default-theme .btn-blue-grey:hover {
  background-color: #879ca7;
  color: #fff;
}
.default-theme .btn-blue-grey:focus, .default-theme .btn-blue-grey.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-blue-grey:focus, .default-theme .btn-blue-grey:active, .default-theme .btn-blue-grey.active {
  background-color: #4a5b64;
}
.default-theme .btn-blue-grey.dropdown-toggle {
  background-color: #78909c !important;
}
.default-theme .btn-blue-grey.dropdown-toggle:hover, .default-theme .btn-blue-grey.dropdown-toggle:focus {
  background-color: #879ca7 !important;
}
.default-theme .btn-blue-grey:not([disabled]):not(.disabled):active, .default-theme .btn-blue-grey:not([disabled]):not(.disabled).active, .show > .default-theme .btn-blue-grey.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #4a5b64 !important;
}
.default-theme .btn-blue-grey:not([disabled]):not(.disabled):active:focus, .default-theme .btn-blue-grey:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-blue-grey.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .blue-grey-ic {
  color: #78909c !important;
}
.default-theme .blue-grey-ic:hover, .default-theme .blue-grey-ic:focus {
  color: #78909c;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-blue-grey {
  color: #fff;
}
.default-theme .text-blue-grey {
  color: #78909c !important;
}
.default-theme a.text-blue-grey:hover, .default-theme a.text-blue-grey:focus {
  color: #5f7682 !important;
}
.default-theme .bg-blue-grey {
  background-color: #78909c !important;
}
.default-theme a.bg-blue-grey:hover, .default-theme a.bg-blue-grey:focus,
.default-theme button.bg-blue-grey:hover,
.default-theme button.bg-blue-grey:focus {
  background-color: #5f7682 !important;
}
.default-theme .badge-bg-blue-grey {
  background-color: #78909c !important;
}
.default-theme a.badge-bg-blue-grey:hover, .default-theme a.badge-bg-blue-grey:focus,
.default-theme button.badge-bg-blue-grey:hover,
.default-theme button.badge-bg-blue-grey:focus {
  background-color: #5f7682 !important;
}
.default-theme .btn-outline-blue-grey {
  border: 2px solid #78909c !important;
  background-color: transparent !important;
  color: #78909c !important;
}
.default-theme .btn-outline-blue-grey:hover, .default-theme .btn-outline-blue-grey:focus, .default-theme .btn-outline-blue-grey:active, .default-theme .btn-outline-blue-grey:active:focus, .default-theme .btn-outline-blue-grey.active {
  border-color: #78909c !important;
  background-color: transparent !important;
  color: #78909c !important;
}
.default-theme .btn-outline-blue-grey:not([disabled]):not(.disabled):active, .default-theme .btn-outline-blue-grey:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-blue-grey.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #78909c !important;
}
.default-theme .btn-outline-blue-grey:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-blue-grey:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-blue-grey.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-dark {
  background-color: #212121 !important;
  color: #fff;
}
.default-theme .btn-dark:hover {
  background-color: #2e2e2e;
  color: #fff;
}
.default-theme .btn-dark:focus, .default-theme .btn-dark.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-dark:focus, .default-theme .btn-dark:active, .default-theme .btn-dark.active {
  background-color: black;
}
.default-theme .btn-dark.dropdown-toggle {
  background-color: #212121 !important;
}
.default-theme .btn-dark.dropdown-toggle:hover, .default-theme .btn-dark.dropdown-toggle:focus {
  background-color: #2e2e2e !important;
}
.default-theme .btn-dark:not([disabled]):not(.disabled):active, .default-theme .btn-dark:not([disabled]):not(.disabled).active, .show > .default-theme .btn-dark.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: black !important;
}
.default-theme .btn-dark:not([disabled]):not(.disabled):active:focus, .default-theme .btn-dark:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .dark-ic {
  color: #212121 !important;
}
.default-theme .dark-ic:hover, .default-theme .dark-ic:focus {
  color: #212121;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-dark {
  color: #fff;
}
.default-theme .text-dark {
  color: #212121 !important;
}
.default-theme a.text-dark:hover, .default-theme a.text-dark:focus {
  color: #080808 !important;
}
.default-theme .bg-dark {
  background-color: #212121 !important;
}
.default-theme a.bg-dark:hover, .default-theme a.bg-dark:focus,
.default-theme button.bg-dark:hover,
.default-theme button.bg-dark:focus {
  background-color: #080808 !important;
}
.default-theme .badge-bg-dark {
  background-color: #212121 !important;
}
.default-theme a.badge-bg-dark:hover, .default-theme a.badge-bg-dark:focus,
.default-theme button.badge-bg-dark:hover,
.default-theme button.badge-bg-dark:focus {
  background-color: #080808 !important;
}
.default-theme .btn-outline-dark {
  border: 2px solid #212121 !important;
  background-color: transparent !important;
  color: #212121 !important;
}
.default-theme .btn-outline-dark:hover, .default-theme .btn-outline-dark:focus, .default-theme .btn-outline-dark:active, .default-theme .btn-outline-dark:active:focus, .default-theme .btn-outline-dark.active {
  border-color: #212121 !important;
  background-color: transparent !important;
  color: #212121 !important;
}
.default-theme .btn-outline-dark:not([disabled]):not(.disabled):active, .default-theme .btn-outline-dark:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-dark.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #212121 !important;
}
.default-theme .btn-outline-dark:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-dark:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-light {
  background-color: #e0e0e0 !important;
  color: #000;
}
.default-theme .btn-light:hover {
  background-color: #ededed;
  color: #000;
}
.default-theme .btn-light:focus, .default-theme .btn-light.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-light:focus, .default-theme .btn-light:active, .default-theme .btn-light.active {
  background-color: #adadad;
}
.default-theme .btn-light.dropdown-toggle {
  background-color: #e0e0e0 !important;
}
.default-theme .btn-light.dropdown-toggle:hover, .default-theme .btn-light.dropdown-toggle:focus {
  background-color: #ededed !important;
}
.default-theme .btn-light:not([disabled]):not(.disabled):active, .default-theme .btn-light:not([disabled]):not(.disabled).active, .show > .default-theme .btn-light.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #adadad !important;
}
.default-theme .btn-light:not([disabled]):not(.disabled):active:focus, .default-theme .btn-light:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-light.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .light-ic {
  color: #e0e0e0 !important;
}
.default-theme .light-ic:hover, .default-theme .light-ic:focus {
  color: #e0e0e0;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #000;
}
.default-theme table.table a.btn.btn-light {
  color: #000;
}
.default-theme .text-light {
  color: #e0e0e0 !important;
}
.default-theme a.text-light:hover, .default-theme a.text-light:focus {
  color: #c7c7c7 !important;
}
.default-theme .bg-light {
  background-color: #e0e0e0 !important;
}
.default-theme a.bg-light:hover, .default-theme a.bg-light:focus,
.default-theme button.bg-light:hover,
.default-theme button.bg-light:focus {
  background-color: #c7c7c7 !important;
}
.default-theme .badge-bg-light {
  background-color: #e0e0e0 !important;
}
.default-theme a.badge-bg-light:hover, .default-theme a.badge-bg-light:focus,
.default-theme button.badge-bg-light:hover,
.default-theme button.badge-bg-light:focus {
  background-color: #c7c7c7 !important;
}
.default-theme .btn-outline-light {
  border: 2px solid #e0e0e0 !important;
  background-color: transparent !important;
  color: #e0e0e0 !important;
}
.default-theme .btn-outline-light:hover, .default-theme .btn-outline-light:focus, .default-theme .btn-outline-light:active, .default-theme .btn-outline-light:active:focus, .default-theme .btn-outline-light.active {
  border-color: #e0e0e0 !important;
  background-color: transparent !important;
  color: #e0e0e0 !important;
}
.default-theme .btn-outline-light:not([disabled]):not(.disabled):active, .default-theme .btn-outline-light:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-light.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #e0e0e0 !important;
}
.default-theme .btn-outline-light:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-light:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-white {
  background-color: #fff !important;
  color: #000;
}
.default-theme .btn-white:hover {
  background-color: white;
  color: #000;
}
.default-theme .btn-white:focus, .default-theme .btn-white.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-white:focus, .default-theme .btn-white:active, .default-theme .btn-white.active {
  background-color: #cccccc;
}
.default-theme .btn-white.dropdown-toggle {
  background-color: #fff !important;
}
.default-theme .btn-white.dropdown-toggle:hover, .default-theme .btn-white.dropdown-toggle:focus {
  background-color: white !important;
}
.default-theme .btn-white:not([disabled]):not(.disabled):active, .default-theme .btn-white:not([disabled]):not(.disabled).active, .show > .default-theme .btn-white.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #cccccc !important;
}
.default-theme .btn-white:not([disabled]):not(.disabled):active:focus, .default-theme .btn-white:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-white.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .white-ic {
  color: #fff !important;
}
.default-theme .white-ic:hover, .default-theme .white-ic:focus {
  color: #fff;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #000;
}
.default-theme table.table a.btn.btn-white {
  color: #000;
}
.default-theme .text-white {
  color: #fff !important;
}
.default-theme a.text-white:hover, .default-theme a.text-white:focus {
  color: #e6e6e6 !important;
}
.default-theme .bg-white {
  background-color: #fff !important;
}
.default-theme a.bg-white:hover, .default-theme a.bg-white:focus,
.default-theme button.bg-white:hover,
.default-theme button.bg-white:focus {
  background-color: #e6e6e6 !important;
}
.default-theme .badge-bg-white {
  background-color: #fff !important;
}
.default-theme a.badge-bg-white:hover, .default-theme a.badge-bg-white:focus,
.default-theme button.badge-bg-white:hover,
.default-theme button.badge-bg-white:focus {
  background-color: #e6e6e6 !important;
}
.default-theme .btn-outline-white {
  border: 2px solid #fff !important;
  background-color: transparent !important;
  color: #fff !important;
}
.default-theme .btn-outline-white:hover, .default-theme .btn-outline-white:focus, .default-theme .btn-outline-white:active, .default-theme .btn-outline-white:active:focus, .default-theme .btn-outline-white.active {
  border-color: #fff !important;
  background-color: transparent !important;
  color: #fff !important;
}
.default-theme .btn-outline-white:not([disabled]):not(.disabled):active, .default-theme .btn-outline-white:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-white.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #fff !important;
}
.default-theme .btn-outline-white:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-white:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-black {
  background-color: #000 !important;
  color: #fff;
}
.default-theme .btn-black:hover {
  background-color: #0d0d0d;
  color: #fff;
}
.default-theme .btn-black:focus, .default-theme .btn-black.focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn-black:focus, .default-theme .btn-black:active, .default-theme .btn-black.active {
  background-color: black;
}
.default-theme .btn-black.dropdown-toggle {
  background-color: #000 !important;
}
.default-theme .btn-black.dropdown-toggle:hover, .default-theme .btn-black.dropdown-toggle:focus {
  background-color: #0d0d0d !important;
}
.default-theme .btn-black:not([disabled]):not(.disabled):active, .default-theme .btn-black:not([disabled]):not(.disabled).active, .show > .default-theme .btn-black.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: black !important;
}
.default-theme .btn-black:not([disabled]):not(.disabled):active:focus, .default-theme .btn-black:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-black.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .black-ic {
  color: #000 !important;
}
.default-theme .black-ic:hover, .default-theme .black-ic:focus {
  color: #000;
}
.default-theme a.btn:not([href]):not([tabindex]),
.default-theme a.btn:not([href]):not([tabindex]):focus,
.default-theme a.btn:not([href]):not([tabindex]):hover {
  color: #fff;
}
.default-theme table.table a.btn.btn-black {
  color: #fff;
}
.default-theme .text-black {
  color: #000 !important;
}
.default-theme a.text-black:hover, .default-theme a.text-black:focus {
  color: black !important;
}
.default-theme .bg-black {
  background-color: #000 !important;
}
.default-theme a.bg-black:hover, .default-theme a.bg-black:focus,
.default-theme button.bg-black:hover,
.default-theme button.bg-black:focus {
  background-color: black !important;
}
.default-theme .badge-bg-black {
  background-color: #000 !important;
}
.default-theme a.badge-bg-black:hover, .default-theme a.badge-bg-black:focus,
.default-theme button.badge-bg-black:hover,
.default-theme button.badge-bg-black:focus {
  background-color: black !important;
}
.default-theme .btn-outline-black {
  border: 2px solid #000 !important;
  background-color: transparent !important;
  color: #000 !important;
}
.default-theme .btn-outline-black:hover, .default-theme .btn-outline-black:focus, .default-theme .btn-outline-black:active, .default-theme .btn-outline-black:active:focus, .default-theme .btn-outline-black.active {
  border-color: #000 !important;
  background-color: transparent !important;
  color: #000 !important;
}
.default-theme .btn-outline-black:not([disabled]):not(.disabled):active, .default-theme .btn-outline-black:not([disabled]):not(.disabled).active, .show > .default-theme .btn-outline-black.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: transparent !important;
  border-color: #000 !important;
}
.default-theme .btn-outline-black:not([disabled]):not(.disabled):active:focus, .default-theme .btn-outline-black:not([disabled]):not(.disabled).active:focus, .show > .default-theme .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.default-theme .btn {
  box-shadow: none;
}
.default-theme .status-primary {
  background-color: #bad3fb !important;
  border: #4285f4;
  color: #0d5bdd !important;
}
.default-theme .status-danger {
  background-color: #ff4d4d !important;
  border: #cc0000;
  color: maroon !important;
}
.default-theme .status-default {
  background-color: #83e2d9 !important;
  border: #2bbbad;
  color: #1d7d73 !important;
}
.default-theme .status-secondary {
  background-color: #dfc6ec !important;
  border: #a6c;
  color: #8639ac !important;
}
.default-theme .status-success {
  background-color: #49ff92 !important;
  border: #00c851;
  color: #007c32 !important;
}
.default-theme .status-info {
  background-color: #a4def3 !important;
  border: #33b5e5;
  color: #178ab4 !important;
}
.default-theme .status-warning {
  background-color: #ffe6b3 !important;
  border: #ffbb33;
  color: #e69900 !important;
}
.default-theme .status-dark {
  background-color: #6e6e6e !important;
  border: #2e2e2e;
  color: #080808 !important;
}
.default-theme .status-ins {
  background-color: #689ecb !important;
  border: #2e5e86;
  color: #1a364d !important;
}
.default-theme .btn-icon {
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #f6f6f6;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.default-theme .btn-icon.transparent {
  background-color: transparent;
}
.default-theme .btn-icon.border {
  border: 1px solid #cccccc;
  background-color: transparent;
}
.default-theme .btn-icon i {
  margin: 0;
  padding: 0;
  font-size: 1.3em;
}
.default-theme .btn-icon.circle {
  border-radius: 50%;
}
.default-theme .btn-icon.circle i {
  font-size: 1.3em;
  line-height: 1.9;
}
.default-theme .panel {
  box-shadow: 0 10px 15px 0 #e9ecee;
  background-color: #fff;
  margin-bottom: 1rem;
  border-radius: 5px;
  overflow: hidden;
}
.default-theme .panel .panel-header {
  border-bottom: 1px solid #f6f6f6;
  padding: 1rem;
  overflow: hidden;
}
.default-theme .panel .panel-header.primary-color {
  background-color: #4285f4;
  color: #fff;
}
.default-theme .panel .panel-header.primary-color-dark {
  background-color: #0d47a1;
  color: #fff;
}
.default-theme .panel .panel-header.secondary-color {
  background-color: #a6c;
  color: #fff;
}
.default-theme .panel .panel-header.secondary-color-dark {
  background-color: #9933cc;
  color: #fff;
}
.default-theme .panel .panel-header.default-color {
  background-color: #2bbbad;
  color: #fff;
}
.default-theme .panel .panel-header.default-color-dark {
  background-color: #00695c;
  color: #fff;
}
.default-theme .panel .panel-header.info-color {
  background-color: #33b5e5;
  color: #fff;
}
.default-theme .panel .panel-header.info-color-dark {
  background-color: #0099cc;
  color: #fff;
}
.default-theme .panel .panel-header.success-color {
  background-color: #00c851;
  color: #fff;
}
.default-theme .panel .panel-header.success-color-dark {
  background-color: #007e33;
  color: #fff;
}
.default-theme .panel .panel-header.warning-color {
  background-color: #ffbb33;
  color: #fff;
}
.default-theme .panel .panel-header.warning-color-dark {
  background-color: #ff8800;
  color: #fff;
}
.default-theme .panel .panel-header.danger-color {
  background-color: #ff3547;
  color: #fff;
}
.default-theme .panel .panel-header.danger-color-dark {
  background-color: #cc0000;
  color: #fff;
}
.default-theme .panel .panel-header.elegant-color {
  background-color: #2e2e2e;
  color: #fff;
}
.default-theme .panel .panel-header.elegant-color-dark {
  background-color: #212121;
  color: #fff;
}
.default-theme .panel .panel-header.stylish-color {
  background-color: #4b515d;
  color: #fff;
}
.default-theme .panel .panel-header.stylish-color-dark {
  background-color: #3e4551;
  color: #fff;
}
.default-theme .panel .panel-header.unique-color {
  background-color: #3f729b;
  color: #fff;
}
.default-theme .panel .panel-header.unique-color-dark {
  background-color: #1c2331;
  color: #fff;
}
.default-theme .panel .panel-header.special-color {
  background-color: #37474f;
  color: #fff;
}
.default-theme .panel .panel-header.special-color-dark {
  background-color: #263238;
  color: #fff;
}
.default-theme .panel .panel-header h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}
.default-theme .panel .panel-body {
  padding: 1rem;
}
.default-theme .panel .panel-footer {
  padding: 1rem;
  overflow: hidden;
}
.default-theme .classic-tabs .nav {
  white-space: nowrap;
  overflow-x: auto;
  position: relative;
  border-radius: 0.3rem 0.3rem 0 0;
}
@media (min-width: 62rem) {
  .default-theme .classic-tabs .nav {
    overflow-x: hidden;
  }
}
.default-theme .classic-tabs .nav li a {
  display: block;
  padding: 20px 24px;
  font-size: 13px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  border-radius: 0;
}
.default-theme .classic-tabs .nav li a:not(.active) {
  margin-bottom: 1px;
}
.default-theme .classic-tabs .nav li a.active {
  border-bottom: 1px solid #ededed;
  color: #2b373d;
}
@media (min-width: 62em) {
  .default-theme .classic-tabs .nav li:first-child {
    margin-left: 0;
  }
  .default-theme .classic-tabs .nav li:last-child {
    margin-right: 0;
  }
}
.default-theme .classic-tabs .nav.primary-color li a.active {
  border-color: #4285f4;
}
.default-theme .classic-tabs .nav.primary-color-dark li a.active {
  border-color: #0d47a1;
}
.default-theme .classic-tabs .nav.secondary-color li a.active {
  border-color: #a6c;
}
.default-theme .classic-tabs .nav.secondary-color-dark li a.active {
  border-color: #9933cc;
}
.default-theme .classic-tabs .nav.default-color li a.active {
  border-color: #2bbbad;
}
.default-theme .classic-tabs .nav.default-color-dark li a.active {
  border-color: #00695c;
}
.default-theme .classic-tabs .nav.info-color li a.active {
  border-color: #33b5e5;
}
.default-theme .classic-tabs .nav.info-color-dark li a.active {
  border-color: #0099cc;
}
.default-theme .classic-tabs .nav.success-color li a.active {
  border-color: #00c851;
}
.default-theme .classic-tabs .nav.success-color-dark li a.active {
  border-color: #007e33;
}
.default-theme .classic-tabs .nav.warning-color li a.active {
  border-color: #ffbb33;
}
.default-theme .classic-tabs .nav.warning-color-dark li a.active {
  border-color: #ff8800;
}
.default-theme .classic-tabs .nav.danger-color li a.active {
  border-color: #ff3547;
}
.default-theme .classic-tabs .nav.danger-color-dark li a.active {
  border-color: #cc0000;
}
.default-theme .classic-tabs .nav.elegant-color li a.active {
  border-color: #2e2e2e;
}
.default-theme .classic-tabs .nav.elegant-color-dark li a.active {
  border-color: #212121;
}
.default-theme .classic-tabs .nav.stylish-color li a.active {
  border-color: #4b515d;
}
.default-theme .classic-tabs .nav.stylish-color-dark li a.active {
  border-color: #3e4551;
}
.default-theme .classic-tabs .nav.unique-color li a.active {
  border-color: #3f729b;
}
.default-theme .classic-tabs .nav.unique-color-dark li a.active {
  border-color: #1c2331;
}
.default-theme .classic-tabs .nav.special-color li a.active {
  border-color: #37474f;
}
.default-theme .classic-tabs .nav.special-color-dark li a.active {
  border-color: #263238;
}
.default-theme .classic-tabs .nav.tabs-animated li a.active {
  border: none;
}
.default-theme .classic-tabs .nav.tabs-animated.primary-color .floor {
  background-color: #4285f4;
}
.default-theme .classic-tabs .nav.tabs-animated.primary-color-dark .floor {
  background-color: #0d47a1;
}
.default-theme .classic-tabs .nav.tabs-animated.secondary-color .floor {
  background-color: #a6c;
}
.default-theme .classic-tabs .nav.tabs-animated.secondary-color-dark .floor {
  background-color: #9933cc;
}
.default-theme .classic-tabs .nav.tabs-animated.default-color .floor {
  background-color: #2bbbad;
}
.default-theme .classic-tabs .nav.tabs-animated.default-color-dark .floor {
  background-color: #00695c;
}
.default-theme .classic-tabs .nav.tabs-animated.info-color .floor {
  background-color: #33b5e5;
}
.default-theme .classic-tabs .nav.tabs-animated.info-color-dark .floor {
  background-color: #0099cc;
}
.default-theme .classic-tabs .nav.tabs-animated.success-color .floor {
  background-color: #00c851;
}
.default-theme .classic-tabs .nav.tabs-animated.success-color-dark .floor {
  background-color: #007e33;
}
.default-theme .classic-tabs .nav.tabs-animated.warning-color .floor {
  background-color: #ffbb33;
}
.default-theme .classic-tabs .nav.tabs-animated.warning-color-dark .floor {
  background-color: #ff8800;
}
.default-theme .classic-tabs .nav.tabs-animated.danger-color .floor {
  background-color: #ff3547;
}
.default-theme .classic-tabs .nav.tabs-animated.danger-color-dark .floor {
  background-color: #cc0000;
}
.default-theme .classic-tabs .nav.tabs-animated.elegant-color .floor {
  background-color: #2e2e2e;
}
.default-theme .classic-tabs .nav.tabs-animated.elegant-color-dark .floor {
  background-color: #212121;
}
.default-theme .classic-tabs .nav.tabs-animated.stylish-color .floor {
  background-color: #4b515d;
}
.default-theme .classic-tabs .nav.tabs-animated.stylish-color-dark .floor {
  background-color: #3e4551;
}
.default-theme .classic-tabs .nav.tabs-animated.unique-color .floor {
  background-color: #3f729b;
}
.default-theme .classic-tabs .nav.tabs-animated.unique-color-dark .floor {
  background-color: #1c2331;
}
.default-theme .classic-tabs .nav.tabs-animated.special-color .floor {
  background-color: #37474f;
}
.default-theme .classic-tabs .nav.tabs-animated.special-color-dark .floor {
  background-color: #263238;
}
.default-theme .classic-tabs .nav.tabs-animated .floor {
  display: inline-block;
  width: 30px;
  height: 3px;
  position: absolute;
  z-index: 1200;
  bottom: 0;
  transition: all 0.4s linear;
}
.default-theme .classic-tabs .tab-content {
  padding: 1rem;
}
.default-theme .classic-tabs .tab-content.card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.default-theme .pills-primary-color .show > .nav-link {
  background-color: #4285f4 !important;
}
.default-theme .pills-primary-color .nav-link.active {
  background-color: #4285f4 !important;
}
.default-theme .tabs-primary-color {
  background-color: #4285f4 !important;
}
.default-theme mdb-tabset ul.tabs-outline-primary-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-primary-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-primary-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-primary-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-primary-color li.active a span {
  color: #4285f4;
}
.default-theme mdb-tabset ul.tabs-outline-primary-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-primary-color-dark .show > .nav-link {
  background-color: #0d47a1 !important;
}
.default-theme .pills-primary-color-dark .nav-link.active {
  background-color: #0d47a1 !important;
}
.default-theme .tabs-primary-color-dark {
  background-color: #0d47a1 !important;
}
.default-theme mdb-tabset ul.tabs-outline-primary-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-primary-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-primary-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-primary-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-primary-color-dark li.active a span {
  color: #0d47a1;
}
.default-theme mdb-tabset ul.tabs-outline-primary-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-secondary-color .show > .nav-link {
  background-color: #a6c !important;
}
.default-theme .pills-secondary-color .nav-link.active {
  background-color: #a6c !important;
}
.default-theme .tabs-secondary-color {
  background-color: #a6c !important;
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color li.active a span {
  color: #a6c;
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-secondary-color-dark .show > .nav-link {
  background-color: #9933cc !important;
}
.default-theme .pills-secondary-color-dark .nav-link.active {
  background-color: #9933cc !important;
}
.default-theme .tabs-secondary-color-dark {
  background-color: #9933cc !important;
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color-dark li.active a span {
  color: #9933cc;
}
.default-theme mdb-tabset ul.tabs-outline-secondary-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-default-color .show > .nav-link {
  background-color: #2bbbad !important;
}
.default-theme .pills-default-color .nav-link.active {
  background-color: #2bbbad !important;
}
.default-theme .tabs-default-color {
  background-color: #2bbbad !important;
}
.default-theme mdb-tabset ul.tabs-outline-default-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-default-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-default-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-default-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-default-color li.active a span {
  color: #2bbbad;
}
.default-theme mdb-tabset ul.tabs-outline-default-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-default-color-dark .show > .nav-link {
  background-color: #00695c !important;
}
.default-theme .pills-default-color-dark .nav-link.active {
  background-color: #00695c !important;
}
.default-theme .tabs-default-color-dark {
  background-color: #00695c !important;
}
.default-theme mdb-tabset ul.tabs-outline-default-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-default-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-default-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-default-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-default-color-dark li.active a span {
  color: #00695c;
}
.default-theme mdb-tabset ul.tabs-outline-default-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-info-color .show > .nav-link {
  background-color: #33b5e5 !important;
}
.default-theme .pills-info-color .nav-link.active {
  background-color: #33b5e5 !important;
}
.default-theme .tabs-info-color {
  background-color: #33b5e5 !important;
}
.default-theme mdb-tabset ul.tabs-outline-info-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-info-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-info-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-info-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-info-color li.active a span {
  color: #33b5e5;
}
.default-theme mdb-tabset ul.tabs-outline-info-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-info-color-dark .show > .nav-link {
  background-color: #0099cc !important;
}
.default-theme .pills-info-color-dark .nav-link.active {
  background-color: #0099cc !important;
}
.default-theme .tabs-info-color-dark {
  background-color: #0099cc !important;
}
.default-theme mdb-tabset ul.tabs-outline-info-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-info-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-info-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-info-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-info-color-dark li.active a span {
  color: #0099cc;
}
.default-theme mdb-tabset ul.tabs-outline-info-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-success-color .show > .nav-link {
  background-color: #00c851 !important;
}
.default-theme .pills-success-color .nav-link.active {
  background-color: #00c851 !important;
}
.default-theme .tabs-success-color {
  background-color: #00c851 !important;
}
.default-theme mdb-tabset ul.tabs-outline-success-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-success-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-success-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-success-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-success-color li.active a span {
  color: #00c851;
}
.default-theme mdb-tabset ul.tabs-outline-success-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-success-color-dark .show > .nav-link {
  background-color: #007e33 !important;
}
.default-theme .pills-success-color-dark .nav-link.active {
  background-color: #007e33 !important;
}
.default-theme .tabs-success-color-dark {
  background-color: #007e33 !important;
}
.default-theme mdb-tabset ul.tabs-outline-success-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-success-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-success-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-success-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-success-color-dark li.active a span {
  color: #007e33;
}
.default-theme mdb-tabset ul.tabs-outline-success-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-warning-color .show > .nav-link {
  background-color: #ffbb33 !important;
}
.default-theme .pills-warning-color .nav-link.active {
  background-color: #ffbb33 !important;
}
.default-theme .tabs-warning-color {
  background-color: #ffbb33 !important;
}
.default-theme mdb-tabset ul.tabs-outline-warning-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-warning-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-warning-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-warning-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-warning-color li.active a span {
  color: #ffbb33;
}
.default-theme mdb-tabset ul.tabs-outline-warning-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-warning-color-dark .show > .nav-link {
  background-color: #ff8800 !important;
}
.default-theme .pills-warning-color-dark .nav-link.active {
  background-color: #ff8800 !important;
}
.default-theme .tabs-warning-color-dark {
  background-color: #ff8800 !important;
}
.default-theme mdb-tabset ul.tabs-outline-warning-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-warning-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-warning-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-warning-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-warning-color-dark li.active a span {
  color: #ff8800;
}
.default-theme mdb-tabset ul.tabs-outline-warning-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-danger-color .show > .nav-link {
  background-color: #ff3547 !important;
}
.default-theme .pills-danger-color .nav-link.active {
  background-color: #ff3547 !important;
}
.default-theme .tabs-danger-color {
  background-color: #ff3547 !important;
}
.default-theme mdb-tabset ul.tabs-outline-danger-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-danger-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-danger-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-danger-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-danger-color li.active a span {
  color: #ff3547;
}
.default-theme mdb-tabset ul.tabs-outline-danger-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-danger-color-dark .show > .nav-link {
  background-color: #cc0000 !important;
}
.default-theme .pills-danger-color-dark .nav-link.active {
  background-color: #cc0000 !important;
}
.default-theme .tabs-danger-color-dark {
  background-color: #cc0000 !important;
}
.default-theme mdb-tabset ul.tabs-outline-danger-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-danger-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-danger-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-danger-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-danger-color-dark li.active a span {
  color: #cc0000;
}
.default-theme mdb-tabset ul.tabs-outline-danger-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-elegant-color .show > .nav-link {
  background-color: #2e2e2e !important;
}
.default-theme .pills-elegant-color .nav-link.active {
  background-color: #2e2e2e !important;
}
.default-theme .tabs-elegant-color {
  background-color: #2e2e2e !important;
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color li.active a span {
  color: #2e2e2e;
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-elegant-color-dark .show > .nav-link {
  background-color: #212121 !important;
}
.default-theme .pills-elegant-color-dark .nav-link.active {
  background-color: #212121 !important;
}
.default-theme .tabs-elegant-color-dark {
  background-color: #212121 !important;
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color-dark li.active a span {
  color: #212121;
}
.default-theme mdb-tabset ul.tabs-outline-elegant-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-stylish-color .show > .nav-link {
  background-color: #4b515d !important;
}
.default-theme .pills-stylish-color .nav-link.active {
  background-color: #4b515d !important;
}
.default-theme .tabs-stylish-color {
  background-color: #4b515d !important;
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color li.active a span {
  color: #4b515d;
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-stylish-color-dark .show > .nav-link {
  background-color: #3e4551 !important;
}
.default-theme .pills-stylish-color-dark .nav-link.active {
  background-color: #3e4551 !important;
}
.default-theme .tabs-stylish-color-dark {
  background-color: #3e4551 !important;
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color-dark li.active a span {
  color: #3e4551;
}
.default-theme mdb-tabset ul.tabs-outline-stylish-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-unique-color .show > .nav-link {
  background-color: #3f729b !important;
}
.default-theme .pills-unique-color .nav-link.active {
  background-color: #3f729b !important;
}
.default-theme .tabs-unique-color {
  background-color: #3f729b !important;
}
.default-theme mdb-tabset ul.tabs-outline-unique-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-unique-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-unique-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-unique-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-unique-color li.active a span {
  color: #3f729b;
}
.default-theme mdb-tabset ul.tabs-outline-unique-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-unique-color-dark .show > .nav-link {
  background-color: #1c2331 !important;
}
.default-theme .pills-unique-color-dark .nav-link.active {
  background-color: #1c2331 !important;
}
.default-theme .tabs-unique-color-dark {
  background-color: #1c2331 !important;
}
.default-theme mdb-tabset ul.tabs-outline-unique-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-unique-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-unique-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-unique-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-unique-color-dark li.active a span {
  color: #1c2331;
}
.default-theme mdb-tabset ul.tabs-outline-unique-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-special-color .show > .nav-link {
  background-color: #37474f !important;
}
.default-theme .pills-special-color .nav-link.active {
  background-color: #37474f !important;
}
.default-theme .tabs-special-color {
  background-color: #37474f !important;
}
.default-theme mdb-tabset ul.tabs-outline-special-color {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-special-color li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-special-color li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-special-color li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-special-color li.active a span {
  color: #37474f;
}
.default-theme mdb-tabset ul.tabs-outline-special-color li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .pills-special-color-dark .show > .nav-link {
  background-color: #263238 !important;
}
.default-theme .pills-special-color-dark .nav-link.active {
  background-color: #263238 !important;
}
.default-theme .tabs-special-color-dark {
  background-color: #263238 !important;
}
.default-theme mdb-tabset ul.tabs-outline-special-color-dark {
  box-shadow: inset 0px -1px 0 #f6f6f6;
  background-color: #fff;
}
.default-theme mdb-tabset ul.tabs-outline-special-color-dark li a {
  cursor: pointer;
}
.default-theme mdb-tabset ul.tabs-outline-special-color-dark li a span {
  color: var(--dynamic-secondary-colour);
}
.default-theme mdb-tabset ul.tabs-outline-special-color-dark li a span.t-number {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  color: #fff;
  margin-right: 0.5rem;
  display: inline-block;
  border-radius: 10px;
}
.default-theme mdb-tabset ul.tabs-outline-special-color-dark li.active a span {
  color: #263238;
}
.default-theme mdb-tabset ul.tabs-outline-special-color-dark li.active a span.t-number {
  color: #fff;
  background-color: var(--dynamic-colour);
}
.default-theme mdb-tabset .tab-content {
  background: #fff;
  box-shadow: none;
}
.default-theme mdb-tabset .tab-content.tab-shadow {
  box-shadow: 0px 10px 15px 0px #e9ecee;
}
.default-theme .mdb-accordion-indicator {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Line Awesome Free";
  font-weight: 900;
}
.default-theme .mdb-accordion-indicator::after {
  display: none !important;
}
.default-theme .mdb-accordion-indicator::before {
  content: "\f107";
}
.default-theme .status-danger,
.default-theme .status-dark {
  color: #fff !important;
}
.default-theme .badge {
  box-shadow: none;
  border-radius: 0.125rem;
  padding: 0.3rem 0.5rem;
  vertical-align: middle;
  border-radius: 4px;
}
.default-theme .popover {
  box-shadow: 0px 12px 15px 8px rgba(0, 0, 0, 0.1607843137);
  border: none;
  border-radius: 5px;
  animation-delay: 0.3s;
  max-width: 550px;
  top: -20px !important;
}
.default-theme .popover:before {
  border: none;
}
.default-theme .popover:after {
  border: none;
}
.default-theme .popover .popover-body {
  border-left: 8px solid var(--dynamic-colour);
  border-radius: 5px;
  overflow: hidden;
}
.default-theme .popover .popover-body ul {
  padding-left: 1.2rem;
}
.default-theme .popover .popover-body .popover-close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--dynamic-colour);
  cursor: pointer;
}
.default-theme .popover .popover-body .popover-close-red {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 16px;
  color: #fff;
  background-color: #c73230;
  cursor: pointer;
  font-size: 10px;
  width: 16px;
  height: 16px;
  line-height: 17px;
  text-align: center;
}
.default-theme .popover ul.link-list li {
  border-bottom: 1px solid #f2f3f8;
  padding: 0.2em 0.4rem;
}
.default-theme .popover ul.link-list li:hover {
  background: #f6f6f6;
  transition: all ease-in 0.3s;
}
.default-theme .popover ul.link-list li.active {
  background: var(--dynamic-secondary-colour);
}
.default-theme .popover ul.link-list li.active a {
  color: #fff !important;
}
.default-theme .dropdown-menu-right {
  right: 0;
  left: auto !important;
}

a.link {
  color: var(--dynamic-colour);
  font-size: 0.75rem;
  display: inline-block;
}

.signal-state {
  position: absolute;
  right: -8px;
  top: 5px;
  z-index: 999999;
}

.pac-container.pac-logo.hdpi {
  z-index: 20000 !important;
}

.signal-state span {
  width: 20px;
  height: 20px;
  line-height: 20px;
  min-width: 20px;
  min-height: 10px;
  border-radius: 10px;
  display: inline-block;
  font-size: 9px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  position: relative;
  z-index: 99999999;
  cursor: pointer;
}
.signal-state span.state-primary {
  background-color: #4285f4;
}
.signal-state span.state-danger {
  background-color: #cc0000;
}
.signal-state span.state-default {
  background-color: #2bbbad;
}
.signal-state span.state-secondary {
  background-color: #a6c;
}
.signal-state span.state-success {
  background-color: #00c851;
}
.signal-state span.state-info {
  background-color: #33b5e5;
}
.signal-state span.state-warning {
  background-color: #ffbb33;
}
.signal-state span.state-dark {
  background-color: #2e2e2e;
}
.signal-state span.state-ins {
  background-color: #2e5e86;
}

:focus {
  outline: none;
}

a {
  color: var(--dynamic-colour);
  transition: none;
}

.font-400 {
  font-weight: 400 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.font-900 {
  font-weight: 900 !important;
}

.text-box-primary {
  background-color: #a2c3fa;
  color: #fff;
}

.fill-primary {
  fill: #4285f4;
}

.text-box-danger {
  background-color: #ff9ba4;
  color: #fff;
}

.fill-danger {
  fill: #ff3547;
}

.text-box-warning {
  background-color: #ffdd99;
  color: #fff;
}

.fill-warning {
  fill: #ffbb33;
}

.text-box-success {
  background-color: #2fff83;
  color: #fff;
}

.fill-success {
  fill: #00c851;
}

.text-box-info {
  background-color: #8dd6f1;
  color: #fff;
}

.fill-info {
  fill: #33b5e5;
}

.text-box-default {
  background-color: #6eded3;
  color: #fff;
}

.fill-default {
  fill: #2bbbad;
}

.text-box-secondary {
  background-color: #d5b3e6;
  color: #fff;
}

.fill-secondary {
  fill: #a6c;
}

.text-box-elegant {
  background-color: #616161;
  color: #fff;
}

.fill-elegant {
  fill: #2e2e2e;
}

.text-box-unique {
  background-color: #e41885;
  color: #fff;
}

.fill-unique {
  fill: #880e4f;
}

.text-box-dark-green {
  background-color: #68c46d;
  color: #fff;
}

.fill-dark-green {
  fill: #388e3c;
}

.text-box-mdb-color {
  background-color: #929eba;
  color: #fff;
}

.fill-mdb-color {
  fill: #59698d;
}

.text-box-red {
  background-color: #e58383;
  color: #fff;
}

.fill-red {
  fill: #d32f2f;
}

.text-box-pink {
  background-color: #f59dbb;
  color: #fff;
}

.fill-pink {
  fill: #ec407a;
}

.text-box-purple {
  background-color: #c058dc;
  color: #fff;
}

.fill-purple {
  fill: #8e24aa;
}

.text-box-deep-purple {
  background-color: #8665d6;
  color: #fff;
}

.fill-deep-purple {
  fill: #512da8;
}

.text-box-indigo {
  background-color: #8591d5;
  color: #fff;
}

.fill-indigo {
  fill: #3f51b5;
}

.text-box-blue {
  background-color: #64a9ed;
  color: #fff;
}

.fill-blue {
  fill: #1976d2;
}

.text-box-light-blue {
  background-color: #e8f1ff;
  color: #fff;
}

.fill-light-blue {
  fill: #82b1ff;
}

.text-box-cyan {
  background-color: #3be9ff;
  color: #fff;
}

.fill-cyan {
  fill: #00bcd4;
}

.text-box-teal {
  background-color: #00dfc5;
  color: #fff;
}

.fill-teal {
  fill: #00796b;
}

.text-box-green {
  background-color: #68c46d;
  color: #fff;
}

.fill-green {
  fill: #388e3c;
}

.text-box-light-green {
  background-color: #bcdc97;
  color: #fff;
}

.fill-light-green {
  fill: #8bc34a;
}

.text-box-lime {
  background-color: #d7db6a;
  color: #fff;
}

.fill-lime {
  fill: #afb42b;
}

.text-box-yellow {
  background-color: #fdde91;
  color: #fff;
}

.fill-yellow {
  fill: #fbc02d;
}

.text-box-amber {
  background-color: #ffc666;
  color: #fff;
}

.fill-amber {
  fill: #ffa000;
}

.text-box-orange {
  background-color: #ffae5c;
  color: #fff;
}

.fill-orange {
  fill: #f57c00;
}

.text-box-deep-orange {
  background-color: #ffbea9;
  color: #fff;
}

.fill-deep-orange {
  fill: #ff7043;
}

.text-box-brown {
  background-color: #af8778;
  color: #fff;
}

.fill-brown {
  fill: #795548;
}

.text-box-grey {
  background-color: #949494;
  color: #fff;
}

.fill-grey {
  fill: #616161;
}

.text-box-blue-grey {
  background-color: #b3c0c7;
  color: #fff;
}

.fill-blue-grey {
  fill: #78909c;
}

.text-box-dark {
  background-color: #545454;
  color: #fff;
}

.fill-dark {
  fill: #212121;
}

.text-box-light {
  background-color: white;
  color: #fff;
}

.fill-light {
  fill: #e0e0e0;
}

.text-box-white {
  background-color: white;
  color: #fff;
}

.fill-white {
  fill: #fff;
}

.text-box-black {
  background-color: #333333;
  color: #fff;
}

.fill-black {
  fill: #000;
}

.response-page {
  display: flex;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.response-page div {
  margin: 0 auto;
}
.response-page div h1 {
  font-size: 65px;
  font-weight: 600;
  color: var(--dynamic-colour);
}
.response-page div h2 {
  font-size: 35px;
  font-weight: 600;
  color: var(--dynamic-secondary-colour);
}

.page-body {
  padding-top: 4.5rem;
}

.widget-block {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.widget-block [class*=block-] {
  background: white;
  padding: 1rem;
  margin-bottom: 2rem;
}
.widget-block [class*=block-] i {
  font-size: 3.5rem;
}
.widget-block [class*=block-] i::before {
  color: #cccccc;
}
.widget-block [class*=block-] .title {
  color: #999999;
}
.widget-block [class*=block-] h1 {
  color: var(--dynamic-colour);
  font-weight: 900;
  font-size: 4rem;
  color: var(--dynamic-colour);
}

strong {
  font-weight: 500 !important;
}

@media (min-width: 1200px) {
  .fixed-sn main,
  .fixed-sn .page-footer .container-fluid {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
}
h1.page-title, h1.title {
  font-size: 1.35rem;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #2b373d;
}

.btn {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  box-shadow: none;
  border-radius: 4px;
  text-transform: uppercase;
}
.btn:hover {
  box-shadow: none;
}
.btn.btn-md, .btn[class*=btn-outline-].btn-md {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}
.btn.btn-sm {
  padding: 0.5rem 1rem !important;
  font-size: 0.65rem;
}
.btn.btn-xs {
  padding: 0.5rem 1rem !important;
  font-size: 0.65rem;
}

body {
  background: #ededed !important;
  background-color: #ededed !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  position: relative;
  font-size: 14px;
}

.la {
  font-size: 20px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  margin-bottom: 5px;
  line-height: 24px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--dynamic-colour) !important;
  border: 1px solid var(--dynamic-colour) !important;
  background-color: var(--dynamic-colour) !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: var(--dynamic-colour) !important;
  border: 1px solid var(--dynamic-colour) !important;
  box-shadow: none;
}

.form-check-input[type=radio]:checked + label:after,
.form-check-input[type=radio].with-gap:checked + label:after,
label.btn input[type=radio]:checked + label:after,
label.btn input[type=radio].with-gap:checked + label:after {
  border-color: var(--dynamic-colour) !important;
  background-color: var(--dynamic-colour) !important;
}

form .form-check-inline {
  margin-top: 0.5rem;
}
form .form-group {
  margin-bottom: 1.2 rem;
}
form select {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}
form select option {
  font-family: "Roboto", sans-serif;
}
form label {
  font-size: 0.9rem;
  color: #707070;
  margin-bottom: 5px;
}
form .form-control {
  font-size: 0.9rem;
}
form .form-control:not([readonly]):focus {
  border-color: var(--dynamic-colour);
  box-shadow: none;
}
form .form-control.is-invalid {
  background-image: none;
}
form .form-control.is-invalid:focus {
  box-shadow: none;
}

.form-group {
  margin-bottom: 0.75rem;
}

.form-control,
.ng-select {
  font-size: 0.9rem;
}
.form-control[readonly],
.ng-select[readonly] {
  box-shadow: none;
  border: 1px solid #ced4da;
}
.form-control:not([readonly]):focus,
.form-control .ng-select-focused:focus,
.ng-select:not([readonly]):focus,
.ng-select .ng-select-focused:focus {
  border-color: var(--dynamic-colour);
  box-shadow: none;
}
.form-control.is-invalid,
.ng-select.is-invalid {
  background-image: none;
}
.form-control.is-invalid:focus,
.ng-select.is-invalid:focus {
  box-shadow: none;
}
.form-control.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: var(--dynamic-colour);
  box-shadow: none;
}
.form-control.ng-select-small .ng-select-container,
.ng-select.ng-select-small .ng-select-container {
  min-height: calc(1.5em + 0.5rem + 2px) !important;
  height: auto !important;
}
.form-control.form-control-sm,
.ng-select.form-control-sm {
  height: calc(1.52em + 0.5rem + 2px);
}

.ng-select-small .ng-select-container {
  min-height: calc(1.5em + 0.5rem + 2px) !important;
  height: auto !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding-bottom: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: var(--dynamic-colour);
  color: #fff;
}
.ng-select.is-invalid .ng-select-container {
  border: 1px solid #c73230;
}

.dropdown.icon-dropdown a:after {
  display: none;
}
.dropdown .dropdown-menu {
  border: none;
  box-shadow: 0 2px 14px 0 rgba(63, 81, 181, 0.19);
  top: -20px;
}
.dropdown .dropdown-menu a.dropdown-item:hover {
  background-color: transparent !important;
  box-shadow: none !important;
  color: var(--dynamic-colour) !important;
}
.dropdown .dropdown-menu a.dropdown-item.active {
  background-color: transparent !important;
}
.dropdown .dropdown-menu button.dropdown-item:hover {
  background-color: transparent !important;
  box-shadow: none !important;
  color: var(--dynamic-colour) !important;
}
.dropdown .dropdown-menu button.dropdown-item.active {
  background-color: transparent !important;
}

.collapse-hide-w {
  padding-left: 20px;
  text-transform: uppercase;
}

header mdb-side-nav .facility-info,
header .side-nav-links .facility-info {
  border-bottom: 1px solid var(--dynamic-sidenav);
  padding: 0 0 0 0;
  display: flex;
  align-items: center;
}
header mdb-side-nav .facility-info p,
header .side-nav-links .facility-info p {
  color: #2b373d;
}
header mdb-side-nav .facility-info strong,
header .side-nav-links .facility-info strong {
  color: #2b373d;
}
header mdb-side-nav .facility-info .facility-icon,
header .side-nav-links .facility-info .facility-icon {
  width: 40px;
  background: #ededed;
  height: 40px;
  text-align: center;
  margin-right: 1rem;
  border-radius: 50px;
}
header mdb-side-nav .facility-info .facility-icon i,
header .side-nav-links .facility-info .facility-icon i {
  font-size: 1.5rem;
  color: #2b373d;
  line-height: 40px;
}
header mdb-side-nav .facility-info label.form-check-label,
header .side-nav-links .facility-info label.form-check-label {
  text-transform: capitalize;
}
header mdb-side-nav .facility-info .facility-name,
header .side-nav-links .facility-info .facility-name {
  color: #ffffff;
}
@media (min-width: 1200px) {
  header mdb-side-nav .fixed.side-nav,
  header .side-nav-links .fixed.side-nav {
    transform: translateX(0%) !important;
  }
}
header mdb-side-nav ul.side-nav,
header .side-nav-links ul.side-nav {
  background: var(--dynamic-sidenav);
  box-shadow: none;
  z-index: 1000;
  border-right: #ededed;
  overflow: visible;
}
header mdb-side-nav ul.side-nav.fixed,
header .side-nav-links ul.side-nav.fixed {
  top: 60px;
}
header mdb-side-nav ul.side-nav .collapsible,
header .side-nav-links ul.side-nav .collapsible {
  margin-top: 0;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-header a,
header .side-nav-links ul.side-nav .collapsible .card .card-header a {
  height: 45px;
  line-height: 45px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-header a h5,
header .side-nav-links ul.side-nav .collapsible .card .card-header a h5 {
  color: #2b373d;
  font-size: 14px;
  line-height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-header a h5 i,
header .side-nav-links ul.side-nav .collapsible .card .card-header a h5 i {
  padding: 0;
  color: #ffffff;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-header a h5 small,
header .side-nav-links ul.side-nav .collapsible .card .card-header a h5 small {
  color: #ffffff;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-header a .fa,
header .side-nav-links ul.side-nav .collapsible .card .card-header a .fa {
  font-size: 1.9em;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-header a .rotate-icon,
header .side-nav-links ul.side-nav .collapsible .card .card-header a .rotate-icon {
  top: 0px;
  margin: 0;
  display: inline-flex;
  position: relative;
  z-index: 999999;
  right: 4px;
  transform-origin: center center;
}
header mdb-side-nav ul.side-nav .collapsible .card.active .card-header,
header .side-nav-links ul.side-nav .collapsible .card.active .card-header {
  background: var(--dynamic-sidenav);
  border-radius: 0;
  filter: brightness(1.2);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li {
  position: relative;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a {
  background: none;
  padding-left: 30px;
  line-height: calc(45px - 10px);
  height: calc(45px - 10px);
  padding-left: 1rem;
  color: #ffffff;
  font-size: 14px;
  z-index: 2;
  font-weight: 400;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a i,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a i {
  color: #ffffff;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.chronic-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.chronic-svg {
  width: 25px;
  margin-right: 14px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.chronic-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.chronic-svg .st0 {
  fill: #fff;
  stroke: #fff;
  stroke-width: 7px;
  stroke-miterlimit: 6;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.chronic-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.chronic-svg .st1 {
  stroke: transparent;
  fill: none;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.remote-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.remote-svg {
  fill: #fff;
  width: 25px;
  margin-right: 14px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.remote-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.remote-svg .st0 {
  stroke: transparent;
  fill: none;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.preventive-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.preventive-svg {
  width: 25px;
  margin-right: 14px;
  fill: #fff;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.preventive-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.preventive-svg .st0 {
  stroke: #fff;
  stroke-width: 25px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.preventive-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.preventive-svg .st1 {
  stroke: transparent;
  fill: none;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.behavioral-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.behavioral-svg {
  width: 25px;
  margin-right: 14px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.behavioral-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.behavioral-svg .st0 {
  fill: none;
  stroke: #fff;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.behavioral-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.behavioral-svg .st1 {
  stroke: #fff;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.behavioral-svg .st2,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.behavioral-svg .st2 {
  stroke: #fff;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.behavioral-svg .sst,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.behavioral-svg .sst {
  fill: #fff;
  stroke: #000;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.transitional-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.transitional-svg {
  width: 25px;
  margin-right: 14px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.transitional-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.transitional-svg .st0 {
  stroke: #fff;
  stroke-width: 25px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.transitional-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.transitional-svg .st1 {
  stroke: #fff;
  fill: none;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.transitional-svg .st2,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.transitional-svg .st2 {
  stroke: #ffffff;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li a svg.hes-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li a svg.hes-svg {
  width: 23px;
  margin-right: 15px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active::after, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover::after,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active::after,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover::after {
  content: " ";
  background: var(--dynamic-colour);
  width: 5px;
  height: 26px;
  position: absolute;
  border-radius: 0 25px 25px 0;
  left: 0px;
  z-index: 1;
  top: calc(50% - 13px);
  transition: all ease-in-out 300ms;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a {
  color: var(--dynamic-colour);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a i, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a i,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a i,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a i {
  color: var(--dynamic-colour);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.chronic-svg .st0, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.chronic-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.chronic-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.chronic-svg .st0 {
  fill: var(--dynamic-colour);
  stroke: var(--dynamic-colour);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.chronic-svg .st1, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.chronic-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.chronic-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.chronic-svg .st1 {
  stroke: transparent;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.preventive-svg, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.preventive-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.preventive-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.preventive-svg {
  fill: var(--dynamic-colour);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.preventive-svg .st1, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.preventive-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.preventive-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.preventive-svg .st1 {
  stroke: transparent;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.preventive-svg .st0, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.preventive-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.preventive-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.preventive-svg .st0 {
  stroke: var(--dynamic-colour);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.remote-svg, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.remote-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.remote-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.remote-svg {
  fill: var(--dynamic-colour);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.remote-svg .st0, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.remote-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.remote-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.remote-svg .st0 {
  stroke: transparent;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.behavioral-svg, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.behavioral-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.behavioral-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.behavioral-svg {
  width: 25px;
  margin-right: 14px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.behavioral-svg .st0, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.behavioral-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.behavioral-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.behavioral-svg .st0 {
  fill: none;
  stroke: var(--dynamic-colour);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.behavioral-svg .st1, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.behavioral-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.behavioral-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.behavioral-svg .st1 {
  stroke: var(--dynamic-colour);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.behavioral-svg .st2, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.behavioral-svg .st2,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.behavioral-svg .st2,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.behavioral-svg .st2 {
  stroke: var(--dynamic-colour);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.behavioral-svg .sst, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.behavioral-svg .sst,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.behavioral-svg .sst,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.behavioral-svg .sst {
  fill: var(--dynamic-colour);
  stroke: #000;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.transitional-svg, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.transitional-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.transitional-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.transitional-svg {
  width: 25px;
  margin-right: 14px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.transitional-svg .st0, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.transitional-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.transitional-svg .st0,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.transitional-svg .st0 {
  stroke: var(--dynamic-colour);
  stroke-width: 25px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.transitional-svg .st1, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.transitional-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.transitional-svg .st1,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.transitional-svg .st1 {
  stroke: var(--dynamic-colour);
  fill: none;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.transitional-svg .st2, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.transitional-svg .st2,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.transitional-svg .st2,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.transitional-svg .st2 {
  stroke: var(--dynamic-colour);
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.hes-svg, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.hes-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.hes-svg,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.hes-svg {
  width: 23px;
  margin-right: 15px;
}
header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.hes-svg path, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li.active a svg.hes-svg circle, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.hes-svg path, header mdb-side-nav ul.side-nav .collapsible .card .card-body ul li:hover a svg.hes-svg circle,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.hes-svg path,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li.active a svg.hes-svg circle,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.hes-svg path,
header .side-nav-links ul.side-nav .collapsible .card .card-body ul li:hover a svg.hes-svg circle {
  stroke: var(--dynamic-colour) !important;
}
header mdb-side-nav ul.side-nav li:first-child a,
header .side-nav-links ul.side-nav li:first-child a {
  margin: 0;
}
header mdb-side-nav ul.side-nav li a,
header .side-nav-links ul.side-nav li a {
  padding-left: 30px;
  line-height: calc(45px - 10px);
  height: calc(45px - 10px);
  padding-left: 1rem;
  color: #ffffff;
  font-size: 14px;
  position: relative;
  z-index: 2;
}
header mdb-side-nav ul.side-nav li a i,
header .side-nav-links ul.side-nav li a i {
  color: #ffffff;
}
header mdb-side-nav ul.side-nav li a:not([href]):not([tabindex]),
header mdb-side-nav ul.side-nav li a:not([href]):not([tabindex]):focus,
header mdb-side-nav ul.side-nav li a:not([href]):not([tabindex]):hover,
header .side-nav-links ul.side-nav li a:not([href]):not([tabindex]),
header .side-nav-links ul.side-nav li a:not([href]):not([tabindex]):focus,
header .side-nav-links ul.side-nav li a:not([href]):not([tabindex]):hover {
  color: #ffffff;
}
header mdb-side-nav ul.side-nav #mCSB_3_scrollbar_vertical,
header .side-nav-links ul.side-nav #mCSB_3_scrollbar_vertical {
  z-index: 5;
}
header mdb-side-nav ul links > li:not(.accordion-menu).active,
header .side-nav-links ul links > li:not(.accordion-menu).active {
  position: relative;
}
header mdb-side-nav ul links > li:not(.accordion-menu).active, header mdb-side-nav ul links > li:not(.accordion-menu):hover,
header .side-nav-links ul links > li:not(.accordion-menu).active,
header .side-nav-links ul links > li:not(.accordion-menu):hover {
  position: relative;
}
header mdb-side-nav ul links > li:not(.accordion-menu).active::after, header mdb-side-nav ul links > li:not(.accordion-menu):hover::after,
header .side-nav-links ul links > li:not(.accordion-menu).active::after,
header .side-nav-links ul links > li:not(.accordion-menu):hover::after {
  content: " ";
  background: var(--dynamic-colour);
  width: 5px;
  height: 26px;
  position: absolute;
  border-radius: 0 25px 25px 0;
  left: 0px;
  z-index: 1;
  top: calc(50% - 13px);
  transition: all ease-in-out 300ms;
}
header mdb-side-nav ul links > li:not(.accordion-menu).active a, header mdb-side-nav ul links > li:not(.accordion-menu):hover a,
header .side-nav-links ul links > li:not(.accordion-menu).active a,
header .side-nav-links ul links > li:not(.accordion-menu):hover a {
  color: var(--dynamic-colour) !important;
}
header mdb-side-nav ul links > li:not(.accordion-menu).active a i, header mdb-side-nav ul links > li:not(.accordion-menu):hover a i,
header .side-nav-links ul links > li:not(.accordion-menu).active a i,
header .side-nav-links ul links > li:not(.accordion-menu):hover a i {
  color: var(--dynamic-colour);
}
header mdb-side-nav ul links > li a,
header .side-nav-links ul links > li a {
  color: #ffffff;
}
header mdb-side-nav ul links > li a i:not(.mdb-accordion-indicator),
header .side-nav-links ul links > li a i:not(.mdb-accordion-indicator) {
  padding-right: 1rem;
  font-size: 1.4rem !important;
}
header mdb-side-nav ul links > li.dashboard-link a,
header mdb-side-nav ul links > li.dashboard-link i,
header .side-nav-links ul links > li.dashboard-link a,
header .side-nav-links ul links > li.dashboard-link i {
  color: #ffffff;
}
header mdb-side-nav ul links > li.dashboard-link.active a,
header mdb-side-nav ul links > li.dashboard-link.active i,
header .side-nav-links ul links > li.dashboard-link.active a,
header .side-nav-links ul links > li.dashboard-link.active i {
  color: var(--dynamic-colour);
}
header mdb-side-nav.side-nav-collapse .side-nav,
header .side-nav-links.side-nav-collapse .side-nav {
  width: 0px;
}
header mdb-side-nav.side-nav-collapse .side-nav li.active::after, header mdb-side-nav.side-nav-collapse .side-nav li:hover::after,
header .side-nav-links.side-nav-collapse .side-nav li.active::after,
header .side-nav-links.side-nav-collapse .side-nav li:hover::after {
  width: 5px;
  left: 0px;
  top: 0px;
}
header mdb-side-nav.side-nav-collapse .side-nav li a,
header .side-nav-links.side-nav-collapse .side-nav li a {
  padding-left: 1.2rem;
}
header mdb-side-nav.side-nav-collapse .side-nav li a span,
header .side-nav-links.side-nav-collapse .side-nav li a span {
  display: none;
}
header mdb-side-nav.side-nav-collapse .facility-info,
header .side-nav-links.side-nav-collapse .facility-info {
  display: none;
}
header mdb-side-nav.side-nav-collapse .web-version,
header .side-nav-links.side-nav-collapse .web-version {
  display: none;
}
@media (max-width: 575.98px) {
  header .fa-img {
    width: 20px !important;
  }
  header .navbar-container {
    width: 36px !important;
  }
}
header mdb-navbar nav {
  height: 60px;
}
header mdb-navbar nav.navbar {
  background: #fff;
  box-shadow: 0px 1px 8px rgba(68, 87, 96, 0.1411764706);
  padding: 0 1rem;
  border-bottom: 1px solid #ededed;
}
header mdb-navbar nav.double-nav {
  padding-left: 0 !important;
}
@media (max-width: 575.98px) {
  header mdb-navbar nav.double-nav {
    padding-right: 0.5rem;
  }
}
header mdb-navbar nav.double-nav .button-collapse {
  height: 100%;
  top: 0;
  align-items: center;
  align-items: center;
  left: 0;
  margin: 0 !important;
  width: 60px;
  justify-content: center;
  border-right: 1px solid #ededed;
  width: 36px !important;
}
@media only screen and (max-width : 1440px) {
  header mdb-navbar nav.double-nav .button-collapse {
    display: flex;
  }
}
@media (min-width: 1200px) {
  header mdb-navbar nav.double-nav .button-collapse {
    display: none;
  }
}
header mdb-navbar nav.double-nav .button-collapse i {
  font-size: 14px !important;
}
header mdb-navbar nav.double-nav .button-collapse i {
  color: var(--dynamic-colour);
  font-size: 16px;
}
@media (min-width: 576px) {
  header mdb-navbar nav.double-nav .brand-logo {
    width: 240px;
    text-align: center;
    position: absolute;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
  }
  header mdb-navbar nav.double-nav .brand-logo h4 {
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    margin: 0;
  }
  header mdb-navbar nav.double-nav .brand-logo img {
    width: 175px;
  }
  header mdb-navbar nav.double-nav .brand-logo:focus {
    outline: none;
  }
}
@media (max-width: 1199.98px) {
  header mdb-navbar nav.double-nav .brand-logo {
    left: 60px;
  }
  header mdb-navbar nav.double-nav.navbar-toggler {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #ededed;
  }
}
@media (max-width: 767.98px) {
  header mdb-navbar nav.double-nav .brand-logo {
    display: none;
  }
}
header mdb-navbar nav.double-nav a {
  color: #2b373d;
}
header mdb-navbar ul.nav {
  min-width: 175;
  justify-content: space-between;
}
header mdb-navbar .brand-logo-small {
  width: 60px !important;
  overflow: hidden;
}
header mdb-navbar .brand-logo-small img {
  width: 150px !important;
  transform: translate(47px, 0px);
  height: 100%;
}
header navlinks.right-menu {
  height: 100%;
}
header navlinks.left-menu ul li.active a {
  color: var(--dynamic-colour);
}
header navlinks ul.nav.navbar-nav {
  height: 100%;
}
header navlinks ul.nav.navbar-nav li.nav-item > a.nav-link {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #eeeeee;
}
header navlinks ul.nav.navbar-nav li.nav-item.avatar a {
  padding-left: 1rem;
}
@media (max-width: 575.98px) {
  header navlinks ul.nav.navbar-nav li.nav-item.avatar a {
    padding-left: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  header navlinks ul.nav.navbar-nav li.nav-item:not(:last-child) > a.nav-link {
    border-left: none;
    font-size: 10px;
    width: 32px;
  }
  header navlinks ul.nav.navbar-nav li.nav-item:not(:last-child) > a.nav-link a {
    font-size: 10px;
  }
}
header navlinks .icon-dropdown > a {
  width: 60px;
}
header navlinks .icon-dropdown i {
  color: var(--dynamic-colour);
  font-size: 1.4rem;
}
header.web {
  height: 60px;
}
header.web mdb-navbar .navbar {
  background: transparent;
  box-shadow: none;
}
@media only screen and (max-width : 992px) {
  header.web mdb-navbar .navbar {
    background: #fff;
  }
}
header.web mdb-navbar .navbar-collapse {
  height: 100%;
}
header.web mdb-navbar .navbar-collapse links {
  height: 100%;
}
header.web mdb-navbar .navbar-collapse links ul {
  height: 100%;
  align-items: center;
}
header.web mdb-navbar .navbar-collapse links ul li a {
  height: 100%;
  color: var(--dynamic-colour);
}
@media (min-width: 992px) {
  header .side-nav {
    transition: none;
  }
}

.ellipse {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
}

@media (min-width: 992px) {
  .fixed-sn main {
    padding-left: 15rem;
  }
}
@media (min-width: 992px) {
  .fixed-sn .double-nav, .fixed-sn main, .fixed-sn footer {
    padding-left: 15rem;
  }
}
@media (max-width: 1440px) {
  .fixed-sn .double-nav, .fixed-sn main, .fixed-sn footer {
    padding-left: 15rem !important;
  }
}
@media (max-width: 1200px) {
  .fixed-sn .double-nav, .fixed-sn main, .fixed-sn footer {
    padding-left: 0rem !important;
  }
}
.fixed-sn main.fixed-lg {
  padding-left: 0 !important;
}

.modal-dialog .modal-content {
  border-radius: 0.4rem;
}
.modal-dialog.flat-modal .modal-content .modal-header h4 {
  font-size: 1.2rem;
  font-weight: 400;
}

.registration-process {
  padding: 3rem 0;
}

ul.nav.classic-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ul.nav.classic-tabs li {
  flex: 1;
  background-color: #fff;
}
ul.nav.classic-tabs li a {
  color: #000 !important;
}
ul.nav.classic-tabs li a.active {
  color: white !important;
}
ul.nav.classic-tabs li.active {
  background-color: var(--dynamic-colour);
}

.classic-tabs.tabs-small ul.nav.classic-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.classic-tabs.tabs-small ul.nav.classic-tabs li {
  flex: 0 0 auto;
}

.classic-tabs .tab-content {
  padding: 3rem;
}

.tags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.tags .tags-title {
  margin-bottom: 10px;
  margin-right: 2rem;
  min-width: 100px;
  font-size: 0.75rem;
  text-transform: capitalize;
}
@media (max-width: 767.98px) {
  .tags .tags-title {
    flex: 1 1 100%;
    margin-bottom: 1rem;
  }
}
.tags ul.tag-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  flex-wrap: wrap;
}
.tags ul.tag-list li {
  border: 1px solid #cccccc;
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 0.9rem;
}
.tags ul.tag-list li i {
  margin-left: 0.2rem;
  cursor: pointer;
}
.tags ul.tag-list li a {
  color: var(--dynamic-secondary-colour);
}

:deep table.vertical thead {
  display: none;
}
:deep table.vertical tbody tr {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
:deep table.vertical tbody tr td {
  flex: 0 0 100%;
  text-align: right;
  padding: 5px 0;
}
:deep table.vertical tbody tr td::before {
  content: attr(data-label);
  float: left;
  font-weight: 500;
  color: var(--dynamic-colour);
}

.md-toast {
  opacity: 0.9 !important;
}

.md-toast-success {
  background-color: #30c96e !important;
  border: 2px solid #30c96e;
}

.md-toast-warning {
  background-color: #ffc655 !important;
  border: 2px solid #fb3;
}

.md-toast-error {
  background-color: #f54857 !important;
  border: 2px solid #ff3547;
}

.md-toast-info {
  background-color: #65bcdc;
  border: 2px solid #33b5e5;
}

#toast-container > mdb-toast-component {
  border-radius: 10px;
  opacity: 1 !important;
}

.md-toast-title {
  font-weight: 500 !important;
}

@media (max-width: 767.98px) {
  .degree-list table tr {
    margin-bottom: 1rem;
  }
}
.degree-list table th:last-child {
  text-align: center;
}
.degree-list table td:last-child {
  text-align: center;
}
.degree-list table thead th {
  padding: 0.2rem 0;
  font-weight: 500;
  color: #2b373d;
}
.degree-list table tbody tr td {
  padding: 0;
  vertical-align: middle;
}

.ngx-datatable {
  overflow: visible;
  margin-bottom: 1rem;
}
.ngx-datatable.material {
  box-shadow: 0 10px 15px 0 #e9ecee;
  border-radius: 5px;
}
.ngx-datatable.material .datatable-header {
  background-color: var(--dynamic-colour);
}
.ngx-datatable.material .datatable-header .datatable-row-right {
  background-image: none;
  background-color: var(--dynamic-colour);
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  color: #fff;
  font-weight: 500;
  font-size: 0.8rem;
  background-color: var(--dynamic-colour);
}
.ngx-datatable.material .datatable-body .datatable-body-cell {
  padding: 0.3rem 0.5rem;
  box-shadow: inset 1px 1px 0 #e6e6e6;
}
.ngx-datatable.material .datatable-body .datatable-row-right {
  background-image: none;
}
.ngx-datatable.material .datatable-body .datatable-row-right .datatable-body-cell {
  padding: 0.5rem 0.6rem !important;
}
.ngx-datatable.material .datatable-body .datatable-row-right .datatable-body-cell-label {
  width: 100%;
}
.ngx-datatable.material .datatable-body .datatable-row-left {
  background-image: none;
}
.ngx-datatable.material .datatable-footer .page-count {
  color: var(--dynamic-colour);
}
.ngx-datatable.material .action-button a {
  margin: 0 2px;
}
.ngx-datatable.material.single-selection .datatable-body-row.active {
  background-color: #ededed;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover {
  background-color: #e6e6e6;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #e6e6e6;
}
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {
  background-color: #ededed;
}
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group:hover {
  background-color: #e6e6e6;
}
.ngx-datatable.material.content-center .datatable-header .datatable-header-cell {
  display: flex;
  justify-content: center;
}
.ngx-datatable.material.content-center .datatable-scroll .datatable-body-row .datatable-body-cell {
  justify-content: center;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
  padding: 0.3rem 0.5rem;
  display: flex;
  align-items: center;
}

.ccm-body .ngx-datatable.material {
  border-radius: 0px;
}

dp-date-picker,
dp-month-calendar,
dp-day-time-calendar {
  position: relative;
  z-index: 9999999;
}
dp-date-picker.dp-material.ccm-date-picker dp-time-select,
dp-month-calendar.dp-material.ccm-date-picker dp-time-select,
dp-day-time-calendar.dp-material.ccm-date-picker dp-time-select {
  border: none;
}
dp-date-picker .ccm-date-picker,
dp-month-calendar .ccm-date-picker,
dp-day-time-calendar .ccm-date-picker {
  border: none;
}
dp-date-picker .dp-popup,
dp-month-calendar .dp-popup,
dp-day-time-calendar .dp-popup {
  top: 5px;
  background: white;
  border-radius: 5px;
}

dp-day-calendar.dp-material.ccm-date-picker .dp-day-calendar-container {
  border-radius: 5px;
}
dp-day-calendar.dp-material.ccm-date-picker .dp-calendar-wrapper {
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.7rem;
  overflow: hidden;
}
dp-day-calendar.dp-material.ccm-date-picker .dp-calendar-wrapper .dp-calendar-weekday {
  width: 30px;
  color: var(--dynamic-colour);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  margin: 3px;
}
dp-day-calendar.dp-material.ccm-date-picker .dp-calendar-wrapper .dp-calendar-day {
  width: 30px;
  height: 30px;
  font-size: 14px;
  margin: 3px;
}
dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-current-location-btn {
  background: var(--dynamic-colour);
  border: 1px solid var(--dynamic-colour);
  top: 0;
}
dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-calendar-nav-container {
  height: 40px;
  border: none;
  background: var(--dynamic-colour);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}
dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-calendar-nav-container .dp-nav-header {
  left: 0;
}
dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-calendar-nav-container .dp-nav-header .dp-nav-header-btn {
  height: 30px;
  background: var(--dynamic-colour);
  color: #fff;
}
dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-nav-btns-container {
  right: 0;
  height: 30px;
}
dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-nav-btns-container .dp-calendar-nav-container-left,
dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-nav-btns-container .dp-calendar-nav-container-right {
  height: 30px;
}
dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-nav-btns-container .dp-calendar-nav-right,
dp-day-calendar.dp-material.ccm-date-picker dp-calendar-nav .dp-nav-btns-container .dp-calendar-nav-left {
  display: inline-block;
  height: 100%;
  width: 30px;
  background: var(--dynamic-colour);
  color: #fff;
}

dp-time-select .dp-time-select-controls {
  border-radius: 5px;
  overflow: hidden;
}
dp-time-select .dp-time-select-controls .dp-time-select-control-up::before {
  top: 1px;
}
dp-time-select .dp-time-select-controls .dp-time-select-control-down::before {
  top: -1px;
}

.ccm-body {
  background-color: #fff;
}

.form-check-input[type=radio]:not(:checked) + label:before,
.form-check-input[type=radio]:not(:checked) + label:after,
.form-check-input[type=radio].with-gap:checked + label:before,
.form-check-input[type=radio].with-gap:checked + label:after,
label.btn input[type=radio]:not(:checked) + label:before,
label.btn input[type=radio]:not(:checked) + label:after,
label.btn input[type=radio].with-gap:checked + label:before,
label.btn input[type=radio].with-gap:checked + label:after {
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.input-group-prepend .input-group-text {
  border: none;
}

.input-group-append {
  height: 32px;
}
.input-group-append .input-group-text {
  border: 1px solid var(--dynamic-colour);
}
.input-group-append:nth-child(1) .input-group-text {
  border-left: 1px solid #fff;
}

.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.input-group input.form-control {
  border-right: 0;
}

.ccm-template .form-group {
  margin-bottom: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0.4rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: #eceff1 !important;
  font-family: "Line Awesome Free";
  font-weight: 900;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected::before {
  content: "\f00c";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dynamic-colour);
  float: right;
  font-size: 1rem;
  line-height: 20px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label {
  font-weight: 400 !important;
  font-family: "Roboto", sans-serif !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected span {
  font-weight: normal;
  margin-left: auto;
}

.sprt {
  width: 100%;
  margin: 0.78rem 0;
  box-shadow: 0px 4px 15px 0 #cccccc;
}

.panel,
.card {
  box-shadow: 0 10px 15px 0 #e9ecee;
}

.datatable-checkbox [type=checkbox]:not(:checked),
.datatable-checkbox [type=checkbox]:checked {
  opacity: 1;
}
.datatable-checkbox input[type=checkbox]:before {
  border: 2px solid #e0e0e0;
}
.datatable-checkbox input[type=checkbox]:checked:before {
  border-color: var(--dynamic-colour);
}

mdb-accordion-item-head {
  background-color: transparent;
}

.mdb-accordion-indicator::after {
  display: none;
}

.accordion .card .card-body {
  padding: 0 1rem;
}

.ccm-patient-info .accordion .card .card-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

table.table th,
table.table td {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.ccm-tabs mdb-tabset .tab-content {
  background: transparent;
}
.ccm-tabs ul.nav.classic-tabs {
  display: none;
}

.h-100 {
  height: 100%;
}

ul.ccm-tabs {
  justify-content: flex-start;
  align-items: center;
}
ul.ccm-tabs li {
  flex: 1;
  width: 100%;
  border: 1px solid #ededed;
}
ul.ccm-tabs li:not(:last-child) {
  border-bottom: 1px solid #ededed;
}
ul.ccm-tabs li a {
  color: var(--dynamic-colour);
  font-weight: 400;
}
ul.ccm-tabs li.active {
  position: relative;
}
ul.ccm-tabs li.active a {
  background-color: var(--dynamic-colour);
  color: #fff;
}
@media (min-width: 576px) {
  ul.ccm-tabs li.active:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #e6e6e6;
    border-width: 10px;
    margin-top: -10px;
  }
}
ul.ccm-tabs.main-category li {
  border: 1px solid #e6e6e6;
}
ul.ccm-tabs.main-category li:not(:last-child) {
  border-bottom: none;
}
ul.ccm-tabs.main-category li.nav-item.disabled {
  border: none;
  border-bottom: 1px solid #e6e6e6;
}
ul.ccm-tabs.main-category li.nav-item.disabled a {
  font-weight: 500;
  background-color: var(--dynamic-colour);
  color: #fff;
}
ul.ccm-tabs.main-category li.nav-item:not(.disabled) a {
  padding-left: 2rem;
  font-size: 0.9rem;
  padding: 5px 2rem;
}
ul.ccm-tabs.main-category li.nav-item.active a {
  color: var(--dark);
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.option-added {
  margin: 0.75rem 0;
  position: relative;
  border-color: var(--dynamic-colour);
}
.option-added button {
  width: 30px;
  height: 30px;
  top: 0;
  right: 0 !important;
}
.option-added button i {
  font-size: 16px;
}
.option-added button i:hover {
  color: var(--dynamic-colour);
}

.create-new .form-control {
  min-height: 36px;
}

mdb-date-picker.custom-datepicker .md-form {
  margin: 0 !important;
}
mdb-date-picker.custom-datepicker .md-form .form-control {
  border: 1px solid #ededed;
  height: 31.5px;
  padding: 0 0.5rem;
  border-radius: 3px;
}

.picker__box .picker__table .picker__day--selected {
  box-shadow: none;
  background-color: var(--dynamic-colour);
}

.picker__date-display {
  display: none !important;
}

.mydp .md-form.md-outline {
  margin: 0;
}

.primary-color-dark-s {
  background-color: var(--dynamic-colour) !important;
  filter: brightness(1.75);
}
.primary-color-dark-s .mat-progress-bar-buffer {
  background-color: var(--dynamic-colour) !important;
  filter: brightness(1.75);
}
.primary-color-dark-s .mat-progress-bar-fill::after {
  background-color: var(--dynamic-colour) !important;
  filter: brightness(1.1);
}

.completed-status .progress {
  height: 12px !important;
  margin: 0;
  border-radius: 0;
  background-color: #c1c1c1;
}
.completed-status .progress .progress-bar {
  height: 12px;
  color: #fff;
  border-radius: 0;
  font-size: 10px;
  border-radius: 0;
  background-color: var(--dynamic-secondary-colour);
}
.completed-status .TimeLog .progress .progress-bar {
  background-color: var(--dynamic-colour);
}

a.dragable-btn {
  cursor: grab;
  flex: 0 0 20px;
  text-align: center;
  height: 20px;
  display: flex;
  align-items: center;
}
a.dragable-btn:active {
  cursor: grabbing;
}
a.dragable-btn i {
  margin: 0;
  padding: 0;
  width: 5px;
  color: #cccccc;
}

.nameCell .datatable-body-cell-label {
  width: 100% !important;
}

.table-data-list mdb-progress-bar.progress.primary-color-dark {
  position: relative;
  top: 50px;
  z-index: 44;
}

.ccm-header {
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.ccm-header .patient-profile {
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  border-bottom: 1px solid #ededed;
}
.ccm-header .patient-profile > div {
  flex: 1 1 25%;
  flex: 1 1 25%;
}
@media (max-width: 991.98px) {
  .ccm-header .patient-profile > div {
    flex: 1 1 50%;
    margin-bottom: 1rem;
  }
}
@media (max-width: 767.98px) {
  .ccm-header .patient-profile > div {
    flex: 1 1 100%;
  }
}
.ccm-header .patient-profile p {
  margin: 0;
  color: #999999;
}
.ccm-header .patient-profile p span {
  color: #4d4d4d;
  font-weight: 500;
  text-transform: capitalize;
}
.ccm-header .patient-profile .profile-img {
  margin-right: 1rem;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: #eceff1;
  line-height: 80px;
  text-align: center;
}
.ccm-header .patient-profile .profile-img i {
  font-size: 2rem;
  color: var(--dynamic-colour);
}
.ccm-header .patient-profile .profile-img img {
  border-radius: 50%;
  width: 75px;
  height: 75px;
}
@media (max-width: 575.98px) {
  .ccm-header .patient-profile .profile-img {
    width: 35px;
    height: 35px;
    line-height: 45px;
  }
  .ccm-header .patient-profile .profile-img i {
    font-size: 1.4rem;
  }
}

.ccm-date {
  display: flex;
}
.ccm-date label {
  margin: 0;
  white-space: nowrap;
  padding: 0 10px;
  font-size: 0.9rem;
}
.ccm-date .cons-date,
.ccm-date .follow-date {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.ccm-time p {
  font-size: 0.9rem;
}

.encounter-header {
  border-bottom: 1px solid #ededed;
}

.enc-time {
  border-right: 1px solid #ededed;
}

.ques-section {
  margin-bottom: 1rem;
  border: 1px solid #ededed;
  padding: 1rem;
  background: #f7f7f7;
}

.add-section {
  border: 1px dashed #cccccc;
  min-height: 100px;
  transition: all ease 0.3s;
}
.add-section:hover {
  border: 1px dashed var(--dynamic-colour);
  transition: all ease 0.3s;
}

.play-counter {
  border-left: 1px solid #fff;
}

@media (min-width: 576px) {
  .logs-container::before {
    content: " ";
    height: 100%;
    width: 1px;
    border-left: 1px dashed var(--dynamic-colour);
    position: absolute;
    left: calc(6rem + 31px);
    z-index: 8;
  }
}
@media (max-width: 767.98px) {
  .logs-container::before {
    left: 1rem;
  }
}

.logs {
  height: 100%;
  padding-left: 6rem;
}
@media (max-width: 767.98px) {
  .logs {
    padding: 0;
  }
}
.logs .log-item {
  position: relative;
  padding-bottom: 1rem;
}
.logs .log-item::before {
  content: " ";
  height: 100%;
  width: 1px;
  border-left: 1px solid var(--dynamic-colour);
  position: absolute;
  left: 1rem;
  z-index: 8;
}
.logs .log-item .log-title {
  color: var(--dynamic-colour);
  margin-bottom: 0;
  padding-left: 11px;
}
.logs .log-item .log-title:before {
  content: attr(date-time);
  position: absolute;
  left: -6rem;
}
.logs .log-item .log-title span.log-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: var(--dynamic-colour);
  border-radius: 10px;
  border: 2px solid #fff;
  z-index: 9;
  position: relative;
}
.logs .log-item .log-info {
  padding-left: 2.5rem;
}
.logs .log-item .log-info .sprt {
  color: #cccccc;
  margin: 0 1rem;
}

.ccm-encounter .accordion .card .card-body {
  padding: 0;
}

@media (max-width: 767.98px) {
  .encounter-header .month {
    flex: 1;
    margin-bottom: 1rem;
  }
  .encounter-header .ccm-date {
    flex: 1 1 100%;
  }
  .encounter-header .ccm-date label {
    flex: 100%;
    padding: 0 0 !important;
  }
  .encounter-header .ccm-date .follow-date {
    width: 100%;
  }
}
.encounter-accordion {
  box-shadow: 0 10px 15px 0 #e9ecee;
}

.collapse-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collapse-btn i {
  color: #fff;
}

.ccm-care-plan-header {
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  width: 100%;
  top: 0;
  z-index: 999;
}
@media (max-width: 575.98px) {
  .ccm-care-plan-header .timer-container {
    margin: 0 auto;
  }
}
@media (max-width: 575.98px) {
  .ccm-care-plan-header strong {
    display: none;
  }
}

.ccm-care-plan-body.drop-shadow {
  box-shadow: 0 37px 96px -100px #333;
}

mdb-tabset.alerts-tab-container {
  display: block;
  margin: 0 -1rem;
}
mdb-tabset.alerts-tab-container ul.alert-tabs {
  border: 1px solid var(--dynamic-colour);
  border-radius: 0;
}
mdb-tabset.alerts-tab-container ul.alert-tabs .nav-item {
  margin: 0 !important;
}
mdb-tabset.alerts-tab-container ul.alert-tabs .nav-item a {
  padding: 0;
  margin: 0;
  color: var(--dynamic-colour);
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  text-align: left;
}
mdb-tabset.alerts-tab-container ul.alert-tabs .nav-item.active a {
  background: var(--dynamic-colour);
  color: #fff;
}

mdb-tabset.modality-tabs ul.modality-tabs {
  border-radius: 0;
  background-color: var(--dynamic-secondary-colour);
  margin-bottom: 0.9rem;
}
@media (max-width: 767.98px) {
  mdb-tabset.modality-tabs ul.modality-tabs {
    overflow-x: scroll;
  }
}
mdb-tabset.modality-tabs ul.modality-tabs .nav-item {
  margin: 0 !important;
}
mdb-tabset.modality-tabs ul.modality-tabs .nav-item a {
  padding: 0;
  margin: 0;
  color: #fff;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  text-align: left;
  white-space: pre;
}
mdb-tabset.modality-tabs ul.modality-tabs .nav-item.active a {
  background: var(--dynamic-colour);
  color: #fff;
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: var(--dynamic-secondary-colour);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: var(--dynamic-secondary-colour);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

.block-header {
  background: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid #ededed;
}

app-ccm-question-list .encounter-section .encounter-body {
  box-shadow: 0px 15px 20px -10px rgba(103, 103, 103, 0.2901960784);
  background: #fdfdfe;
}

ul.nav.line-tabs {
  box-shadow: inset 0px -3px 0 0 #c9d3e2;
  background-color: #fff;
}
ul.nav.line-tabs li.nav-item a {
  color: var(--dynamic-secondary-colour);
  padding: 1rem;
  font-weight: 500;
}
ul.nav.line-tabs li.nav-item.active {
  border-bottom: 3px solid var(--dynamic-colour);
}
ul.nav.line-tabs li.nav-item.active a {
  color: var(--dynamic-colour);
}

.db-block {
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: #fff;
  box-shadow: 0 10px 15px 0 #e9ecee;
  border-radius: 5px;
}

.ccm-loader {
  position: absolute;
  left: calc(50% - 26px);
  top: calc(50% - 8px);
  z-index: 999;
}

.loader-wrap {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 998;
  top: 0;
  left: 0;
}
.loader-wrap:after {
  content: " ";
  background: #fff;
  width: 100%;
  height: 100%;
  display: block;
  transition: background ease-in-out 0.3s;
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-pulse,
.la-ball-pulse > div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-ball-pulse {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-ball-pulse.la-dark {
  color: #333;
}

.la-ball-pulse > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-pulse {
  width: 54px;
  height: 18px;
}

.la-ball-pulse > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  -moz-animation-delay: -200ms;
  -o-animation-delay: -200ms;
  animation-delay: -200ms;
}

.la-ball-pulse > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  -moz-animation-delay: -100ms;
  -o-animation-delay: -100ms;
  animation-delay: -100ms;
}

.la-ball-pulse > div:nth-child(3) {
  -webkit-animation-delay: 0ms;
  -moz-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms;
}

.la-ball-pulse > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  -webkit-animation: ball-pulse 1s ease infinite;
  -moz-animation: ball-pulse 1s ease infinite;
  -o-animation: ball-pulse 1s ease infinite;
  animation: ball-pulse 1s ease infinite;
}

.la-ball-pulse.la-sm {
  width: 26px;
  height: 8px;
}

.la-ball-pulse.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px;
}

.la-ball-pulse.la-2x {
  width: 108px;
  height: 36px;
}

.la-ball-pulse.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}

.la-ball-pulse.la-3x {
  width: 162px;
  height: 54px;
}

.la-ball-pulse.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}

/*
  * Animation
  */
@-webkit-keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
}
@-moz-keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -moz-transform: scale(0.01);
    transform: scale(0.01);
  }
}
@-o-keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -o-transform: scale(0.01);
    transform: scale(0.01);
  }
}
@keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -webkit-transform: scale(0.01);
    -moz-transform: scale(0.01);
    -o-transform: scale(0.01);
    transform: scale(0.01);
  }
}
.alert-manager-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--stylish-color);
  color: #2b373d;
  padding: 0.4rem;
}
.alert-manager-header .day-name,
.alert-manager-header .day-time {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}
.alert-manager-header .day-name {
  flex: 0 0 100px;
}
.alert-manager-header .day-time {
  flex: 1;
}

.weekdays {
  background: #fff;
}
.weekdays .weekday {
  display: flex;
  background: white;
  border-bottom: 1px solid #ededed;
}
.weekdays .weekday .day-name {
  flex: 0 0 100px;
  background: var(--dynamic-colour);
  color: #fff;
}
.weekdays .weekday .day-time {
  background: #ff9292;
  flex: 0 0 30px;
}
.weekdays .weekday .day-time.select {
  border: 1px solid var(--dynamic-secondary-colour);
}
.weekdays .weekday .day-time:hover {
  background-color: #ededed;
}
.weekdays .weekday :not(:nth-child(2)) {
  flex: 1;
  text-align: center;
  display: grid;
  grid-template-rows: auto;
}
.weekdays .weekday :not(:nth-child(2)):hover {
  background: #ededed;
}
.weekdays .weekday .all-day {
  flex: 0 0 86px;
  padding: 0 6px;
}
.weekdays .weekday :nth-child(2) {
  text-align: left;
}

main.page-body {
  padding-top: 4.5rem;
  padding-bottom: 1.5rem;
}

.bs-sm {
  box-shadow: 4px 4px 10px #f2f3f8;
}

.system-info {
  background-color: #fff;
}
.system-info .system-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  border: 1px solid;
  margin-bottom: 0.5rem;
  border-color: #fff;
}
.system-info .system-item:hover {
  border-color: var(--dynamic-colour);
}
.system-info .system-item .sys-img {
  padding-right: 1rem;
}
.system-info .system-item h5 {
  margin: 0;
}

.ng-dropdown-panel.ng-select-bottom {
  z-index: 999999999 !important;
}

.system-config.ng-select.ng-select-single .ng-select-container {
  height: 36px !important;
}
.system-config .ng-dropdown-panel.ng-select-bottom .ng-option {
  background: #f2f3f8;
  padding: 0 0.5rem;
  margin: 0;
}
.system-config .ng-dropdown-panel.ng-select-bottom .ng-option.ng-option-selected {
  background: #f2f3f8 !important;
}
.system-config .ng-dropdown-panel.ng-select-bottom .ng-option.ng-option-selected:before {
  display: none;
}
.system-config .ng-dropdown-panel.ng-select-bottom .ng-option.ng-option-selected .system-item {
  border-color: var(--dynamic-colour);
}

mat-form-field.show-rocord {
  margin-bottom: 1rem;
  display: block;
  text-align: right;
}
mat-form-field.show-rocord a {
  padding: 0.1rem 0.2rem;
  background: var(--dynamic-secondary-colour);
  margin-left: 0.3rem;
  border: 1px solid var(--dynamic-secondary-colour);
  color: white !important;
  border-radius: 3px;
}
mat-form-field.show-rocord a.active {
  background: var(--dynamic-colour);
  color: white;
  border: 1px solid var(--dynamic-colour);
}

.master-care-plan h3.section-title {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 1.3rem 0;
  color: var(--dynamic-colour);
}
.master-care-plan section {
  padding: 1rem;
  border: 1px solid #e6e6e6;
  margin-bottom: 1rem;
}

.notification-list {
  max-height: 300px;
  margin: 0 -0.5rem;
}
.notification-list .notification-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  font-size: 13px;
}
.notification-list .notification-items:not(:first-child) {
  border-top: 1px solid #ededed;
}
.notification-list .notification-items .n-desc p {
  flex: 0 0 180px;
  margin-top: 6px;
  margin-bottom: 0px;
  font-size: 12px;
}
.notification-list .notification-items .n-counter {
  font-size: 10px;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: var(--dynamic-secondary-colour);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ededed;
}
.notification-list .notification-items .n-counter i {
  font-size: 10px !important;
}
.notification-list .notification-items .n-counter.completed, .notification-list .notification-items .n-counter:hover {
  background-color: var(--dynamic-colour);
}
.notification-list .notification-items .n-counter.completed i, .notification-list .notification-items .n-counter:hover i {
  color: #fff;
}
.notification-list .notification-items:hover {
  cursor: pointer;
}
.notification-list .notification-items .clear-link {
  padding: 0;
  font-size: 12px;
  color: var(--dynamic-colour);
  margin-left: 0.5rem;
}
.notification-list .mCSB_inside > .mCSB_container {
  margin-right: 0px;
}

.ccm-header {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}

.ccm-nav .ccm-navbar {
  display: inline-flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.ccm-nav .ccm-navbar li {
  flex-shrink: 0;
}
.ccm-nav .ccm-navbar li a {
  padding: 0.75rem 1rem;
  color: #999999;
  display: block;
}
.ccm-nav .ccm-navbar li a:hover, .ccm-nav .ccm-navbar li a:focus {
  color: var(--dynamic-colour);
  box-shadow: inset 0 -3px 0 var(--dynamic-colour);
}
.ccm-nav .ccm-navbar li.active a {
  box-shadow: inset 0 -3px 0 var(--dynamic-colour);
  color: var(--dynamic-colour);
}

.cal-month-view .cal-day-badge {
  background-color: var(--dynamic-colour);
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 0;
  line-height: 20px;
  font-weight: normal;
}
.cal-month-view .cal-open-day-events {
  color: #2b373d;
  background-color: #e6e6e6;
  box-shadow: none;
}

.spinner-grow {
  position: fixed;
  top: 50%;
  left: calc(50% - 20px);
}

.msg-counter {
  font-size: 10px;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: var(--dynamic-secondary-colour);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ededed;
}

i.message-alert {
  position: relative;
}

i.message-alert:after {
  content: " ";
  height: 10px;
  display: inline-block;
  background: red;
  width: 10px;
  border-radius: 10px;
  position: absolute;
  top: -6px;
  left: 15px;
  border: 2px solid white;
}

#moveAbleDiv {
  position: fixed;
  z-index: 9999;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
  border-radius: 7px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4196078431);
}

#moveableHeader {
  padding: 10px;
  cursor: grab;
  z-index: 10;
  background-color: #2196f3;
  color: #fff;
}

.video-container {
  position: relative;
  background: rgba(0, 0, 0, 0.4196078431);
}
.video-container .video-chat-header {
  transform: translate3d(0, -40px, 0);
  opacity: 0;
  background: rgba(22, 16, 16, 0.4588235294);
  display: flex;
  align-items: center;
  padding: 1rem;
  position: absolute;
  width: 100%;
}
.video-container .video-chat-header p {
  margin: 0;
  color: white;
}
.video-container .video-chat-header .avatar {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  line-height: 35px;
  border-radius: 50px;
  background: #ededed;
  margin-right: 0.75rem;
  font-weight: 500;
  color: #1d3d71;
  font-size: 12px;
}
.video-container .video-chat-footer {
  transform: translate3d(0, 30px, 0);
  opacity: 0;
  position: absolute;
  bottom: 2rem;
  width: 100%;
}
.video-container .video-chat-footer button {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 0.5rem;
  border-radius: 50%;
  border: none;
  position: relative;
  z-index: 5;
}
.video-container .video-chat-footer button i {
  font-size: 1.3rem;
  line-height: 40px;
}
.video-container .video-chat-footer button.call-end {
  background-color: #c73230;
  color: #fff;
}
.video-container .video-chat-footer button.microphone, .video-container .video-chat-footer button.camera-action {
  background-color: #fff;
}
.video-container .video-chat-footer button.full-screen {
  background-color: transparent;
  color: #fff;
}

.video-container:hover .video-chat-footer {
  /* display: block; */
  transform: translate3d(0, 0, 0);
  opacity: 1;
  animation-name: fadeInVideoAction;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

.video-container:hover .video-chat-header {
  /* display: block; */
  transform: translate3d(0, 0, 0);
  opacity: 1;
  animation-name: fadeInVideoHeader;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

.caller {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .caller {
    width: 100vw;
    height: 80%;
    align-self: center;
  }
  .caller .call-action {
    align-self: flex-end;
  }
  .caller .caller-info {
    margin-top: auto;
  }
}

span.caller-video {
  position: absolute;
  width: 130px;
  height: 97px;
  z-index: 1111;
  top: 0;
  right: 0;
  margin: 1rem;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 1px 10px 14px rgba(35, 31, 32, 0.2117647059);
}

span.caller-video video {
  width: 100%;
  height: 100%;
}

@keyframes fadeInVideoAction {
  0% {
    transform: translate3d(0, 30px, 0);
    opacity: 0;
  }
  25% {
    opacity: 0.2;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fadeInVideoHeader {
  0% {
    transform: translate3d(0, -40px, 0);
    opacity: 0;
  }
  25% {
    opacity: 0.2;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.pulse {
  display: block;
  width: 200px;
  height: 200px;
  line-height: 205px;
  border-radius: 50%;
  background: var(--dynamic-colour);
  box-shadow: 0 0 0 white;
  animation: pulse 2s infinite;
  transition: all ease-in-out 0.5s;
}

.pulse:hover {
  transition: all ease-in-out 0.5s;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #a2c3fa;
    box-shadow: 0 0 0 0 #a2c3fa;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px #bad3fb;
    box-shadow: 0 0 0 10px #bad3fb;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 #a2c3fa;
    box-shadow: 0 0 0 0 #a2c3fa;
  }
}
.ringing {
  display: flex;
  width: 60px;
  height: 60px;
  background: var(--dynamic-colour);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
}

.ringing i {
  font-size: 1.6rem;
  color: white;
}

.ringing:after,
.ringing:before {
  content: " ";
  display: block;
  border: 1px solid var(--dynamic-colour);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  animation: ringing 2s infinite;
  animation-timing-function: cubic-bezier(0.79, 0, 0.28, 0.87);
  opacity: 0;
}

.ringing:after {
  animation-delay: 1s;
}

@keyframes ringing {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
.logo-animation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: white;
  z-index: 9999;
}
.logo-animation svg {
  width: 100px;
}

.slide-in-elliptic-top-fwd {
  -webkit-animation: slide-in-elliptic-top-fwd 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-elliptic-top-fwd 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.delay-1 {
  animation-duration: 3s;
  animation-delay: 0.25s;
}

.delay-2 {
  animation-duration: 3s;
  animation-delay: 0.5s;
}

.delay-3 {
  animation-duration: 3s;
  animation-delay: 0.75s;
}

.delay-4 {
  animation-duration: 3s;
  animation-delay: 1s;
}

.delay-5 {
  animation-duration: 3s;
  animation-delay: 1.25s;
}

.delay-6 {
  animation-duration: 3s;
  animation-delay: 1.5s;
}

.pulsate-fwd {
  animation: pulsate-fwd 2s ease-in-out infinite both;
}

@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    -webkit-transform: scale(1.075);
    transform: scale(1.075);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.3;
  }
}
.skltn-card__avatar {
  width: 60px;
  height: 60px; /* <-- Don't forget height */
  margin-bottom: 20px;
}

.skltn-card__title {
  height: 32px;
  margin-bottom: 20px;
}

.skltn-card__line {
  height: 20px;
  margin-bottom: 20px;
}

.skltn-card__line:last-child {
  width: 60%;
}

dp-date-picker {
  display: flex !important;
}

.picker__box .picker__header {
  margin-top: 0.5rem;
}

table.ccm-table tr td,
table.ccm-table tr th {
  font-family: inherit;
  vertical-align: middle;
  white-space: pre;
}
table.ccm-table thead {
  background-color: var(--dynamic-colour);
}
table.ccm-table thead tr th {
  height: 50px;
  font-weight: 500;
  color: #fff;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 10px 18px;
  background: var(--dynamic-colour);
  color: white;
  font-weight: 500;
}

#DataTables_Table_0_length select {
  display: inline-block !important;
}

.tox-statusbar {
  display: none !important;
}

#progress-bar .progress {
  height: 20px !important;
}

#progress-bar .progress {
  height: 20px !important;
  background-color: #d3d5d8;
  border-radius: 4px;
}

#progress-bar .progress-bar {
  height: 20px;
  line-height: 20px;
}

.min-label {
  margin-left: 2px;
}
.min-label:after {
  content: attr(data-label);
}

table.dataTable {
  width: 100% !important;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 2px 10px;
}

table.dataTable tbody td {
  border-left: 0.5px solid #f5f5f5;
  border-bottom: 0.5px solid #f5f5f5;
}

.dataTables_scrollBody {
  overflow-y: hidden !important;
}

td#interactive-contact .mCustomScrollBox {
  width: 100% !important;
}

td#interactive-contact table th,
td#interactive-contact table td {
  vertical-align: top;
}

table.dataTable > tbody > tr.selected > * {
  box-shadow: none !important;
  color: #212529 !important;
  background-color: rgba(78, 176, 72, 0.1294117647);
}

.dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting {
  opacity: all ease-in 300ms;
  position: relative;
}
.dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting:before, .dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting:after,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting:before,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting:after {
  font-family: "Line Awesome Free";
  position: absolute;
  opacity: 0.6;
}
.dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting:before,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting:before {
  top: 12px;
  right: 5px;
}
.dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting:after,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting:after {
  right: 5px;
  top: 23px;
}
.dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting_asc,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting_asc {
  transition: all ease-in 300ms;
  position: relative;
}
.dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting_asc:before,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting_asc:before {
  position: absolute;
  font-family: "Line Awesome Free";
  transform: translate(0px, 0px);
}
.dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting_asc:before,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting_asc:before {
  content: "\f0d8";
  right: 2px;
  top: 12px;
}
.dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting_desc,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting_desc {
  position: relative;
}
.dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting_desc:before,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting_desc:before {
  position: absolute;
  font-family: "Line Awesome Free";
}
.dataTables_scrollHead table.ccm-datatable.dataTable thead .sorting_desc:before,
.DTFC_LeftHeadWrapper table.ccm-datatable.dataTable thead .sorting_desc:before {
  content: "\f0d7";
  right: 2px;
  top: 12px;
}

.no-data-available {
  text-align: center;
}

.dataTables_empty {
  display: none;
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: var(--dynamic-colour);
}

.mod-list .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 8px !important;
}

.pcm-status-container {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1199.98px) {
  .pcm-status-container {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: auto;
  }
}

.insurance-gap {
  position: relative;
  overflow: hidden;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 0 5px 0 10px;
  background-color: white;
}
@media (min-width: 576px) {
  .insurance-gap {
    margin-right: 1rem;
  }
}
.insurance-gap:after {
  content: " ";
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  background: #dee2e6;
  top: 0;
}

.facility-gap {
  position: relative;
  overflow: hidden;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 0 5px 0 10px;
  background-color: white;
}
.facility-gap:after {
  content: " ";
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  background: #dee2e6;
  top: 0;
}

.pcm-status {
  margin: 0.5rem 4px;
}
@media (max-width: 1199.98px) {
  .pcm-status {
    height: auto;
  }
}
@media (max-width: 575.98px) {
  .pcm-status {
    margin-top: 10px;
  }
  .pcm-status:nth-child(2) {
    border-top: 1px solid #dee2e6;
    margin-top: 10px;
  }
}
.pcm-status ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  max-width: 385px;
  margin-bottom: 0;
  margin: 0px -2px 0 -2px;
}
@media (max-width: 767.98px) {
  .pcm-status ul {
    justify-content: flex-end;
  }
}
.pcm-status ul li a {
  margin: 4px 2px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border-width: 1px;
}
.pcm-status ul li a span {
  font-size: 11px;
  font-weight: 500;
}
.pcm-status ul li a:hover {
  background: #fff;
  color: var(--dynamic-colour);
}

.info-popover {
  flex: 0 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  border: 1px dashed #d65654;
}
.info-popover i {
  color: #c73230;
}

.ngx-datatable .datatable-body .datatable-body-row > div {
  display: flex;
  box-shadow: inset 1px 1px 0 #e6e5e5;
}

.ccm-accordian .card {
  margin-bottom: 0.5rem;
}
.ccm-accordian .card .card-header {
  border: 1px solid #ededed;
  background-color: #fafafa;
}
.ccm-accordian .card .card-header h5 {
  color: var(--dynamic-colour);
}
.ccm-accordian .card .card-body {
  border: 1px solid #ededed;
  border-top: 0;
  padding: 1rem;
}
.ccm-accordian .card:first-of-type {
  border-bottom: 0;
}

.problem-list {
  padding: 0;
  list-style-type: none;
}
@media (max-width: 991.98px) {
  .problem-list li {
    display: flex;
    overflow: scroll;
    scroll-behavior: smooth;
  }
  .problem-list li::-webkit-scrollbar {
    display: none;
  }
  .problem-list li .custom-control {
    margin-left: 0.5rem;
  }
  .problem-list li .custom-control .custom-control-label {
    white-space: pre;
    margin-bottom: 0px;
  }
}
.problem-list li a {
  padding: 0.2rem;
  color: var(--dark);
  display: block;
  margin-bottom: 5px;
}
.problem-list li.active a {
  color: var(--dynamic-colour);
}

.text-checkable .custom-control {
  padding: 0;
}
.text-checkable .custom-control .custom-control-label {
  padding: 4px 10px;
  margin-bottom: 3px;
  display: block;
  cursor: pointer;
  box-shadow: inset 0px 0px 0px 1px #ededed;
}
.text-checkable .custom-control .custom-control-label:before, .text-checkable .custom-control .custom-control-label:after {
  display: none;
}
.text-checkable .custom-control input[type=checkbox]:checked + .custom-control-label {
  background-color: var(--dynamic-colour) !important;
  color: #fff;
  box-shadow: inset 0px 0px 0px 1px white;
}

.ng-select.my-class-success .ng-select-container {
  color: var(--dynamic-colour);
  background: #e5f1e4;
  border: 1px solid #67bb6f;
}

.ng-select.my-class .ng-select-container {
  color: #e57373;
  background: #ffebee;
  border: 1px solid #e57373;
}

.radio-box {
  padding: 5px 10px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin-bottom: 5px;
}
.radio-box a {
  font-size: 15px;
}
.radio-box.checked {
  background-color: #f2f2f2;
}
.radio-box.active {
  background-color: var(--dynamic-colour);
  border: var(--dynamic-colour);
}
.radio-box.active a {
  color: #fff;
}

.ccm-nav.mCustomScrollbar .mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -8px;
}

.user-name-list .name-caption {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--dynamic-secondary-colour);
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 20px;
  line-height: 23px;
  font-size: 10px;
  color: white;
  font-weight: 400;
  border: 2px solid white;
  cursor: pointer;
}

.user-name-list .name-caption:not(:first-child) {
  background: var(--dynamic-secondary-colour) !important;
  margin-left: -7px;
}

.user-name-list .name-caption:hover {
  z-index: 1;
  position: relative;
  cursor: pointer;
}

main .sticky-header.sticky-header-topnote {
  top: 60px;
}
main .sticky-header {
  background: #fff;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 90px;
  z-index: 22;
  border-radius: 0;
  border: none;
}
@media (min-width: 768px) {
  main .sticky-header {
    width: calc(100% - 240px);
  }
}
@media (min-width: 768px) {
  main.page-body .sticky-header {
    left: 241px;
    padding-left: 3rem;
  }
  main.page-body.fixed-lg .sticky-header {
    left: 61px;
    padding-left: 3rem;
  }
}
@media (max-width: 991.98px) {
  main.page-body .sticky-header {
    left: 0px;
  }
}

.c-pointer {
  cursor: pointer;
}

.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  background: white;
}

.dataTables_wrapper.no-footer div.dataTables_scrollBody > table > tbody:last-child {
  background: white;
}

.datatable-scroll {
  width: unset !important;
}

.mCSB_draggerContainer .mCSB_draggerRail {
  display: none;
}

.side-nav-links div#mCSB_2_container_wrapper {
  margin: 0 !important;
}

.side-nav-links .mCSB_container_wrapper > .mCSB_container {
  padding-right: 0;
}

#mCSB_2_scrollbar_horizontal {
  display: none !important;
}

.popsTemplates-pills .nav-item {
  padding: 0 !important;
}
.popsTemplates-pills .nav-item a.active {
  background-color: #fff !important;
  color: #000 !important;
}

.side-nav-links div#mCSB_2_scrollbar_vertical, .side-nav-links .mCSB_scrollTools_vertical {
  z-index: 999;
}

.ccm-nav {
  overflow-x: auto;
}

.overlay-Body {
  position: relative;
}
.overlay-Body::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.0509803922);
  /* opacity: 0.1; */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  border: 1px solid #ddd;
}

@media (max-width: 375px) {
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0 !important;
    min-width: 1.5em !important;
    min-height: 1.5em !important;
  }
}
@media (max-width: 767px) {
  span.notify-active {
    left: 18px !important;
  }
}
.topbar-sticky-mdb {
  position: sticky;
  left: 0;
  right: 0;
  top: 60px;
  z-index: 999;
  background-color: #fff;
  margin: 0 -15px 0 -15px;
  padding: 0 15px;
  border: #ededed;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.03);
}

.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap,
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled,
.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap,
.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled {
  background: #f4f4f4;
  color: #a6a6a6;
  border: 1px solid #c8c8c8;
}

#linear1 svg#linear1SVG {
  position: absolute;
  z-index: 1;
  margin-top: -22px;
}

.e-pivotview .e-headercell {
  background-color: var(--dynamic-colour) !important;
}
.e-pivotview .e-headercell .e-headertext {
  color: white;
}
.e-pivotview .e-summary .e-gtot {
  background-color: white !important;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell.my-table-cell {
  font-size: 12px !important;
  white-space: pre-line;
}

.blob {
  background: black;
  border-radius: 100%;
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.pulse-btn:before {
  content: " ";
  display: block;
  height: 19px;
  width: 19px;
  border-radius: 8px;
  background: transparent;
  box-shadow: 0 0 0 0 #ff5252;
  -webkit-animation: pulse-red 1.5s infinite;
  animation: pulse-red 1.5s infinite;
  position: absolute;
  top: 10px;
}

.recording-border {
  width: 20px;
  height: 20px;
  color: red;
  border-radius: 15px;
  border: 1px solid red;
  font-size: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.disableNgSelectIcon ng-select .ng-select-container .ng-arrow-wrapper {
  display: none !important;
}

@media (max-width: 991.98px) {
  .horizontal-scroll {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
  }
  .horizontal-scroll .horizontal-scroll-body {
    overflow-y: scroll;
    margin: 0 2px;
    flex-grow: 1;
    scroll-behavior: smooth;
  }
  .horizontal-scroll .horizontal-scroll-body ul {
    margin-bottom: 0;
  }
  .horizontal-scroll .horizontal-scroll-body::-webkit-scrollbar {
    display: none;
  }
  .horizontal-scroll .horizontal-scroll-body .system-list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    overflow: scroll;
    list-style: none;
    scroll-behavior: smooth;
  }
  .horizontal-scroll .horizontal-scroll-body .system-list::-webkit-scrollbar {
    display: none;
  }
  .horizontal-scroll .horizontal-scroll-body .system-list li {
    flex: 1 1;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .horizontal-scroll .hs-btn {
    border: 0;
    background: #f2f3f8;
  }
  .horizontal-scroll .hs-btn i {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .horizontal-scroll .hs-btn {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  #scorll-spy {
    display: none;
  }
}
.table-nowrap thead tr th {
  white-space: pre;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 0.6rem !important;
}

.fixed-column {
  background: #fff;
  position: fixed;
  right: 0;
}

.fixed-header-action {
  position: absolute;
  right: 0;
  z-index: 1;
  width: 230px;
  color: #fff;
  font-weight: 500;
  font-size: 0.8rem;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 1rem;
  background: var(--dynamic-colour);
  border-top-right-radius: 4px;
}

hr.divider {
  opacity: 0.5;
}

@media (max-width: 575.98px) {
  ul.pain-check {
    display: flex;
    flex-wrap: nowrap;
  }
  ul.pain-check li {
    display: flex;
    justify-content: center;
  }
}
ul.status-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
ul.status-list li {
  margin: 5px;
}
ul.status-list li a {
  border: 1px solid #d9d9d9;
  padding: 4px 10px;
  border-radius: 4px;
  padding-left: 25px;
  display: block;
  position: relative;
  color: #2b373d;
}
ul.status-list li a:before {
  content: " ";
  width: 10px;
  height: 10px;
  border-radius: 12px;
  display: inline-block;
  background: #d9d9d9;
  position: absolute;
  left: 7px;
  top: 8px;
}
ul.status-list li a:hover {
  border: 1px solid var(--dynamic-colour);
  background: #f4f4f4;
}
ul.status-list li a:hover::before {
  background: var(--dynamic-colour);
}
ul.status-list li.active a {
  border: 1px solid var(--dynamic-colour);
  background: #f4f4f4;
}
ul.status-list li.active a:before {
  background: var(--dynamic-colour);
}

ul.status-list.disabled li a {
  border: 1px solid grey;
  background: #f4f4f4;
  pointer-events: auto;
  cursor: not-allowed;
}
ul.status-list.disabled li a:before {
  background: grey;
}

ul.status-list.delete-row-list li a:hover {
  border: 1px solid #c73230;
  background: rgba(199, 50, 48, 0.1215686275);
}
ul.status-list.delete-row-list li a:hover::before {
  background: #c73230;
}
ul.status-list.delete-row-list li.active a {
  border: 1px solid #c73230;
  background: rgba(199, 50, 48, 0.1215686275);
}
ul.status-list.delete-row-list li.active a:before {
  background: #c73230;
}

.app-ringcentral-container.minimize {
  box-shadow: none !important;
}

.app-ringcentral-container {
  width: 300px;
  min-width: 300px;
  box-shadow: -1px -1px 20px 4px rgba(198, 198, 198, 0.6901960784);
  overflow: hidden;
}
.app-ringcentral-container .header {
  background: white;
  padding: 5px 5px 5px 1rem;
  border-bottom: 1px solid #f2f3f8;
  color: var(--dynamic-secondary-colour);
  cursor: pointer;
}
.app-ringcentral-container .header p {
  margin: 0;
}
.app-ringcentral-container .header button {
  border: none;
  font-size: 0.9rem;
  background: transparent;
  color: inherit;
}
.app-ringcentral-container .body {
  height: 100%;
  transition: width 2s;
}
.app-ringcentral-container.minimize .header {
  background: #fff;
  color: #2b373d;
  border: 0 !important;
  height: 0;
  padding: 0;
}
.app-ringcentral-container.minimize .body {
  height: 0;
  width: auto;
  -webkit-animation: scale-up-bottom 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-bottom 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.app-ringcentral-container.close {
  display: none;
}
.app-ringcentral-container iframe {
  border: none;
  background-color: white;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-9-29 14:20:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
.custom-list-col .sb-item-body .card-body {
  max-height: 218px;
}

/**
 * ----------------------------------------
 * animation scale-up-bottom
 * ----------------------------------------
 */
.scale-up-bottom {
  -webkit-animation: scale-up-bottom 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-bottom 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-bottom {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}
@keyframes scale-up-bottom {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}
.patients-list .custom-list-dropdown {
  position: absolute;
  z-index: 1;
  top: 25px;
  right: 0;
}
@media (max-width: 991.98px) {
  .patients-list .custom-list-dropdown {
    top: 0;
    margin-bottom: 1rem;
  }
}

.patients-list {
  padding-top: 24px;
}

div.dataTables_wrapper div.dataTables_length label {
  margin: 0;
}

.ngDraggable-container {
  position: fixed;
  top: 90px;
  left: calc(50% - 150px);
  text-align: center;
  display: flex;
  justify-content: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.loadingrow {
  position: absolute;
  z-index: 3;
  top: 100px;
  width: 100%;
}
.loadingrow .progress {
  border-radius: 0;
}

i.block-icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  border-radius: 2px;
  margin: 0 2px;
}
i.block-icon.light {
  background-color: var(--dynamic-sidenav);
  color: #fff;
  filter: brightness(1.2);
}
i.block-icon.danger {
  background-color: #f8bbd0;
}

i.block-icon {
  font-size: 14px;
}

i.block-icon:hover {
  background: #000;
}

.accordion-links .mCSB_scrollTools {
  right: 10px !important;
}

.pagination .page-item.active .page-link {
  background: var(--dynamic-colour) !important;
  padding: 0.5em 0.7em;
  color: #fff !important;
}

.pagination .page-item.active .page-link:hover {
  background: var(--dynamic-colour) !important;
  padding: 0.5em 0.7em;
  color: #fff !important;
}

.user-name-list-colorspan .name-caption {
  background-color: grey !important;
}

.user-name-list-colorspan .name-caption:not(:first-child) {
  background: grey !important;
  margin-left: -7px;
}

.custom-c-gap {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border: 1px solid var(--dynamic-colour);
  background-color: #fff;
  border-radius: 30px;
  display: inline-flex;
  margin-left: 5px;
  font-size: 12px;
  color: #222831;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  z-index: 10;
}

.btn {
  border-radius: 4px;
}

.custom-d-progress {
  padding: 2px;
  background-color: #eee;
  display: flex;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}
.custom-d-progress .progress {
  height: 30px !important;
  margin: 0;
  background-color: var(--dynamic-sidenav);
}
.custom-d-progress .progress .progress-bar {
  height: 30px !important;
}

.text-plus-icon-right {
  position: absolute;
  left: 170px;
  top: 10px;
  z-index: 9999;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  background-color: var(--dynamic-sidenav);
}
.text-plus-icon-right:hover {
  color: #4285f4 !important;
}

.side-nav-links .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #ededed;
}

.datatable-body-cell-label {
  width: 100% !important;
}

.ng-option-label,
.cursor-pointer {
  cursor: pointer;
}

.btn-icon {
  min-width: 30px;
}

.modal-backdrop,
.modal-backdrop.in {
  opacity: 0.9 !important;
  background: rgba(27, 27, 27, 0.3);
}

.height-humana .ng-select-small .ng-dropdown-panel .ng-dropdown-panel-items.scroll-host {
  max-height: 140px !important;
}

.document-close {
  position: absolute;
  font-size: 17px !important;
  right: -7px !important;
  top: -8px;
  background: #f44336 !important;
  width: 20px;
  height: 20px;
  line-height: 20px;
  opacity: 1;
  color: white !important;
  border-radius: 25px;
  z-index: 99999999 !important;
  opacity: 1 !important;
}
.document-close span {
  text-shadow: none;
}

.modal-quick-note {
  z-index: 99999 !important;
}

.count-danger-box.bg-danger {
  width: 95px;
  height: 45px;
  font-size: 26px;
  color: #fff;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ngx-datatable .datatable-footer {
  overflow: hidden !important;
}

table.rpm-device-table {
  border-radius: 5px;
}
table.rpm-device-table thead tr th {
  font-weight: 500;
  text-align: left !important;
}
table.rpm-device-table thead tr th:not(:nth-child(2)) {
  text-align: center;
}
table.rpm-device-table thead tr th:first-child {
  border-top: 0;
}
table.rpm-device-table thead tr th:last-child {
  border-top: 0;
}
table.rpm-device-table tbody tr td {
  text-align: left !important;
  vertical-align: middle !important;
}
table.rpm-device-table tfoot tr td {
  vertical-align: middle !important;
  padding: 2px 5px !important;
}
table.rpm-device-table tfoot tr td:first-child {
  border-top: 0;
  border-radius: 6px !important;
}
table.rpm-device-table tfoot tr td ul.pagination.pagination-circle {
  margin: 0;
}

@media (min-width: 1200px) {
  .dataTables_scrollHeadInner {
    min-width: 100% !important;
  }
}
mdb-tabset .white.pills-custom-pills li {
  margin-left: 0 !important;
}

.device--dexcom h4 {
  font-size: 18px;
  font-weight: 500;
}
.device--dexcom h5 {
  font-size: 16px;
  font-weight: 500;
}
.device--dexcom h6 {
  font-size: 12px;
  font-weight: 500;
}
.device--dexcom .badge {
  border-radius: 8px;
  height: 15px;
  width: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.device--dexcom .hight-font {
  font-size: 50px;
  font-weight: 500;
}
.device--dexcom .hight-font small {
  font-size: 14px;
}
.device--dexcom .deviation {
  font-size: 18px;
  font-weight: 500;
}
.device--dexcom .deviation small {
  font-size: 14px;
}
.device--dexcom .-time-in-range- {
  width: 50px;
  height: 175px;
}
.device--dexcom .-time-in-range- div:first-child {
  border-radius: 4px 4px 0 0;
}
.device--dexcom .-time-in-range- div:last-child {
  border-radius: 0 0 4px 4px;
}
.device--dexcom .-time-in-range- .-high- {
  background-color: #c73230;
}
.device--dexcom .-time-in-range- .-inrange- {
  background-color: #61d270;
}
.device--dexcom .-time-in-range- .-low- {
  background-color: #ffc107;
}
.device--dexcom .-time-in-range- .-verylow- {
  background-color: #17a2b8;
}

.weekly-data .btn {
  border-width: 1px !important;
}

.phone-verification {
  display: flex;
  align-items: self-start;
  justify-content: center;
}

.verification-code input[type=text] {
  border: 1px solid #ced4da;
  height: 31px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0 0.5rem;
  width: 100%;
}

.verification-code {
  position: relative;
  flex: 1;
}

button.edit-code {
  background: transparent;
  border: none;
  color: var(--primary-color);
}

.verify-phone-icon {
  width: 20px;
  height: 20px;
  margin: 4px 0 0 auto;
  color: #ffffff;
  line-height: 21px;
  text-align: center;
  border-radius: 15px;
}
.verify-phone-icon.not-verified {
  background: #ef5350;
}
.verify-phone-icon.verified {
  background: var(--dynamic-colour);
}

td.status-popover {
  cursor: pointer;
}
td.status-popover .popover {
  border-radius: 5px;
}
td.status-popover .popover .popover-body {
  border: none;
  padding: 1rem;
  border-radius: 5px;
}
td.status-popover .popover .popover-body ul.drop-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
td.status-popover .popover .popover-body ul.drop-list .custom-control {
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.tooltip-inner {
  max-width: 220px !important;
}

.embed-responsive-item {
  padding: 0.5rem;
  background-color: #ededed;
}

.status-bullet {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  border: 0;
}

.input-loader {
  position: relative;
}
.input-loader > span {
  position: absolute;
  top: 8px;
  right: 10px;
}

.modal-xlx {
  max-width: 1000px !important;
}

.quickNote11 {
  z-index: 9999 !important;
}

.myDate-111 .md-form .form-control.mydp-date {
  display: none !important;
}

.highlightText {
  background-color: black;
  padding: 1px 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 2px;
}

.custom-select-xy-axis .ng-dropdown-panel .scroll-host::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.custom-select-xy-axis .ng-dropdown-panel .scroll-host::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.custom-select-xy-axis .ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border: 0px none #ffffff;
  border-radius: 10px;
}

.custom-select-xy-axis .ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}

.custom-select-xy-axis .ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb:active {
  background: #c4c4c4;
}

.custom-select-xy-axis .ng-dropdown-panel .scroll-host::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.custom-select-xy-axis .ng-dropdown-panel .scroll-host::-webkit-scrollbar-track:hover {
  background: transparent;
}

.custom-select-xy-axis .ng-dropdown-panel .scroll-host::-webkit-scrollbar-track:active {
  background: transparent;
}

.custom-select-xy-axis .ng-dropdown-panel .scroll-host::-webkit-scrollbar-corner {
  background: transparent;
}

.custom-select-xy-axis .ng-select-container .ng-value-container,
.custom-select-xy-axis .ng-select-container .ng-value-container .ng-input {
  padding-left: 30px !important;
  padding-right: 10px !important;
  top: 2px !important;
}

.custom-select-xy-axis .ng-select-container .ng-arrow-wrapper {
  display: none;
}

.custom-select-xy-axis .ng-dropdown-panel .ng-option {
  border: 1px solid var(--dynamic-colour);
  border-bottom: 0;
  color: var(--dynamic-colour);
  text-align: left;
}

.custom-select-xy-axis .ng-dropdown-panel .ng-option:last-child {
  border-bottom: 1px solid var(--dynamic-colour);
  color: var(--dynamic-colour);
}

.custom-select-xy-axis .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--dynamic-colour);
  color: #fff;
}

.fa-mobile-custom-active {
  width: 20px;
  height: 30px;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--dynamic-colour);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}

.fa-mobile-custom-inactive {
  width: 20px;
  height: 30px;
  padding: 4px;
  border-radius: 4px;
  background-color: grey;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}

.minimize-editor {
  width: 250px !important;
}
.minimize-editor .caller {
  width: 250px !important;
  min-width: 250px !important;
}
.minimize-editor .caller .minimize-hide {
  display: none !important;
}
.minimize-editor .caller .font-minimize {
  display: none;
}
.minimize-editor .caller .font-maximize {
  display: inline-block;
}
.minimize-editor .minimize-hide {
  display: none !important;
}

.font-maximize {
  display: none;
}

.body-landing-dragging {
  overflow-y: inherit !important;
}

ul.ccm-custom-mh {
  display: none !important;
}

.sortCursor {
  cursor: pointer;
}

.classic-tabs.classic-tabs-mh .tab-content,
.classic-tabs.classic-tabs-mh .nav.classic-tabs {
  margin: 0 !important;
}

.mCSB_outside + .mCSB_scrollTools {
  right: -16px;
}

.btn-transparent-btn {
  box-shadow: none !important;
  padding: 0 !important;
  background-color: transparent !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  text-transform: none !important;
  white-space: nowrap !important;
}

.facilty-demo .collapsible .card .card-header a h5,
.facilty-demo .collapsible .card .card-header a h5 {
  background-color: #4285f4 !important;
}
.facilty-demo .collapsible .card .card-header a h5 span div small,
.facilty-demo .collapsible .card .card-header a h5 span div small {
  font-size: 15px;
}
.facilty-demo .collapsible .card .card-header a h5 .mdb-accordion-indicator::before,
.facilty-demo .collapsible .card .card-header a h5 .mdb-accordion-indicator::before {
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 2px;
}
.facilty-demo ul.facility-list-demo li {
  margin: 0px 0px 0px 0;
  padding: 0.35rem 1rem 0.35rem 1rem;
  font-size: 14px;
  display: block;
  text-align: left;
  position: relative;
  border-bottom: 1px solid #3f3f3f !important;
  color: #fff;
  white-space: nowrap;
}
.facilty-demo ul.facility-list-demo li:hover {
  cursor: pointer;
  background-color: transparent !important;
  color: var(--dynamic-colour) !important;
  transition: all ease-in-out 300ms;
}
.facilty-demo ul.facility-list-demo li:hover::after {
  content: " ";
  background: transparent !important;
  width: 0px !important;
  height: 0;
  position: absolute;
  border-radius: 0 25px 25px 0;
  left: 0px;
  z-index: 1;
}
.facilty-demo ul.facility-list-demo li.active {
  cursor: pointer;
  background-color: transparent !important;
  color: var(--dynamic-colour) !important;
  transition: all ease-in-out 300ms;
}
.facilty-demo ul.facility-list-demo li.active::after {
  content: " ";
  background: #fff !important;
  width: 0px !important;
  height: 0;
  position: absolute;
  border-radius: 0 25px 25px 0;
  left: 0px;
  z-index: 1;
}
.facilty-demo .sidenave-dropdown .btn-custom-sidenav {
  background-color: var(--dynamic-sidenav);
  color: #fff;
  border: 1px solid var(--dynamic-sidenav);
}
.facilty-demo .sidenave-dropdown .btn-custom-sidenav:active {
  background-color: var(--dynamic-sidenav);
  filter: brightness(1.1);
}
.facilty-demo .sidenave-dropdown .btn-custom-sidenav:focus ~ .sidenave-dropdown-menu, .facilty-demo .sidenave-dropdown .btn-custom-sidenav:active ~ .sidenave-dropdown-menu {
  display: block;
}
.facilty-demo .sidenave-dropdown .sidenave-dropdown-menu {
  position: absolute;
  left: 245px;
  top: 45px;
  padding: 0;
  min-width: 200px;
  display: none;
}
.facilty-demo .sidenave-dropdown .sidenave-dropdown-menu:focus, .facilty-demo .sidenave-dropdown .sidenave-dropdown-menu:active, .facilty-demo .sidenave-dropdown .sidenave-dropdown-menu:hover {
  display: block !important;
}
.facilty-demo .sidenave-dropdown .sidenave-dropdown-menu .input-field-search {
  position: relative;
}
.facilty-demo .sidenave-dropdown .sidenave-dropdown-menu .input-field-search .form-control-facility {
  border-radius: 0px;
  padding: 0 1rem 0 1.7rem;
  border: 1px solid var(--dynamic-sidenav);
  background: var(--dynamic-sidenav);
  height: 33.2px;
  color: #fff;
  width: 100%;
  text-transform: capitalize;
}
.facilty-demo .sidenave-dropdown .sidenave-dropdown-menu .input-field-search .form-control-facility:focus {
  outline: none;
  border-bottom: 1px solid #fff;
  box-shadow: none;
}
.facilty-demo .sidenave-dropdown .sidenave-dropdown-menu .input-field-search span.las.la-search.fa-lg {
  position: absolute;
  left: 6px;
  top: 8px;
  color: #fff;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border: 2px solid #f1f1f1;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
  border: 2px solid #777;
}

.ng-search-admin .ng-select-container, .ng-select.ng-search-admin .ng-select-container {
  padding: 10px 0px !important;
  min-height: 45px !important;
  border-radius: 8px;
}

.ng-search-admin .ng-select-container .ng-value-container .ng-input {
  top: 8px !important;
}

ul.nav.classic-tabs-c {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ul.nav.classic-tabs-c li {
  flex: 1;
  background-color: #fff !important;
}
ul.nav.classic-tabs-c li a {
  color: #000 !important;
  padding: 10px 16px !important;
}
ul.nav.classic-tabs-c li.active {
  background-color: var(--dynamic-colour) !important;
}
ul.nav.classic-tabs-c li.active a {
  color: #fff !important;
}

mdb-progress.my-upload-logos .progress {
  height: 15px;
  margin-top: 4px;
  margin-bottom: 0px;
}

mdb-progress.my-upload-logos .progress .progress-bar {
  height: 15px;
}

.input-spinner {
  position: absolute;
  right: 5px;
  bottom: 2px;
}
.input-spinner .spinner-border {
  width: 1.3rem;
  height: 1.3rem;
}

.app-new-primary-bg {
  background-color: var(--dynamic-colour);
}

.app-new-secondary-bg {
  background-color: var(--dynamic-secondary-colour);
}

.app-new-primary-color {
  color: var(--dynamic-colour);
}

.app-new-secondary-color {
  color: var(--dynamic-secondary-colour);
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2.25px);
}

.margin-top-30px {
  margin-top: 30px !important;
}
.margin-top-30px .double-nav.fixed-top {
  margin-top: 30px !important;
}
.margin-top-30px .fixed.side-nav {
  margin-top: 30px !important;
}

.notification-log {
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); /* easeOutBack */
}

.notification-log-show {
  right: 0;
  opacity: 1;
}

.notification-log-hide {
  -webkit-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045); /* easeInBack */
}

.notification-bar-mh {
  min-height: 30px;
  height: 30px;
  background: var(--dynamic-colour);
  position: fixed;
  line-height: 30px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999 !important;
  overflow: hidden;
}
.notification-bar-mh p {
  font-size: 12px;
  margin: 0;
  color: #fff;
  text-align: center;
}
.notification-bar-mh p i {
  font-size: 16px;
  margin-right: 7px;
}
.notification-bar-mh p .tada {
  animation: tada 2s linear infinite;
}
.notification-bar-mh span {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  font-weight: 700;
  color: var(--dynamic-colour);
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 20px;
  background-color: #fff;
  cursor: pointer;
  text-align: center;
}

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-8deg);
    transform: scale(0.9) rotate(-8deg);
  }
  30%, 50%, 70% {
    -webkit-transform: scale(1.3) rotate(8deg);
    transform: scale(1.3) rotate(8deg);
  }
  40%, 60% {
    -webkit-transform: scale(1.3) rotate(-8deg);
    transform: scale(1.3) rotate(-8deg);
  }
  100%, 80% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
.modal.fade .modal-dialog {
  margin: 50px auto 1.75rem auto !important;
}

.fixed-action-btn {
  min-width: 50px;
  position: fixed;
  z-index: 999 !important;
}

.hide-datatable-column {
  display: none;
  min-width: 0px;
  max-width: 0px;
  padding: 0px;
}

.background-light-grey {
  background: rgba(var(--dynamic-colour), 0.5)80;
}

.background-dark-grey {
  background: #4E4E4E80;
}

.background-less-dark-grey {
  background: #5F5F5F80;
}

.m-button {
  width: 125px;
  height: 35px;
  color: white;
  display: inline-block;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: var(--dynamic-colour);
  filter: opacity(0.5);
}

.bg-sidenav {
  background-color: var(--dynamic-sidenav);
}

.datatable-body,
.datatable-body-row,
.datatable-header {
  width: 100% !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: normal !important;
}

.popover-header {
  font-weight: 500;
}

.popover-header {
  font-weight: 500;
}

.consent-note-pre pre {
  white-space: break-spaces;
}

.btn {
  margin: 0.375rem;
}

.comments-counts {
  position: absolute;
  top: -3px;
  right: -6px;
  min-width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  background-color: var(--dynamic-secondary-colour);
  border-radius: 14px;
  font-size: 8px;
  color: #fff;
  z-index: 9;
  padding: 0 2px;
}

.ng-draggable {
  cursor: grab !important;
}

.ng-dragging {
  cursor: grabbing !important;
}

.textarea .input-area {
  margin-right: -14px;
}
.textarea .input-area .form-control {
  overflow-y: auto;
  height: 58px;
  padding-right: 10px !important;
  padding: 0;
  border: 0 !important;
  resize: none !important;
  word-break: break-all;
}

.textarea.extended-height .input-area {
  margin-right: -14px;
}
.textarea.extended-height .input-area .form-control {
  overflow-y: auto;
  height: 130px !important;
  padding-right: 10px !important;
  padding: 0;
  border: 0 !important;
  resize: none !important;
  word-break: break-all;
}

.accordion .my-custom-according {
  margin-bottom: 1rem;
}
.accordion .my-custom-according .card.active .card-header a:not(.collapsed) .mdb-accordion-indicator {
  top: 8px;
  right: -15px;
  color: var(--dynamic-colour);
}
.accordion .my-custom-according .card.active .card-header {
  border: 1px solid var(--dynamic-colour);
}
.accordion .my-custom-according .card.active .card-header h5 {
  color: var(--dynamic-colour);
}
.accordion .my-custom-according .card {
  border-bottom: 0 !important;
}
.accordion .my-custom-according .card .card-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #6D6D6D;
  border: 1px solid #C6C6C6;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0.5rem 0.5rem;
}
.accordion .my-custom-according .card .card-header h5 {
  font-weight: 500;
  font-size: 12px;
  color: #6D6D6D;
}
.accordion .my-custom-according .card .card-header h5 span {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.accordion .my-custom-according .card .card-header .mdb-accordion-indicator {
  top: 13px;
  right: -15px;
}
.accordion .my-custom-according .card .card-body {
  padding: 0px 0.5rem 1rem 0.5rem !important;
}

.accordion .my-custom-according-left {
  margin-bottom: 1rem;
}
.accordion .my-custom-according-left .card.active .card-header a:not(.collapsed) .mdb-accordion-indicator {
  top: 10px;
  right: -15px;
  color: var(--dynamic-colour);
}
.accordion .my-custom-according-left .card.active .card-header {
  border: 1px solid var(--dynamic-colour);
}
.accordion .my-custom-according-left .card.active .card-header h5 {
  color: var(--dynamic-colour);
}
.accordion .my-custom-according-left .card {
  border-bottom: 0 !important;
}
.accordion .my-custom-according-left .card .card-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #6D6D6D;
  border: 1px solid #C6C6C6;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0.8rem 0.5rem;
}
.accordion .my-custom-according-left .card .card-header h5 {
  font-weight: 500;
  font-size: 12px;
  color: #6D6D6D;
}
.accordion .my-custom-according-left .card .card-header h5 span {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.accordion .my-custom-according-left .card .card-header .mdb-accordion-indicator {
  top: 16px;
  right: -15px;
}
.accordion .my-custom-according-left .card .card-body {
  padding: 0px 0.5rem 1rem 0.5rem !important;
}

.accordion .my-custom-according-middle {
  margin-bottom: 1rem;
}
.accordion .my-custom-according-middle .card.active .card-header a:not(.collapsed) .mdb-accordion-indicator {
  top: 10px;
  color: var(--dynamic-colour);
}
.accordion .my-custom-according-middle .card.active {
  border: 1px solid var(--dynamic-colour) !important;
}
.accordion .my-custom-according-middle .card.active .card-header h5 {
  color: var(--dynamic-colour);
}
.accordion .my-custom-according-middle .card {
  border: 1px solid #C6C6C6 !important;
  border-radius: 8px;
  margin-bottom: 10px;
}
.accordion .my-custom-according-middle .card .card-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #6D6D6D;
  padding: 0.8rem 1rem;
}
.accordion .my-custom-according-middle .card .card-header h5 {
  font-weight: 500;
  font-size: 12px;
  color: #6D6D6D;
  margin-left: 26px;
}
.accordion .my-custom-according-middle .card .card-header h5 span {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.accordion .my-custom-according-middle .card .card-header .mdb-accordion-indicator {
  top: 16px;
  left: 15px;
  right: unset;
}
.accordion .my-custom-according-middle .card .card-body {
  padding: 0px 1rem 1rem 1rem !important;
}

.popover {
  z-index: 999999 !important;
}

.pro-crox-x {
  position: absolute;
  right: 0;
  top: -4px;
  z-index: 9999;
  font-size: 14px;
  color: #fff;
  background-color: var(--dynamic-secondary-colour);
  width: 15px !important;
  min-width: 15px !important;
  min-height: 15px !important;
  height: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  display: flex !important;
  white-space: normal !important;
}

.circle-15 {
  width: 15px;
  height: 15px;
  min-width: 15px;
  background-color: grey;
  border-radius: 15px;
}

.zsiq_floatmain.zsiq_theme1,
.zsiq_custommain,
.zsiq_floatmain {
  z-index: 99 !important;
}

.zsiq_theme1.zsiq_floatmain {
  width: 50px !important;
  max-height: 50px;
}

.zsiq_theme1 .siqicon:before {
  font-size: 20px !important;
  line-height: 47px !important;
}

.zsiq_theme1 .zsiq_flt_rel {
  width: 47px !important;
  height: 47px !important;
}

.btn-icon-40 {
  width: 45px;
  min-width: 45px;
  justify-content: space-between !important;
  padding: 3px !important;
  align-items: center !important;
}
.btn-icon-40 .badge {
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  font-size: 9px;
  margin-top: 2px;
  border: 0 !important;
}

.ng-select-small-myforms .ng-select .ng-select-container {
  border: 1px solid #e5e5e5;
}

.md-drppicker {
  display: flex;
}

.modal-title {
  line-height: 1 !important;
}

.mr-400 {
  margin-right: 400px !important;
}

.ngx-datatable .datatable-header .datatable-header-cell:first-child .sort-btn {
  display: none !important;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5215686275) !important;
}

.my-form-according .card {
  margin-bottom: 1rem;
}
.my-form-according label {
  color: #000;
  font-weight: 500;
}
.my-form-according .card.is-collapsed mdb-accordion-item-head i.mdb-accordion-indicator.rotate-icon {
  top: 36px;
  border: 1px solid #000;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.my-form-according .card mdb-accordion-item-head i.mdb-accordion-indicator.rotate-icon {
  top: 28px;
  border: 1px solid #000;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.b-h-200 {
  max-height: 200px;
}
.b-h-200 .b-h-scroll {
  max-height: 200px;
  height: 100%;
  overflow-y: auto;
}
.b-h-200 .b-h-scroll ul li {
  list-style: disc;
  display: inline-block;
}

.ccm-nav .ccm-navbar li.disbaled-li {
  pointer-events: none;
  color: gray;
}

.department-changelist {
  margin-left: -20px;
}
.department-changelist ul {
  margin: 0;
  padding: 0;
}
.department-changelist ul li {
  display: block;
  list-style: none;
  padding: 0.3rem 0.5rem;
  margin-bottom: 5px;
  border-radius: 4px;
}
.department-changelist ul li:hover {
  background-color: rgba(78, 176, 72, 0.431372549);
}
.department-changelist ul li a.changelist-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.dataTables_scroll {
  max-height: 700px;
  overflow-y: auto;
  margin-top: 7px;
  margin-bottom: 5px;
}
.dataTables_scroll .dataTables_scrollHead {
  position: sticky !important;
  top: 0px;
  z-index: 999;
}
.dataTables_scroll .dataTables_scrollHeadInner {
  margin-top: -7px;
}

mdb-progress .progress .progress-bar.progress-bar-dark-green {
  background-color: #0f8f08 !important;
}
mdb-progress .progress .progress-bar.progress-bar-light-green {
  background-color: #a3ef9f !important;
}

.md-outline > .datepicker-icon {
  top: 0 !important;
}

.myClear-custom ~ ngx-daterangepicker-material .md-drppicker .btn.btn-default.clear {
  margin-bottom: 0;
  border: 1px solid #ddd;
}
.myClear-custom ~ ngx-daterangepicker-material .md-drppicker .btn.btn-default.clear svg {
  display: none !important;
}

.dataTables_scrollHead,
.dataTables_scrollBody {
  overflow: unset !important;
}

.ng-select.ng-select-small.cura-select .ng-select-container {
  height: 35px !important;
}
.ng-select.ng-select-small.cura-select .ng-select-container .ng-placeholder {
  top: 8px !important;
}

.btn-dynamic-ai {
  border: 2px solid rgba(255, 255, 255, 0.64);
  background: linear-gradient(90deg, #41D03A 1.84%, #0054B8 102.82%) !important;
}
.btn-dynamic-ai img {
  margin-top: 2px;
}

table.dataTable tbody tr:hover {
  background-color: rgba(78, 176, 72, 0.1294117647);
}

table.dataTable tbody tr.highlight-row {
  background-color: rgba(78, 176, 72, 0.1294117647);
}

zxing-scanner.myscan video {
  width: calc(100vw - 67px);
  height: calc(100vh - 104px);
  border-radius: 10px;
  border: 0px solid #fff;
  margin: 2px;
}

.myQrCode {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.myQrCode qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
}
.myQrCode qrcode .qrcode canvas {
  width: 286px !important;
  height: 286px !important;
  border: 1px solid #000;
}

input::placeholder {
  color: #bababa !important;
}

.role-circle-name {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #17a2b8;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 20px;
  line-height: 23px;
  font-size: 10px;
  color: white;
  font-weight: 400;
  border: 2px solid white;
  cursor: pointer;
}

.my-style-sidenav .card {
  background-color: transparent !important;
  background: transparent !important;
  border: 0 !important;
}
.my-style-sidenav .card .card-header {
  padding: 0.4rem 1rem !important;
}
.my-style-sidenav a {
  color: #fff !important;
}
.my-style-sidenav a .mdb-accordion-indicator {
  top: 7px;
}

.enable-embedded-view {
  display: none;
}

.enable-embedded-view ~ .page-body {
  padding: 0 !important;
  margin: 0 !important;
}
.enable-embedded-view ~ .page-body .fixed-action-btn {
  display: none;
}

.md-drppicker {
  right: 0 !important;
  left: auto !important;
}